import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Divider from '@material-ui/core/Divider';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import PropTypes from 'prop-types';

import DashboardPanel from './DashboardPanel';
import { getCustomer, updateCustomer, deleteCard } from '../../store/actions/managerActions';

const styles = theme => ({
  primary: {
    color: '#006EFF !important',
  },
  title: {
    marginBottom: theme.spacing.unit * 4,
  },
  table: {
    marginBottom: theme.spacing.unit * 4,
  },
});

class CreditCardInformation extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    customerUpdate: PropTypes.func,
    sources: PropTypes.array,
    defaultSource: PropTypes.object,
    loadCards: PropTypes.func,
    cardDelete: PropTypes.func,
  };

  static defaultProps = {
    sources: [
      { number: '41xx-xxxx-xxxx-xx56', primary: true },
      { number: '41xx-xxxx-xxxx-xx56', primary: false },
      { number: '41xx-xxxx-xxxx-xx56', primary: false },
    ],
  };

  componentDidMount = () => {
    const { loadCards } = this.props;
    loadCards();
  };

  handlePrimary = cardId => {
    const { customerUpdate, loadCards } = this.props;
    customerUpdate({ default_source: cardId });
    loadCards();
  };

  handleDeleteCard = cardId => {
    const { cardDelete } = this.props;
    cardDelete(cardId);
  };

  renderContent = () => {
    const { sources, classes, history, defaultSource } = this.props;

    return (
      <Fragment>
        <Typography className={classes.title} variant='h6'>
          CREDIT CARD
        </Typography>
        <Divider />
        <Table className={classes.table}>
          <TableBody>
            {sources.map(card => (
              <TableRow key={card.id}>
                <TableCell>
                  <CreditCardIcon />
                </TableCell>
                <TableCell>
                  <Typography variant='h6'>{`****-****-****-${card.last4}`}</Typography>
                </TableCell>
                <TableCell>
                  {card.id === defaultSource ? (
                    <Button className={classes.primary} disabled>
                      (Primary)
                    </Button>
                  ) : (
                    <Button onClick={() => this.handlePrimary(card.id)} color='primary'>
                      Make Primary
                    </Button>
                  )}
                </TableCell>
                {sources.length > 1 && (
                  <TableCell>
                    <IconButton onClick={() => this.handleDeleteCard(card.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <center>
          <Button
            color='primary'
            variant='contained'
            onClick={() => history.push('/dashboard/addCreditCard')}
          >
            ADD CREDIT CARD
          </Button>
        </center>
      </Fragment>
    );
  };

  render = () => (
    <DashboardPanel panelName='CREDIT CARD INFORMATION' pageMode>
      {this.renderContent()}
    </DashboardPanel>
  );
}

const mapStateToProps = ({ manager: { sources, defaultSource } }) => ({ sources, defaultSource });

const mapDispatchToProps = dispatch => ({
  loadCards: () => dispatch(getCustomer()),
  customerUpdate: fields => dispatch(updateCustomer(fields)),
  cardDelete: cardId => dispatch(deleteCard(cardId)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(CreditCardInformation);
