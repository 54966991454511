import APP_WORKING from '../actions/notificationActions';

const initialState = {
  isWorking: false,
};

const notificationReducer = (state = initialState, action) => {
  let _state;
  switch (action.type) {
    case APP_WORKING:
      _state = { ...state };
      _state.isWorking = action.payload.working;
      return _state;
    default:
      return state;
  }
};

export default notificationReducer;
