import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import getQuote from '../../services/QuoteService';

const styles = () => ({
  quoteContainer: {
    height: 72,
    backgroundColor: '#EE6352',
    border: '1px solid #707070',
    flex: 'initial',
    padding: '0 16px',
  },
  quoteText: {
    fontSize: 20,
    color: '#ffffff',
  },
  sourceText: {
    fontWeight: 'bold',
  },
  spacer: {
    padding: '0 8px',
  },
});

const FooterQuote = ({ classes, seed = 1 }) => {
  const quote = getQuote(seed);
  return (
    <Grid className={classes.quoteContainer} container justify='center' alignItems='center'>
      <span className={classes.quoteText}>{`"${quote.quote}"`}</span>
      <span className={`${classes.spacer} ${classes.sourceText} ${classes.quoteText}`}>-</span>
      <span className={`${classes.sourceText} ${classes.quoteText}`}>{quote.source}</span>
    </Grid>
  );
};

FooterQuote.propTypes = {
  classes: PropTypes.object,
  seed: PropTypes.number,
};

export default withStyles(styles)(FooterQuote);
