import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import DocumentTitle from 'react-document-title';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import Header from './Header';
import l10n from '../../services/l10n';
import SkillsCarousel from './SkillsCarousel';
import Footer from './Footer';
import GetStartedAction from './GetStartedAction';

import PaperworkImg from '../../assets/image2.png';
import PersonalizationImg from '../../assets/image3.png';
import CustomBudgetImg from '../../assets/image4.png';
import VirtualCard from '../../assets/VirtualCard.png';
import NoForms from '../../assets/NoForms.png';
import Skills from '../../assets/100Skills.png';
import Product from '../../assets/brinq_featured_products.jpg';

class LandingPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  state = {
    btnMsg: 'Get Started Now',
    btnRoute: '/register',
  };

  render = () => {
    const { classes } = this.props;
    const { btnMsg, btnRoute } = this.state;

    return (
      <DocumentTitle title='Home'>
        <div>
          <Header />
          <section className={classes.heroContainer}>
            <Grid
              container
              className={classes.heroTextContainer}
              justify='center'
              alignItems='center'
            >
              <Grid
                item
                container
                className={classes.heroContent}
                sm={8}
                direction='column'
                justify='center'
                alignItems='center'
              >
                <Typography className={classes.heroTitle} variant='h6'>
                  {l10n('Employee Development Without the Waste')}
                </Typography>
                <SkillsCarousel />
                <Button
                  component={Link}
                  to={btnRoute}
                  variant='contained'
                  color='secondary'
                  className={classes.heroButton}
                  size='large'
                >
                  {btnMsg}
                </Button>
              </Grid>
            </Grid>
          </section>
          <Grid item container xs={12} className={classes.heroBottomImage} />

          <Grid container justify='center' className={classes.howItWorksContainer}>
            <Typography className={classes.howItWorksTitle} variant='h6'>
              {l10n('How Brinq Sponsored Learning Works')}
            </Typography>
            <Grid container className={classes.howItWorksCards} justify='space-around'>
              <HowItWorks
                title={l10n('Virtual Prepaid Cards')}
                message={l10n('Managers set the budget. We create the cards. Employees shop.')}
                image={VirtualCard}
                alt={l10n('Brinq Prepaid Virtual Cards')}
              />
              <HowItWorks
                title={l10n('Crucial Employee Skills')}
                message={l10n(
                  "A marketplace of books, audiobooks, and online classes to master what's most important.",
                )}
                image={Skills}
                alt={l10n('soft skills training')}
              />
              <HowItWorks
                title={l10n('No More Forms')}
                message={l10n(
                  'We track everything. No more printing, writing, signing, and scanning forms.',
                )}
                image={NoForms}
                alt={l10n('No more forms')}
              />
            </Grid>
          </Grid>

          <Grid className={classes.homeModules} container justify='center'>
            <Grid
              container
              item
              className={`${classes.homeModuleContainer} ${classes.periwinkleBackground}`}
              justify='center'
            >
              <HomeModule
                title={l10n('Fund employee accounts')}
                subtitle={l10n(
                  'These budgets let your team purchase the development resources they need to win. Unspent funds rollover and can be recouped at any time.',
                )}
                image={CustomBudgetImg}
                imageAlt={l10n('Custom budget')}
                cta={btnMsg}
                direction='reverse'
              />
            </Grid>

            <Grid container item className={classes.homeModuleContainer} justify='center'>
              <HomeQuote
                quote={l10n(
                  '"77% of [CEOs] believe that the biggest threat to their businesses stems from underdeveloped soft skills."',
                )}
                citation={
                  <a href='https://chiefexecutive.net/investing-in-millennials-soft-skills-could-benefit-the-bottom-line/'>
                    {l10n('PriceWaterhouseCoopers’ “CEO Survey”')}
                  </a>
                }
                marginDirection='bottom'
              />
              <HomeModule
                title={l10n('Employees own their development')}
                subtitle={l10n(
                  'They tackle strengths and weaknesses with their own learning style and with the topics they need most.',
                )}
                image={PersonalizationImg}
                imageAlt={l10n('Personalized learning')}
                cta={btnMsg}
                showCTA
              />
              <HomeQuote
                quote={l10n(
                  '"49% of all terminations are attributed to deficient soft skills, which by a factor of 2x, is the number one reason workers are fired."',
                )}
                citation={
                  <a href='https://blogs.wsj.com/cio/2018/06/26/hard-pressed-by-soft-skills-cios-face-talent-challenge/'>
                    {l10n(
                      'Joseph Fuller, Professor of Management Practice at Harvard Business School',
                    )}
                  </a>
                }
                marginDirection='top'
              />
            </Grid>

            <Grid
              container
              item
              className={`${classes.homeModuleContainer} ${classes.periwinkleBackground}`}
              justify='center'
            >
              <HomeModule
                title={l10n('We keep track of everything')}
                subtitle={l10n(
                  'Manager profiles mean you see every dollar spent, every book or class purchased, all without the need for reimbursement forms.',
                )}
                image={PaperworkImg}
                imageAlt={l10n('Automated tracking without reimbursement forms')}
                cta={btnMsg}
                direction='reverse'
              />
            </Grid>
          </Grid>
          {/* <div className={classes.customerContainer}>
            <Typography className={classes.ourCustomersTitle} variant='h6'>
              Our customers
            </Typography>
            <Grid
              className={classes.customerLogos}
              container
              alignItems='center'
              justify='space-between'
            >
              <Logo height='120px' image={Fiiz} alt={l10n('Fiiz logo - Soft skills development')} />
              <Logo image={Dwelo} alt={l10n('Dwelo logo - Soft skills development')} />
              <Logo image={KenGarff} alt={l10n('Ken Garff logo - Soft skills development')} />
              <Logo
                height='75px'
                image={Nestwell}
                alt={l10n('Nestwell logo - Soft skills development')}
              />
            </Grid>
          </div> */}
          <GetStartedAction />
          <Footer />
        </div>
      </DocumentTitle>
    );
  };
}

const _HowItWorks = ({ title, message, image, alt, classes }) => (
  <Grid item sm={12} md={4} lg={3}>
    <Grid container justify='center'>
      <div className={classes.howItWorksdiv}>
        <div className={classes.howItWorksImageContainer}>
          <img className={classes.howItWorksCardImage} height='205px' src={image} alt={alt} />
        </div>
        <Typography className={classes.howItWorksCardTitle} component='h2' variant='h6'>
          {title}
        </Typography>
        <Typography className={classes.howItWorksCardSubtitle}>{message}</Typography>
      </div>
    </Grid>
  </Grid>
);

_HowItWorks.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
};

const _HomeQuote = props => {
  const { classes, quote, citation, marginDirection } = props;
  let homeQuote;
  if (marginDirection === 'top') {
    homeQuote = classes.HomeQuoteTop;
  } else if (marginDirection === 'bottom') {
    homeQuote = classes.HomeQuoteBottom;
  } else {
    homeQuote = classes.HomeQuote;
  }
  return (
    <Grid container xs={12} justify='center'>
      <Grid container direction='column' className={homeQuote} item xs={12}>
        <Grid item>
          <Typography variant='h5' className={classes.HomeQuoteContent}>
            {quote}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.HomeQuoteCitation}>{citation}</Typography>
        </Grid>
        <Grid item>
          <Divider classes={{ root: classes.divider }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

_HomeQuote.propTypes = {
  classes: PropTypes.object,
  quote: PropTypes.string,
  citation: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  marginDirection: PropTypes.oneOf(['bottom', 'top', 'all']),
};

const _HomeModule = props => {
  const {
    classes,
    title,
    subtitle,
    image,
    imageAlt,
    cta,
    direction,
    showCTA = true,
    className,
  } = props;
  let appliedClass;
  if (className) {
    appliedClass = `${classes.homeModule} ${className}`;
  } else {
    appliedClass = classes.homeModule;
  }
  return (
    <Grid item container className={appliedClass}>
      <Grid
        className={
          direction === 'reverse' ? classes.homeModuleContentReverse : classes.homeModuleContent
        }
        container
        alignItems='center'
        justify='space-evenly'
      >
        {direction === 'reverse' && (
          <Grid item xs={10} sm={5} lg={4}>
            <img className={classes.homeModuleImage} src={image} alt={imageAlt} />
          </Grid>
        )}
        <Grid item className={classes.homeModuleText} xs={10} md={5} lg={4}>
          <Typography className={classes.homeModuleTitle} variant='h6'>
            {title}
          </Typography>
          <Typography className={classes.homeModuleSubTitle}>{subtitle}</Typography>
          {showCTA && (
            <Hidden smDown>
              <Button
                component={Link}
                to='/register'
                className={classes.homeModuleButton}
                variant='contained'
                color='primary'
                size='large'
              >
                {cta}
              </Button>
            </Hidden>
          )}
        </Grid>
        {direction !== 'reverse' && (
          <Grid item xs={10} md={5} lg={4}>
            <img className={classes.homeModuleImage} src={image} alt={imageAlt} />
          </Grid>
        )}
        {showCTA && (
          <Hidden mdUp>
            <Button
              component={Link}
              to='/register'
              className={`${classes.homeModuleSmallCTA} ${classes.homeModuleButton}`}
              variant='contained'
              color='primary'
              size='large'
            >
              {cta}
            </Button>
          </Hidden>
        )}
      </Grid>
    </Grid>
  );
};

_HomeModule.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  cta: PropTypes.string,
  direction: PropTypes.string,
  showCTA: PropTypes.bool,
  className: PropTypes.string,
};

// const _Logo = ({ classes, image, alt, height }) => (
//   <Grid container item className={classes.customerLogo} xs={12} md={4} lg={2} justify='center'>
//     <img src={image} alt={alt} height={height || '40px'} />
//   </Grid>
// );

// _Logo.propTypes = {
//   classes: PropTypes.object,
//   image: PropTypes.string,
//   alt: PropTypes.string,
//   height: PropTypes.string,
// };

const styles = theme => ({
  divider: {
    height: '5px',
    backgroundColor: theme.palette.primary.main,
  },
  periwinkleBackground: {
    backgroundColor: '#F9FCFF',
  },
  heroContainer: {
    backgroundColor: '#EE6352',
    padding: '100px 0',
    height: 'calc(100vh - 260px)',
    maxHeight: 720,
    minHeight: 475,
    [theme.breakpoints.down('sm')]: {
      padding: '0 0 40px',
    },
    [theme.breakpoints.only('xs')]: {
      height: '100vh',
    },
    marginTop: 80,
  },
  heroBottomImage: {
    background: `center / cover no-repeat url(${Product})`,
    height: '20vh',
    maxHeight: 200,
  },
  heroTextContainer: {
    height: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  heroContent: {
    alignItems: 'center',
    textAlign: 'center',
    padding: 20,
    borderRadius: 4,
    maxWidth: 900,
    [theme.breakpoints.down('md')]: {
      maxWidth: 500,
    },
  },
  heroSubHeading: {
    fontSize: 17,
    color: '#ffffff',
    marginBottom: 40,
  },
  heroTitle: {
    fontSize: 45,
    fontWeight: 'bold',
    color: '#ffffff',
  },
  heroButton: {
    '&:hover': {
      color: '#EE6352',
    },
  },
  HomeQuoteTop: {
    maxWidth: 600,
    textAlign: 'center',
    marginTop: 120,
    padding: '0 16px',
    [theme.breakpoints.down('md')]: {
      marginBottom: 60,
      marginTop: 40,
    },
  },
  HomeQuoteBottom: {
    maxWidth: 600,
    textAlign: 'center',
    marginBottom: 120,
    padding: '0 16px',
    [theme.breakpoints.down('md')]: {
      marginTop: 60,
      marginBottom: 40,
    },
  },
  HomeQuoteContent: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  HomeQuoteCitation: {
    fontSize: 17,
    fontWeight: 'lighter',
  },
  howItWorksContainer: {
    padding: '80px 16px',
    textAlign: 'center',
  },
  howItWorksTitle: {
    fontSize: 45,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 80,
  },
  homeModuleContainer: {
    padding: '120px 0',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      textAlign: 'center',
    },
  },
  customerContainer: {
    padding: '80px 0',
  },
  ourCustomersTitle: {
    marginBottom: 60,
    fontSize: 45,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  finalCTA: {
    backgroundColor: '#EE6352',
    padding: '80px 0',
  },
  finalCTATitle: {
    fontSize: 45,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  finalCTASubheading: {
    fontSize: 20,
    fontFamily: 'Open Sans',
    color: '#FFF',
  },
  footer: {
    paddingTop: '80px',
    backgroundColor: '#474747',
  },
  footerLinks: {
    marginBottom: 30,
  },
});

const ModuleStyles = theme => ({
  homeModule: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '60px 0',
    },
  },
  homeModuleImage: {
    width: '100%',
  },
  homeModuleContent: {
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  homeModuleContentReverse: {
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  homeModuleTitle: {
    fontSize: 40,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  homeModuleSubTitle: {
    fontSize: 17,
    fontFamily: 'Open Sans',
    marginBottom: 25,
  },
  homeModuleText: {
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 60,
    },
  },
  homeModuleSmallCTA: {
    marginTop: 40,
  },
  homeModuleButton: {
    '&:hover': {
      color: '#FFFFFF',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '40px 0',
    },
  },
});

// const LogoStyles = () => ({
//   customerLogo: {
//     margin: '20px 0',
//   },
// });

const HowItWorksStyles = theme => ({
  howItWorksCards: {
    justifyContent: 'space-around',
  },
  howItWorksdiv: {
    maxWidth: 300,
    marginBottom: 80,
    [theme.breakpoints.down('md')]: {
      marginBottom: 40,
    },
  },
  howItWorksCard: {
    maxWidth: 425,
  },
  howItWorksCardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  howItWorksCardTitle: {
    fontSize: 25,
    margin: '32px 0 16px',
  },
  howItWorksCardSubtitle: {
    fontSize: 17,
    fontFamily: 'Open Sans',
    textAlign: 'center',
  },
});

const HomeModule = withStyles(ModuleStyles)(_HomeModule);
const HomeQuote = withStyles(styles)(_HomeQuote);
// const Logo = withStyles(LogoStyles)(_Logo);
const HowItWorks = withStyles(HowItWorksStyles)(_HowItWorks);

export default compose(withStyles(styles), withWidth())(LandingPage);
