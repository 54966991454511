import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { SocialIcon } from 'react-social-icons';

import orange from '@material-ui/core/colors/orange';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import FooterQuote from './components/FooterQuote';
import Footer from './LandingPage/Footer';
import logo from '../assets/header_logo.png';
import { sendMessage } from '../store/actions/messageActions';
import createLoadingSelector from '../store/actions/loaderActions';

const validator = values => {
  const errors = {};
  if (!values.name) errors.name = 'Name is required';
  if (!values.email) errors.email = 'Email is required';
  if (!/\w+@\w+\.\w+\w/g.test(values.email)) errors.email = 'Email is invalid';
  if (!values.message) errors.message = 'Subject is required';
  return errors;
};

const styles = theme => ({
  root: {
    marginBottom: theme.spacing.unit * 5,
  },
  subtitle: {
    fontSize: 14,
  },
  socialMediaSection: {
    backgroundColor: orange[50],
    marginTop: theme.spacing.unit * 5,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 7}px ${theme.spacing.unit *
      2}px ${theme.spacing.unit * 7}px`,
  },
});

class ContactUs extends Component {
  seed = Math.random();

  static propTypes = {
    classes: PropTypes.object,
    messageSend: PropTypes.func,
    isFetching: PropTypes.bool,
  };

  state = {
    form: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
  };

  handleSubmit = values => {
    const { messageSend } = this.props;
    messageSend(values);
  };

  render = () => {
    const { classes, isFetching } = this.props;
    const { form } = this.state;
    return (
      <Formik initialValues={form} validate={validator} onSubmit={this.handleSubmit}>
        {({ values, errors, handleChange, handleBlur, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid className={classes.root} container justify='center'>
              <Grid item container direction='column' spacing={32} xs={12} sm={6} lg={4}>
                <Grid item container direction='column' alignItems='center'>
                  <Grid item>
                    <a href='/'>
                      <img src={logo} alt='brinq logo' width={200} />
                    </a>
                  </Grid>
                  <Grid item>
                    <Typography variant='h5'>Get in touch with us today</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.subtitle} variant='subtitle1'>
                      Send us a message and will get back to you as soon as possible
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <TextField
                    name='name'
                    label='Name'
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.name}
                    error={!!errors.name}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name='email'
                    label='Email'
                    fullWidth
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.email}
                    error={!!errors.email}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name='subject'
                    label='Subject'
                    fullWidth
                    value={values.subject}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.subject}
                    error={!!errors.subject}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name='message'
                    label='Message'
                    fullWidth
                    multiline
                    rows={4}
                    value={values.message}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    helperText={errors.message}
                    error={!!errors.message}
                  />
                </Grid>

                <Grid item container direction='row' justify='flex-end'>
                  <Grid item>
                    <Button type='submit' variant='contained' color='primary' disabled={isFetching}>
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>

                <Grid item>
                  <section className={classes.socialMediaSection}>
                    <Grid container direction='column' spacing={16}>
                      <Grid item>
                        <Typography variant='h6'>Follow us</Typography>
                      </Grid>
                      <Grid item container direction='row' spacing={8}>
                        <Grid item xs={4}>
                          <SocialIcon url='https://www.facebook.com/Brinqskills/' />
                        </Grid>
                        <Grid item xs={4}>
                          <SocialIcon url='https://twitter.com/Brinqskills' />
                        </Grid>
                        <Grid item xs={4}>
                          <SocialIcon url='https://www.linkedin.com/company/thebrinq/' />
                        </Grid>
                      </Grid>
                    </Grid>
                  </section>
                </Grid>
              </Grid>
            </Grid>
            <FooterQuote seed={this.seed} />
            <Footer />
          </form>
        )}
      </Formik>
    );
  };
}

const loadingSelector = createLoadingSelector(['SEND_MESSAGE']);

const mapStateToProps = state => ({ isFetching: loadingSelector(state) });

const mapDispatchToProps = {
  messageSend: values => sendMessage(values),
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(ContactUs);
