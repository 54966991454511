import {
  HANDLE_NEXT,
  HANDLE_PREVIOUS,
  HANDLE_CARD_CHANGE,
  HANDLE_TEAM_CHANGE,
} from '../actions/accountDetailsActions';

const initState = {
  team: {
    budget: '25',
    emails: '',
  },
  card: {
    businessName: '',
    cardholder: '',
    cardNumber: '',
    expiration: '',
    cvc: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
  },
  step: 0,
};

const accountDetailsReducer = (state = initState, action) => {
  let _state;
  switch (action.type) {
    case HANDLE_NEXT:
      _state = { ...state };
      _state.step = action.payload.step;
      return _state;
    case HANDLE_PREVIOUS:
      _state = { ...state };
      _state.step = action.payload.step;
      return _state;
    case HANDLE_CARD_CHANGE:
      _state = { ...state };
      _state.card = action.payload.card;
      return _state;
    case HANDLE_TEAM_CHANGE:
      _state = { ...state };
      _state.team = action.payload.team;
      return _state;
    default:
      return state;
  }
};

export default accountDetailsReducer;
