import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3,
  },
  textfield: {
    width: 300,
    marginBottom: 15,
  },
});

class SortableTableToolbar extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    inputProps: PropTypes.object,
    contextActions: PropTypes.func,
    classes: PropTypes.object,
    onSearch: PropTypes.func,
    rowsSelected: PropTypes.array,
  };

  static defaultProps = {
    onSearch: () => {},
    contextActions: () => {},
  };

  handleSearch = event => {
    const { onSearch } = this.props;
    onSearch(event.target.value);
  };

  render = () => {
    const { label, placeholder, classes, contextActions, inputProps, rowsSelected } = this.props;
    return (
      <Toolbar className={classes.root}>
        <Grid container justify='space-between' alignItems='flex-end'>
          <Grid item xs={6}>
            <TextField
              className={classes.textfield}
              label={label}
              placeholder={placeholder}
              onChange={this.handleSearch}
              InputProps={inputProps}
            />
          </Grid>
          <Grid item>{contextActions({ rowsSelected })}</Grid>
        </Grid>
      </Toolbar>
    );
  };
}

export default withStyles(styles)(SortableTableToolbar);
