import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FormHelperText } from '@material-ui/core';
import l10n from '../../services/l10n';
import LineDivider from '../components/LineDivider';
import FeatureCard from './FeatureCard';

const styles = theme => ({
  container: {
    [theme.breakpoints.up('sm')]: {
      padding: '40px 200px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px',
    },
  },
  formContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: `0px 110px`,
    },
    textAlign: 'center',
  },
  formControl: {
    marginTop: '120px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  formButton: {
    '&:last-child': {
      marginRight: 0,
    },
    marginRight: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: 15,
    color: '#707070',
    marginBottom: 120,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  sideBySide: {
    width: '40%',
  },
  budgetContainer: {
    textAlign: 'left',
    marginTop: 32,
  },
  budgetOptions: {
    marginRight: 40,
  },
  helperText: {
    textAlign: 'center',
  },
});

const InviteTeam = ({
  onChange,
  team = { emails: '', budget: '' },
  classes,
  onClick,
  width,
  isSolo,
}) => {
  const inviteMembersMessage =
    width === 'xs'
      ? 'Simple, Risk-Free Employee Development'
      : 'Invite your employees to use Brinq and allocate a monthly budget for them to purchase development resources.';
  return (
    <Grid
      className={classes.container}
      direction={width === 'xs' || width === 'sm' ? 'column' : 'row'}
      container
      spacing={32}
    >
      <Grid item md={12} lg={4}>
        <FeatureCard />
      </Grid>
      <Grid item md={12} lg={8}>
        <div className={classes.formContainer}>
          <Typography variant='h2' className={classes.title} gutterBottom>
            {l10n('Invite Team Members')}
          </Typography>
          <Typography variant='subtitle1' className={classes.subtitle} gutterBottom>
            {l10n(inviteMembersMessage)}
          </Typography>
          <TextField
            fullWidth
            required
            name='emails'
            helperText={l10n(
              'To invite multiple employees, separate email addresses using a comma',
            )}
            label={l10n('Employee Email Addresses')}
            value={team.emails}
            onChange={onChange}
            margin='normal'
            FormHelperTextProps={{ className: classes.helperText }}
          />
          <Grid container item className={classes.formControl} justify='flex-end'>
            <LineDivider style={{ marginTop: 40, marginBottom: 16 }} />

            <Buttons classes={classes} isSolo={isSolo} team={team} onClick={onClick} />
          </Grid>
          <Grid container item justify='flex-end'>
            <FormHelperText>Clicking invite will charge your default payment method</FormHelperText>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

const Buttons = withRouter(({ classes, isSolo = false, team, onClick, history }) =>
  isSolo ? (
    <Fragment>
      <Button className={classes.formButton} onClick={() => history.goBack()}>
        {l10n('Back')}
      </Button>
      <Button
        className={classes.formButton}
        variant='contained'
        color='primary'
        disabled={team.emails.trim().length === 0 || team.budget.length === 0}
        onClick={e => onClick(e, team)}
      >
        {l10n('Invite')}
      </Button>
    </Fragment>
  ) : (
    <Button
      className={classes.formButton}
      variant='contained'
      color='primary'
      disabled={team.emails.trim().length === 0 || team.budget.length === 0}
      onClick={onClick}
    >
      {l10n('Next')}
    </Button>
  ),
);

Buttons.propTypes = {
  classes: PropTypes.object.isRequired,
  isSolo: PropTypes.bool.isRequired,
  team: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

InviteTeam.propTypes = {
  onChange: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isSolo: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withWidth(),
)(InviteTeam);
