import React, { Component } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import {
  handleNext,
  handlePrevious,
  handleCardChange,
  handleTeamChange,
  saveAccountDetails,
  inviteTeamMembers,
} from '../../store/actions/accountDetailsActions';
import Header from '../LandingPage/Header';
import Logo from '../../assets/header_logo.png';
import AccountSetupStepper from './AccountSetupStepper';
import StripeCardForm from './StripeCardForm';
import InviteTeam from './InviteTeam';
import FooterQuote from '../components/FooterQuote';

const styles = theme => ({
  setupContainer: {
    padding: '0 16px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 100,
    },
  },
  form: {
    margin: '1em auto 0',
    width: 500,
  },
  formControl: {
    marginTop: '30px',
  },
  terms: {
    color: '#ee6352',
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: 15,
  },
  content: {
    flex: 1,
  },
});

class SetupAccount extends Component {
  seed = Math.random();

  handleNext = () => {
    const { step, _handleNext } = this.props;
    _handleNext(step);
  };

  handlePrevious = () => {
    const { step, _handlePrevious } = this.props;
    _handlePrevious(step);
  };

  onCardChange = e => {
    const { card, _cardChange } = this.props;
    const _card = { ...card };
    _card[e.target.name] = e.target.value;
    _cardChange(_card);
  };

  onTeamChange = e => {
    const { team, _teamChange } = this.props;
    const _team = { ...team };
    _team[e.target.name] = e.target.value;
    _teamChange(_team);
  };

  handleSubmit = async () => {
    const {
      _saveAccountDetails,
      _inviteTeamMembers,
      card,
      team,
      currentUser,
      history,
    } = this.props;
    let promise = Promise.resolve();
    if (team.budget.trim().length > 0 && team.emails.trim().length > 0) {
      promise = _inviteTeamMembers(team, currentUser);
    }

    Promise.all([promise, _saveAccountDetails(card, currentUser.id, team)])
      .then(() => {
        history.push('/dashboard');
      })
      .catch(() => {
        NotificationManager.error('There was an error setting up your account', 'Try again');
      });
  };

  render() {
    const { classes, card, team, width, step } = this.props;
    const isMobile = width === 'xs';
    return (
      <DocumentTitle title='Registration'>
        <Grid container className={classes.content} direction='column' justify='space-between'>
          {isMobile && (
            <Grid item>
              <Header isStatic={true} />
            </Grid>
          )}
          <Grid item container className={classes.setupContainer} justify='center'>
            {!isMobile && (
              <Grid container item xs={10} justify='center'>
                <Grid item>
                  <img src={Logo} height={112} alt='Brinq logo' />
                </Grid>
              </Grid>
            )}
            {step === 0 && (
              <Grid item>
                <InviteTeam team={team} onClick={this.handleNext} onChange={this.onTeamChange} />
              </Grid>
            )}
            {step === 1 && (
              <Grid item>
                <StripeCardForm
                  card={card}
                  handleSubmit={this.handleSubmit}
                  handlePrevious={this.handlePrevious}
                  numEmployees={team.emails.split(',').length}
                  budget={team.budget}
                  onChange={this.onCardChange}
                />
              </Grid>
            )}
            <Grid justify='center' container item xs={12}>
              <AccountSetupStepper step={step} mode={isMobile ? 'mobile' : 'desktop'} />
            </Grid>
          </Grid>
          {!isMobile || (step === 1 && <FooterQuote seed={this.seed} />)}
        </Grid>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({ ...state.accountDetails, currentUser: state.currentUser });

const mapDispatchToProps = dispatch => ({
  _handleNext: details => dispatch(handleNext(details)),
  _handlePrevious: details => dispatch(handlePrevious(details)),
  _cardChange: details => dispatch(handleCardChange(details)),
  _teamChange: details => dispatch(handleTeamChange(details)),
  _saveAccountDetails: (details, id, team) => dispatch(saveAccountDetails(details, id, team)),
  _inviteTeamMembers: (team, user) => dispatch(inviteTeamMembers(team, user)),
});

SetupAccount.propTypes = {
  classes: PropTypes.object.isRequired,
  _handleNext: PropTypes.func.isRequired,
  _handlePrevious: PropTypes.func.isRequired,
  _cardChange: PropTypes.func.isRequired,
  _teamChange: PropTypes.func.isRequired,
  _inviteTeamMembers: PropTypes.func.isRequired,
  _saveAccountDetails: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  step: PropTypes.number.isRequired,
  team: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
};

export default compose(
  withStyles(styles),
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SetupAccount);
