import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import Paper from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';

const styles = () => ({
  sectionA: {
    backgroundColor: '#EE6352',
    height: 150,
  },
  sectionAText: {
    fontSize: 30,
    fontWeight: 500,
    font: 'Roboto',
  },
  sectionB: {
    padding: 20,
    marginBottom: 20,
  },
  sectionBTitle: {
    font: 'Roboto',
    fontWeight: 800,
  },
  paper: {
    margin: 0,
    padding: 0,
  },
  bullet: {
    color: '#337DEC',
    fontSize: 10,
    marginRight: 10,
  },
  feature: {
    fontWeight: 'normal',
    fontSize: 15,
  },
});

const FeatureCard = props => {
  const { classes } = props;
  return (
    <Paper className={classes.paper} elevation={2} square>
      <Grid direction='column' container>
        <Grid item>
          <Grid
            className={classes.sectionA}
            container
            direction='column'
            justify='center'
            alignItems='center'
          >
            <Grid item>
              <Typography className={classes.sectionAText} variant='h4' color='secondary'>
                $25/Month
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' color='secondary'>
                $0 to us, $25 to each team member
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            className={classes.sectionB}
            alignItems='flex-start'
            container
            direction='column'
            spacing={32}
          >
            <Grid item>
              <Typography className={classes.sectionBTitle} variant='h5'>
                Features
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className={classes.feature}>
                <Icon className={classes.bullet}>lens</Icon>
                Instant virtual debit cards - no applications
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className={classes.feature}>
                <Icon className={classes.bullet}>lens</Icon>
                The best books, audiobooks, and online classes
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className={classes.feature}>
                <Icon className={classes.bullet}>lens</Icon>
                Budgets Rollover
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className={classes.feature}>
                <Icon className={classes.bullet}>lens</Icon>
                Budgets can be reallocated
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className={classes.feature}>
                <Icon className={classes.bullet}>lens</Icon>
                Budgets can even be redeemed
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='h6' className={classes.feature}>
                <Icon className={classes.bullet}>lens</Icon>
                We track everything - no reimbursement forms
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default withStyles(styles)(FeatureCard);
