import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import l10n from '../../services/l10n';
import LineDivider from '../components/LineDivider';

const styles = theme => ({
  formContainer: {
    maxWidth: 530,
    textAlign: 'center',
    margin: '0 10px',
  },
  formControl: {
    marginTop: '30px',
    maxWidth: 530,
  },
  formButton: {
    '&:last-child': {
      marginRight: 0,
    },
    marginRight: 8,
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: 15,
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  totalContainer: {
    maxWidth: 392,
    backgroundColor: '#F3F0F0',
    padding: '30px 16px',
    margin: '0 10px',
  },
  contentContainer: {
    maxWidth: 1000,
    paddingTop: 30,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'initial',
    },
  },
  summaryTitle: {
    marginBottom: 30,
    fontSize: 20,
    fontWeight: 'bold',
  },
  item: {
    fontSize: 15,
    color: '#635E5E',
  },
  number: {
    fontSize: 15,
    fontWeight: 'bold',
    marginRight: 16,
  },
  lineItem: {
    maxWidth: 273,
  },
});

const formValid = card => {
  // TODO IMPROVE FORM VALIDATION HERE
  const fields = [
    'businessName',
    'cardholder',
    'cardNumber',
    'expiration',
    'cvc',
    'address1',
    'city',
    'state',
    'zip',
  ];
  let valid = true;
  fields.forEach(field => {
    if (card[field].trim().length === 0) valid = false;
  });

  return valid;
};

const StripeCardForm = ({
  onChange,
  card,
  classes,
  handleSubmit,
  handlePrevious,
  numEmployees,
  budget,
  width,
}) => (
  <Grid container justify='center' alignItems='center' direction='column'>
    <Grid xs={12} item>
      <Typography variant='h2' className={classes.title} gutterBottom>
        {l10n('Add a credit card to your Brinq account')}
      </Typography>
    </Grid>
    <Grid xs={12} item>
      <Typography variant='subtitle1' className={classes.subtitle} gutterBottom>
        {l10n("Every dollar spent goes right to your employees' Brinq Pre-paid Virtual Cards")}
      </Typography>
    </Grid>
    <Grid xs={12} item container spacing={32} justify='center' className={classes.contentContainer}>
      <Grid xs={12} md={6} container item className={classes.formContainer}>
        <Grid xs={12} item>
          <TextField
            fullWidth
            required
            name='businessName'
            label='Business Name'
            value={card.businessName}
            onChange={onChange}
            margin='normal'
          />
        </Grid>
        <Grid item container justify='space-between' spacing={16}>
          <Grid xs={12} sm={6} item>
            <TextField
              fullWidth
              required
              name='cardholder'
              label='Name on Card'
              value={card.cardholder}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <TextField
              fullWidth
              required
              name='cardNumber'
              label='Card Number'
              value={card.cardNumber}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
        </Grid>
        <Grid item container spacing={16} justify='space-between'>
          <Grid xs={12} sm={6} item>
            <TextField
              fullWidth
              required
              name='expiration'
              label='Expiration Date'
              value={card.expiration}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <TextField
              fullWidth
              required
              name='cvc'
              label='CVC'
              value={card.cvc}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
        </Grid>
        <Grid item container justify='space-between' spacing={16}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              required
              name='address1'
              label='Billing Address'
              value={card.address1}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name='address2'
              label='Address line 2'
              value={card.address2}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
        </Grid>
        <Grid item container justify='space-between' spacing={16}>
          <Grid xs={12} sm={6} item>
            <TextField
              fullWidth
              required
              name='city'
              label='City'
              value={card.city}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
          <Grid xs={12} sm={6} item>
            <TextField
              fullWidth
              required
              name='state'
              label='State'
              value={card.state}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
        </Grid>
        <Grid item container justify='flex-start'>
          <Grid sm={6} xs={12} item>
            <TextField
              fullWidth
              required
              name='zip'
              label='Zip Code'
              value={card.zip}
              onChange={onChange}
              margin='normal'
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container alignItems='center' className={classes.totalContainer} xs={12} sm={6}>
        <Typography variant='h2' className={classes.summaryTitle}>
          {l10n('Purchase Summary')}
        </Typography>
        <LineItem item={l10n('Number of Employees')} number={numEmployees} classes={classes} />
        <LineItem
          item={l10n('Budget per Employee')}
          number={`$${Number(budget).toFixed(2)}`}
          classes={classes}
        />
        <LineDivider style={{ marginTop: 32, marginBottom: 16 }} />
        <LineItem
          item={l10n('Total Cost per Month')}
          number={`$${(numEmployees * Number(budget)).toFixed(2)}`}
          classes={classes}
          numberStyle={{ fontSize: 20 }}
        />
      </Grid>
    </Grid>
    <Grid container item className={classes.formControl} justify='flex-end'>
      <LineDivider style={{ marginTop: 40, marginBottom: 16 }} />
      <Button className={classes.formButton} onClick={handlePrevious}>
        {l10n('Back')}
      </Button>
      <Button
        className={classes.formButton}
        disabled={!formValid(card)}
        variant='contained'
        color='primary'
        onClick={handleSubmit}
      >
        {l10n('Submit')}
      </Button>
    </Grid>
  </Grid>
);

const LineItem = ({ item, number, classes, numberStyle = {} }) => (
  <Grid className={classes.lineItem} container justify='space-between' alignItems='center'>
    <Typography className={classes.lineItem}>{item}</Typography>
    <Typography className={classes.number} style={numberStyle}>
      {number}
    </Typography>
  </Grid>
);

LineItem.propTypes = {
  item: PropTypes.string,
  number: PropTypes.string,
  classes: PropTypes.object,
  numberStyle: PropTypes.object,
};

StripeCardForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  card: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  numEmployees: PropTypes.number,
  budget: PropTypes.number,
  handlePrevious: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  withWidth(),
)(StripeCardForm);
