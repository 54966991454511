import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';

class VerifyLanding extends Component {
  componentDidUpdate() {
    const { currentUser, history } = this.props;
    if (currentUser.authUser) {
      axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/verifyEmail`, {
        uid: currentUser.id,
      });
      if (currentUser.isManager) {
        history.push('/setupAccount');
      } else {
        history.push('/shop');
      }
    }
  }

  render() {
    return <div />;
  }
}

VerifyLanding.propTypes = {
  currentUser: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({ currentUser: state.currentUser });

export default connect(mapStateToProps)(VerifyLanding);
