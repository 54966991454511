import React, { Component } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import '../css/Header.css';

// New imports
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import { connect } from 'react-redux';
import VirtualCardModal from './VirtualCardModal';
import NavButton from './components/NavButton';
import NavMenu from './components/NavMenu';
import DynamicAvatar from './components/DynamicAvatar';
import GlobalSearch from './GlobalSearch';
import l10n from '../services/l10n';
import { signOut } from '../store/actions/authActions';
import { toggleModal, VCARD_MODAL } from '../store/actions/modalActions';

import Logo from '../assets/header_logo.png';

const styles = theme => ({
  root: {
    display: 'flex',
    height: 78,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    borderBottom: '1px solid #EEEEEE',
    display: 'flex',
  },
  logo: {
    marginLeft: theme.spacing.unit * 7,
    marginRight: theme.spacing.unit * 8,
    height: 75,
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
  navButton: {
    fontSize: 16,
    textTransform: 'none',
    fontFamily: 'Roboto',
    color: '#707070',
    opacity: 0.8,
    borderRadius: 0,
    lineHeight: '62px',
  },
  remainingBudget: {
    fontSize: 25,
  },
  cardIcon: {
    width: 36,
    height: 36,
  },
  creditCardButton: {
    color: '#006EFF',
    marginLeft: -12,
    marginRight: 20,
  },
  virtualCardLauncher: {
    cursor: 'pointer',
    marginRight: '8px',
  },
});

class AppHeader extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
    };
  }

  static defaultProps = {
    currentUser: {
      remainingBudget: 0,
      isManager: false,
    },
  };

  open(modal) {
    this.setState({ [modal]: true });
  }

  close(modal) {
    this.setState({ [modal]: false });
  }

  handleTabClick = (event, link) => {
    const { history } = this.props;
    history.push(`/${link}`);
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (e, type) => {
    if (type) {
      const { history } = this.props;
      history.push(`/${type}`);
    }

    this.setState({ anchorEl: null });
  };

  handleSignout = () => {
    const { history } = this.props;
    firebase
      .auth()
      .signOut()
      .then(() => history.push('/login'));
  };

  render() {
    const { currentUser, classes, history, match, _toggleModal, modals } = this.props;
    const { anchorEl } = this.state;
    const showTabs = !!currentUser;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position='fixed' color='secondary' className={classes.appBar}>
          <Grid container wrap='nowrap' alignItems='center'>
            <Grid item className={classes.logo}>
              <a href='/'>
                <img height={75} src={Logo} alt='header logo' />
              </a>
            </Grid>
            <Grid container item>
              {showTabs && (
                <NavButton
                  active={match.path.includes('dashboard')}
                  onClick={e => this.handleTabClick(e, 'dashboard')}
                >
                  {l10n('Dashboard')}
                </NavButton>
              )}
              {showTabs && (
                <NavButton
                  id='Header-Shop'
                  active={match.path.includes('shop')}
                  onClick={e => this.handleTabClick(e, 'shop')}
                >
                  {l10n('Shop')}
                </NavButton>
              )}
            </Grid>
            <Grid item>
              <GlobalSearch history={history} match={match} />
            </Grid>

            <Grid item>
              <Toolbar variant='dense'>
                {!currentUser.isManager && (
                  <Button
                    id='Header-VirtualCardButton'
                    className={classes.creditCardButton}
                    onClick={() => _toggleModal(VCARD_MODAL, true)}
                    color='primary'
                    aria-label='Profile'
                  >
                    <Typography variant='h6' style={{ marginRight: 10, whiteSpace: 'nowrap' }}>
                      {showTabs &&
                        currentUser.remainingBudget &&
                        `$ ${Number(currentUser.remainingBudget).toFixed(2) || 0}`}
                    </Typography>
                    <CreditCardIcon />
                  </Button>
                )}
                <IconButton
                  className={classes.accountButton}
                  aria-label='Account'
                  aria-owns={anchorEl ? 'simple-menu' : undefined}
                  aria-haspopup='true'
                  onClick={this.handleClick}
                >
                  <DynamicAvatar currentUser={currentUser} size={36} />
                </IconButton>
              </Toolbar>
            </Grid>
          </Grid>
          <NavMenu
            anchorEl={anchorEl}
            handleClose={this.handleClose}
            handleSignout={this.handleSignout}
            user={currentUser}
            history={history}
          />
        </AppBar>
        <Modal
          aria-labelledby='simple-modal-title'
          aria-describedby='simple-modal-description'
          open={modals.vCardModal}
          onClose={() => _toggleModal(VCARD_MODAL, false)}
          disableAutoFocus
        >
          <VirtualCardModal />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  currentUser: state.currentUser,
  modals: state.modals,
});

const mapDispatchToProps = dispatch => ({
  _signOut: () => dispatch(signOut()),
  _toggleModal: (type, state) => dispatch(toggleModal(type, state)),
});

AppHeader.propTypes = {
  currentUser: PropTypes.shape({
    isManager: PropTypes.bool,
    remainingBudget: PropTypes.number,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  classes: PropTypes.object.isRequired,
  _toggleModal: PropTypes.func,
  modals: PropTypes.object,
  match: PropTypes.shape({
    path: PropTypes.string,
  }),
};

export default withStyles(styles)(
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(AppHeader),
  ),
);

const Budget = ({ budget }) => (
  <span className='Header-budgetContainer'>
    {`$${budget.toFixed(2)}`}
    <span className='Header-remainingBudget'>{l10n('budget remaining')}</span>
  </span>
);

Budget.propTypes = { budget: PropTypes.number.isRequired };
