import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import {
  doSignOut,
  doSignInWithPopup,
  doSignInWithEmailAndPassword,
  doCreateUserWithEmailAndPassword,
  doResendEmailVerificationLink,
} from '../../services/Auth';

export const HANDLE_USER_CHANGE = 'HANDLE_USER_CHANGE';
export const VERIFY_INVITATION = 'VERIFY_INVITATION';
export const CLEAR_INVITE_USER = 'CLEAR_INVITE_USER';

const baseurl = process.env.REACT_APP_FIREBASE_ENV_PATH;

export const signIn = user => dispatch => {
  if (user.provider === 'email') {
    doSignInWithEmailAndPassword(user.email, user.password).then(res => {
      if (res.uid) {
        dispatch({ type: 'LOGIN_SUCCESS', res });
      } else {
        dispatch({ type: 'LOGIN_ERROR', res });
      }
    });
  } else {
    doSignInWithPopup(user.provider).then(res => {
      if (res.user.uid) {
        dispatch({ type: 'LOGIN_SUCCESS', res });
      } else {
        dispatch({ type: 'LOGIN_ERROR', res });
      }
    });
  }
};

export const signOut = () => dispatch => {
  doSignOut().then(() => {
    dispatch({ type: 'SIGNOUT_SUCCESS' });
  });
};

export const signUp = (user, inviteUser) => (dispatch, getState, { getFirestore }) => {
  doCreateUserWithEmailAndPassword(user, inviteUser).then(res => {
    if (res.status === 'success') {
      const db = getFirestore();
      db.collection('inviteUsers')
        .doc(user.email)
        .delete()
        .then(() => {
          dispatch({ type: 'SIGNUP_SUCCESS', res });
        })
        .catch(() => {
          dispatch({
            type: 'SIGNUP_ERROR',
            res: { title: 'Sign up error', message: 'Could not complete sign up at this time' },
          });
        });
    } else {
      dispatch({ type: 'SIGNUP_ERROR', res });
    }
  });
};

export const sendEmailVerification = () => dispatch => {
  doResendEmailVerificationLink().then(() => {
    dispatch({ type: 'VERIFICATION_SUCCESS' });
  });
};

export const changeUser = user => dispatch => {
  dispatch({ type: HANDLE_USER_CHANGE, payload: { user } });
};

export const verifyInvitation = user => async (dispatch, getState, { getFirestore }) => {
  const payload = {};
  const db = getFirestore();

  const userDoc = await db
    .collection('inviteUsers')
    .doc(user.email)
    .get();

  payload.inviteUser = userDoc;

  dispatch({ type: VERIFY_INVITATION, payload });
};

export const clearInviteUser = () => dispatch => {
  dispatch({ type: CLEAR_INVITE_USER });
};

export const sendPasswordResetRequest = email => dispatch => {
  axios
    .post(`${baseurl}/passwordRecovery`, { email })
    .then(() => {
      NotificationManager.success('Email sent sucessfully!');
      return dispatch({ type: 'PASSWORD_RESET_REQUEST_SUCCESS' });
    })
    .catch(err => {
      const { data } = err.response;
      NotificationManager.error(data.message);
    });
};

export const sendNewPasswordRequest = (password, token) => dispatch => {
  axios
    .post(`${baseurl}/passwordReset`, { password, token })
    .then(() => {
      NotificationManager.success('Password change sucessfully!');
      return dispatch({ type: 'NEW_PASSWORD_REQUEST_SUCCESS' });
    })
    .catch(err => {
      const { data } = err.response;
      NotificationManager.error(data.message);
    });
};
