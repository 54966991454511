import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#EE6352',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#EE6352',
    },
    background: {
      default: '#FFFFFF',
    },
  },
  typography: {
    title: {
      fontSize: '16px',
    },
    body1: {
      fontSize: 14,
      fontFamily: 'Open Sans',
    },
    body2: {
      fontSize: '12px',
      fontFamily: 'Roboto',
    },
    h6: {
      fontSize: 16,
    },
    fontFamily: ['Roboto', '"Open Sans"'].join(','),
    useNextVariants: true,
  },
  mixins: {
    toolbar: {
      minHeight: 75,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 67,
      },
      '@media (min-width:600px)': {
        minHeight: 83,
      },
    },
  },
});

export default theme;
