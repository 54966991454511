import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import l10n from '../services/l10n';

const styles = () => ({
  banner: {
    backgroundColor: '#EE6352',
    color: '#fff',
    height: '100vh',
    textAlign: 'center',
    '& p': { color: '#fff' },
  },
  bannerTitle: {
    color: '#FFFFFF',
    fontSize: 45,
    fontWeight: 'bold',
    lineHeight: '56px',
    maxWidth: 500,
  },
});

const Banner = props => {
  const { classes } = props;
  return (
    <Grid container item xs={6} justify='center' alignItems='center' className={classes.banner}>
      <Grid item xs={8}>
        <Typography variant='h3' className={classes.bannerTitle}>
          {l10n('Employee Development Made Easy')}
        </Typography>
      </Grid>
    </Grid>
  );
};

Banner.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Banner);
