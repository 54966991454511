import React from 'react';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import l10n from '../../services/l10n';

const styles = theme => ({
  cta: {
    backgroundColor: '#EE6352',
    [theme.breakpoints.up('sm')]: {
      padding: '60px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  section: {
    [theme.breakpoints.up('sm')]: {
      padding: 60,
    },
    [theme.breakpoints.down('sm')]: {
      padding: 20,
    },
  },
  title: {
    [theme.breakpoints.up('sm')]: {
      fontSize: 45,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 35,
    },
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  subheading: {
    fontSize: 20,
    fontFamily: 'Open Sans',
    color: '#FFF',
  },
  ctaButton: {
    minWidth: 182,
  },
});

const GetStartedAction = props => {
  const { classes } = props;
  return (
    <Grid className={classes.cta} container direction='row' alignItems='center'>
      <Grid item sm={12} md={9}>
        <Grid className={classes.section} container direction='row' alignItems='center'>
          <Grid container item>
            <Typography className={classes.title} variant='h6'>
              {l10n('Get your employees their Brinq Pre-paid Virtual Card today.')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12} md={3}>
        <div className={classes.section}>
          <Button
            component={Link}
            to='/register'
            variant='contained'
            color='secondary'
            size='large'
            className={classes.ctaButton}
          >
            Get Started Now
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(GetStartedAction);
