import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';

import Header from './LandingPage/Header';
import termsJson from '../json/terms';

const styles = theme => ({
  subheader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 240,
    marginTop: 80,
    backgroundColor: '#eaeaea',
  },
  subheaderTitle: {
    font: 'Roboto Black',
    fontWeight: 800,
  },
  sticky: {
    position: 'sticky',
    top: 0,
  },
  list: {
    paddingLeft: 100,
    paddingTop: 90,
  },
  listHeader: {
    fontSize: 20,
    font: 'Roboto Black',
    fontWeight: 800,
  },
  listTitle: {
    font: 'Roboto Black',
    fontWeight: 800,
    marginBottom: 40,
  },
  content: {
    margin: '26px 0px 100px 0px',
  },
  paper: {
    padding: 100,
    marginRight: 100,
  },
  descriptions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 40,
  },
  bullet: {
    marginRight: 28,
    color: '#1473e6',
    fontSize: 15,
  },
  anchor: {
    display: 'block',
    position: 'relative',
    top: -210,
    visibility: 'hidden',
  },
  bold: {
    fontWeight: 800,
  },
});

const TermsAndConditions = ({ classes }) => {
  const [current, setCurrent] = useState(0);
  return (
    <Grid container direction='column'>
      <Grid item>
        <Header />
      </Grid>
      <Grid item>
        <SubHeader classes={classes} />
      </Grid>
      <Grid item>
        <Grid className={classes.content} container direction='row'>
          <Grid item sm={12} md={3}>
            <div className={classes.sticky}>
              <List className={classes.list}>
                <ListItem>
                  <ListItemText
                    primaryTypographyProps={{ className: classes.listHeader, variant: 'h4' }}
                    primary='Terms'
                  />
                </ListItem>
                {termsJson.map((item, index) => (
                  <ListItem
                    key={item.text}
                    button
                    onClick={e => setCurrent(index)}
                    component='a'
                    href={`#${item.href}`}
                  >
                    <ListItemText
                      primaryTypographyProps={{
                        className: index === current ? classes.bold : null,
                      }}
                      primary={item.text}
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          </Grid>
          <Grid item sm={12} md={9}>
            <Paper className={classes.paper} elevation={1} square>
              {termsJson.map(item => (
                <div key={item.text}>
                  <Typography className={classes.listTitle} variant='h4'>
                    {item.text}
                  </Typography>
                  {item.descriptions.map(desc => (
                    <div key={desc} className={classes.descriptions}>
                      <Icon className={classes.bullet}>lens</Icon>
                      <div className={classes.anchor} id={item.href} />
                      <Typography variant='body1'>{desc}</Typography>
                    </div>
                  ))}
                </div>
              ))}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

TermsAndConditions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SubHeader = ({ classes }) => (
  <div className={classes.subheader}>
    <Typography className={classes.subheaderTitle} variant='h3'>
      Brinq Terms and Conditions
    </Typography>
  </div>
);

SubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TermsAndConditions);
