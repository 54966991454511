import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import Firebase from '../../services/Firebase';
import db from '../../services/Firestore';
import GA from '../../js/components/GA';
import { LAUNCH_WALKTHROUGH } from './modalActions';

export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETED_USER = 'DELETED_USER';

const _clearUser = dispatch => {
  localStorage.removeItem('user');
  dispatch({ type: SET_USER, payload: {} });
};

export const setUser = user => dispatch => {
  if (!_.isNil(user)) {
    db.collection('users')
      .doc(user.uid)
      .get()
      .then(async fullUser => {
        if (fullUser.exists) {
          const fullUserData = fullUser.data();
          GA.setAttribute({ userId: fullUserData.email });
          const persistUser = { authUser: user, ...fullUserData };
          if (!fullUserData.isManager && fullUserData.stripeId) {
            // If the user is an employee and has an issuing card get their budget
            const budgetDoc = await db
              .collection('budgets')
              .doc(fullUserData.stripeId)
              .get();
            persistUser.remainingBudget = budgetDoc.data().budget;
          }
          localStorage.setItem('user', JSON.stringify(persistUser));
          if (!fullUserData.finishedWalkthrough) {
            dispatch({ type: LAUNCH_WALKTHROUGH });
          }
          dispatch({ type: SET_USER, payload: persistUser });
        } else {
          _clearUser(dispatch);
        }
      })
      .catch(() => _clearUser(dispatch));
  } else {
    _clearUser(dispatch);
  }
};

export const registerAuthListener = () => dispatch => {
  Firebase.auth().onAuthStateChanged(user => {
    dispatch(setUser(user));
  });
};

export const saveUser = user => async (dispatch, getState) => {
  const { currentUser } = getState();
  db.collection('users')
    .doc(user.id)
    .update(user)
    .then(() => {
      if (currentUser.id === user.id) {
        dispatch({ type: UPDATE_USER, payload: user });
      }
      NotificationManager.success('Update succeeded');
    })
    .catch(() => {
      NotificationManager.error(
        'The account update could not be saved',
        'Please contact us if the problem persists',
      );
    });
};

export const deleteUser = user => async (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  await firestore
    .collection('users')
    .doc(user.id)
    .update({ status: 'deleted' });
  dispatch({ type: DELETED_USER });
  NotificationManager.success('Update succeeded');
};
