import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import GroupImage from '../../assets/group.png';

const styles = {
  portrait: {
    minHeight: 200,
  },
  portraitContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  portraitSubheading: {
    textAlign: 'center',
  },
};

const DefaultContent = ({
  image = GroupImage,
  title = 'Enter Title Here!',
  subheading = 'Enter Subheading Here!',
  buttonText = 'Write Button Action!',
  classes,
  onButtonClick = () => {},
}) => (
  <Fragment>
    <section name='portrait' className={classes.portrait}>
      <Grid container direction='column' alignItems='center' spacing={8}>
        <Grid item>
          <img src={image} alt='group' />
        </Grid>
        <Grid item>
          <Typography variant='h6' className={classes.portraitTitle}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='subtitle1' className={classes.portraitSubheading}>
            {subheading}
          </Typography>
        </Grid>
      </Grid>
    </section>
    <Divider />
    <center>
      <Button color='primary' fullWidth onClick={onButtonClick}>
        {buttonText}
      </Button>
    </center>
  </Fragment>
);

DefaultContent.propTypes = {
  classes: PropTypes.object,
  image: PropTypes.string,
  title: PropTypes.string,
  subheading: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default withStyles(styles)(DefaultContent);
