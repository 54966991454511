import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

import { inviteTeamMembers } from '../../store/actions/accountDetailsActions';
import InviteMembers from '../AccountSetup/InviteTeam';
import brinqHeader from '../../assets/header_logo.png';

class InviteMembersLanding extends Component {
  static propTypes = {
    invite: PropTypes.func,
    currentUser: PropTypes.object,
    history: PropTypes.object,
  };

  state = {
    team: {
      emails: '',
      budget: '25',
    },
  };

  handleChange = e => {
    const { value } = e.target;
    this.setState(prev => ({ team: { ...prev.team, emails: value } }));
  };

  handleClick = () => {
    const { invite, currentUser, history } = this.props;
    const { team } = this.state;
    invite(team, currentUser);
    setTimeout(() => history.push('/dashboard'), 1000); // Adding a small delay to sync with the notif popping out
  };

  render = () => {
    const { team } = this.state;
    return (
      <Grid container alignItems='center' direction='column'>
        <Grid item>
          <img src={brinqHeader} height={100} alt='Header' />
        </Grid>
        <Grid item>
          <InviteMembers
            team={team}
            isSolo={true}
            onChange={this.handleChange}
            onClick={this.handleClick}
          />
        </Grid>
      </Grid>
    );
  };
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

const mapDispatchToProps = dispatch => ({
  invite: (team, mngr) => dispatch(inviteTeamMembers(team, mngr, false)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(InviteMembersLanding);
