import { withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import SmsFailed from '@material-ui/icons/SmsFailedOutlined';
import HelpOutline from '@material-ui/icons/HelpOutline';
import ContactSupport from '@material-ui/icons/ContactSupportOutlined';
import SignOut from '@material-ui/icons/ExitToApp';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import DynamicAvatar from './DynamicAvatar';
import l10n from '../../services/l10n';

const styles = () => ({
  avatar: {
    backgroundColor: '#006EFF',
  },
  avatarText: {
    color: '#fff',
    fontSize: 13,
  },
  darkText: {
    color: '#000000',
    fontWeight: 500,
    '& span': {
      color: '#000',
      fontWeight: 500,
    },
  },
});

const NavMenu = ({ anchorEl, handleClose, handleSignout, user, classes }) => (
  <Popper
    open={Boolean(anchorEl)}
    anchorEl={anchorEl}
    transition
    disablePortal
    onClose={handleClose}
    placement='bottom-end'
  >
    {({ TransitionProps }) => (
      <Grow {...TransitionProps} id='menu-list-grow'>
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <Fragment>
              <MenuList>
                <MenuItem onClick={e => handleClose(e, 'editProfile')}>
                  <DynamicAvatar className={classes.avatar} currentUser={user} size={36} />
                  <ListItemText
                    primary={`${user.firstName} ${user.lastName}`}
                    secondary={user.email}
                  />
                </MenuItem>
              </MenuList>
              <Divider />
              <MenuList>
                <MenuItem onClick={e => handleClose(e, 'feedback')}>
                  <ListItemIcon>
                    <SmsFailed />
                  </ListItemIcon>
                  <ListItemText>{l10n('Beta Feedback')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleClose(e, 'faqs')}>
                  <ListItemIcon>
                    <HelpOutline />
                  </ListItemIcon>
                  <ListItemText>{l10n('FAQs')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleClose(e, 'howitworks')}>
                  <ListItemIcon>
                    <HelpOutline />
                  </ListItemIcon>
                  <ListItemText>{l10n('How Brinq Works')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={e => handleClose(e, 'support')}>
                  <ListItemIcon>
                    <ContactSupport />
                  </ListItemIcon>
                  <ListItemText>{l10n('Support')}</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleSignout}>
                  <ListItemIcon className={classes.darkText}>
                    <SignOut />
                  </ListItemIcon>
                  <ListItemText className={classes.darkText}>{l10n('Logout')}</ListItemText>
                </MenuItem>
              </MenuList>
            </Fragment>
          </ClickAwayListener>
        </Paper>
      </Grow>
    )}
  </Popper>
);

const Avatar = ({ letters, classes }) => (
  <div className={classes.avatar}>
    <span className={classes.avatarText}>{letters}</span>
  </div>
);

Avatar.propTypes = {
  letters: PropTypes.string,
  classes: PropTypes.object,
};

NavMenu.propTypes = {
  anchorEl: PropTypes.object,
  handleClose: PropTypes.func,
  handleSignout: PropTypes.func,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
  classes: PropTypes.object,
};

export default withStyles(styles)(NavMenu);
