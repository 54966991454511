import axios from 'axios';
import uuid from 'uuid-random';
import { NotificationManager } from 'react-notifications';

export const EMPLOYEE_LOAD = 'EMPLOYEE_LOAD';
export const PURCHASEHISTORY_LOAD = 'PURCHASEHISTORY_LOAD';
export const REQUESTCLASS_LOAD = 'REQUESTCLASS_LOAD';
export const DELETE_EMPLOYEES = 'DELETE_EMPLOYEES';
export const REALLOCATE_DELETED_EMPLOYEE_FUNDS = 'REALLOCATE_DELETED_EMPLOYEE_FUNDS';
export const SET_EDIT_USER = 'SET_EDIT_USER';
export const RESET_MANAGER_ACTION_STATUS = 'RESET_MANAGER_ACTION_STATUS';
export const CHANGE_ACTION_STATUS = 'CHANGE_ACTION_STATUS';
export const INVITE_TEAM_MEMBERS = 'INVITE_TEAM_MEMBERS';
export const GET_CUSTOMER = 'GET_CUSTOMER';
export const UPDATE_MANAGER_SUCCESS = 'UPDATE_MANAGER_SUCCESS';

export const getEmployees = managerId => async (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  const employees = [];
  const budgets = {};

  try {
    const budgetSnap = await firestore
      .collection('budgets')
      .where('managerId', '==', managerId)
      .get();
    budgetSnap.forEach(doc => {
      budgets[doc.id] = doc.data();
    });
    const querySnapshot = await firestore
      .collection('users')
      .where('managerId', '==', managerId)
      .get();
    querySnapshot.forEach(doc => {
      const emp = doc.data();
      emp.remainingBudget = budgets[emp.stripeId].budget;
      employees.push(emp);
    });

    const inviteUsersRef = await firestore
      .collection('inviteUsers')
      .where('managerId', '==', managerId)
      .get();
    inviteUsersRef.forEach(doc => {
      const emp = doc.data();
      employees.push({ id: doc.id, status: 'invited', firstName: '', lastName: 'N/A', ...emp });
    });

    dispatch({ type: EMPLOYEE_LOAD, payload: { employees } });
  } catch (err) {
    NotificationManager.error('Error loading employes');
    throw new Error(err);
  }
};

export const loadPurchaseHistory = () => (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  const { currentUser } = getState();
  const managerId = currentUser.isManager ? currentUser.id : currentUser.managerId;
  const transactions = [];

  firestore
    .collection('transactions')
    .where('managerId', '==', managerId)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        transactions.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: PURCHASEHISTORY_LOAD, payload: { purchaseHistory: transactions } });
    })
    .catch(e => {
      throw new Error(e);
    });
};

export const loadRequestClass = managerId => (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  const merchandiseRequests = [];

  firestore
    .collection('merchandiseRequests')
    .where('managerId', '==', managerId)
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        merchandiseRequests.push({ ...doc.data(), id: doc.id });
      });
      dispatch({ type: REQUESTCLASS_LOAD, payload: { requestClass: merchandiseRequests } });
    })
    .catch(e => {
      throw new Error(e);
    });
};

export const deleteEmployees = users => async (dispatch, getState, { getFirestore }) => {
  const {
    currentUser: { stripeId, id },
  } = getState();
  try {
    await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/deleteEmployees`, {
      employees: users,
      stripeId,
    });

    dispatch(getEmployees(id, { getFirestore, dispatch }));
    NotificationManager.success('Successfully deleted employees');
  } catch (err) {
    NotificationManager.error(`There was an issue deleting employees ${err}`);
    throw new Error(err);
  }
};

export const reallocateBudget = data => async (dispatch, getState, { getFirestore }) => {
  const { deletedEmployees, selectedEmployees } = data;
  const {
    currentUser: { id },
  } = getState();
  try {
    await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/reallocateFunds`, {
      deletedEmployees,
      selectedEmployees,
      managerId: id,
    });

    dispatch(getEmployees(id, { getFirestore, dispatch }));
    NotificationManager.success('Successfully reallocated amount!');
  } catch (err) {
    dispatch({ type: CHANGE_ACTION_STATUS, payload: { status: 'error' } });
    NotificationManager.error(`Error on reallocation: ${err}`);
  }
};

export const getEmployee = id => (dispatch, getState, { getFirestore }) => {
  const db = getFirestore();
  db.collection('users')
    .doc(id)
    .get()
    .then(doc => {
      const user = doc.data();
      // eslint-disable-next-line
      dispatch(setEditUser(user));
    });
};

export const setEditUser = user => (dispatch, getState) => {
  if (user.id === getState().currentUser.id) {
    localStorage.setItem('user', JSON.stringify(user));
  }
  dispatch({ type: SET_EDIT_USER, payload: user });
};

export const resetManagerStatus = () => dispatch => {
  dispatch({ type: RESET_MANAGER_ACTION_STATUS });
};

export const getCustomer = () => async (dispatch, getState) => {
  const manager = getState().currentUser;
  const response = await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/getCustomer`, {
    stripeId: manager.stripeId,
  });
  // eslint-disable-next-line
  const { sources, default_source } = response.data;
  dispatch({
    type: GET_CUSTOMER,
    payload: { sources: sources.data, defaultSource: default_source },
  });
};

export const updateCustomer = fields => async (dispatch, getState) => {
  const manager = getState().currentUser;
  const response = await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/updateCustomer`, {
    stripeId: manager.stripeId,
    fields,
  });
  NotificationManager.success('Change primary card successfully!');
  // eslint-disable-next-line
  const { default_source } = response.data;
  dispatch({ type: UPDATE_MANAGER_SUCCESS, payload: { defaultSource: default_source } });
};

export const addCard = (source, setSubmitting) => async (dispatch, getState) => {
  const manager = getState().currentUser;
  const idempotencyKey = uuid();

  const stripeCard = {
    number: source.number.trim(),
    exp_month: source.expiryDate.trim().split('/')[0],
    exp_year: source.expiryDate.trim().split('/')[1],
    cvc: source.cvc,
    name: source.name,
    address_line1: source.address,
    address_city: source.city,
    address_state: source.state,
    address_zip: source.postalCode,
    address_country: source.country,
  };

  // eslint-disable-next-line
  Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY);

  // eslint-disable-next-line
  Stripe.card.createToken(stripeCard, async (status, res) => {
    if (res.error) {
      NotificationManager.error(`Error upon adding card ${res.error}`);
      return res.error;
    }

    await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/addCard`, {
      userId: manager.id,
      businessName: manager.businessName || '',
      token: res.id,
      idempotencyKey,
    });
    NotificationManager.success('Added card successfully!');
    dispatch(getCustomer());
    dispatch({ type: UPDATE_MANAGER_SUCCESS });
    setSubmitting(false);
  });
};

export const deleteCard = cardId => async (dispatch, getState) => {
  const manager = getState().currentUser;
  await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/deleteCard`, {
    stripeId: manager.stripeId,
    cardId,
  });
  NotificationManager.success('Deleted card successfully!');
  dispatch(getCustomer());
  dispatch({ type: UPDATE_MANAGER_SUCCESS });
};
