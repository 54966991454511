import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import ShopImg from '../../assets/Image_8.png';
import VirtualCardImg from '../../assets/Group_546.png';
import AddBookToCartImg from '../../assets/add_book_to_cart.png';
import UseBrinqPrepaidCardImg from '../../assets/use_brinq_prepaid_card.png';

const marginTopFactor = 5;

const styles = theme => ({
  header: {
    color: '#1D6FDD',
    fontFamily: 'Roboto',
    fontSize: 45,
    fontWeight: 800,
  },
  text: {
    marginTop: theme.spacing.unit * marginTopFactor,
    fontFamily: 'Roboto',
    fontSize: 25,
    fontWeight: 800,
  },
  img: {
    marginTop: theme.spacing.unit * marginTopFactor,
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

const ShopPage = ({ classes }) => (
  <Fragment>
    <Typography className={classes.header} variant='h4'>
      Shop
    </Typography>
    <Typography className={classes.text} variant='h6'>
      Step 1: Search our vast inventory
    </Typography>
    <Typography variant='body1'>Choose from books, audiobooks, and courses</Typography>
    <img className={classes.img} src={ShopImg} alt='shop' />
    <Typography className={classes.text} variant='h6'>
      Step 2: Click an item to visit our partner's site
    </Typography>
    <Typography variant='body1'>
      You'll go to our partner sites to complete checkout, remember to use your Brinq card,
      including the billing address shown on your card
    </Typography>
    <img className={classes.img} src={VirtualCardImg} alt='virtual card' />
    <Typography className={classes.text} variant='h6'>
      Step 3: Add your item to our partner's cart
    </Typography>
    <Typography variant='body1'>
      You can choose the digital, audio, or physical version for books
    </Typography>
    <img className={classes.img} src={AddBookToCartImg} alt='virtual card' />
    <Typography className={classes.text} variant='h6'>
      Step 4: Add your Brinq Prepaid card and complete checkout
    </Typography>
    <Typography variant='body1'>
      Be sure to save your card information for future use with this partner
    </Typography>
    <img className={classes.img} src={UseBrinqPrepaidCardImg} alt='virtual card' />
  </Fragment>
);

ShopPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(ShopPage);
