import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import l10n from '../../services/l10n';

const styles = theme => ({
  root: {
    maxWidth: 841,
  },
  padded: {
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 8}px ${theme.spacing.unit *
      5}px ${theme.spacing.unit * 8}px`,
  },
  panelTitle: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  panel: {
    paddingTop: theme.spacing.unit * 3,
    paddingRight: theme.spacing.unit * 3,
    paddingLeft: theme.spacing.unit * 3,
  },
});

class DashboardPanel extends Component {
  static propTypes = {
    classes: PropTypes.object,
    panelName: PropTypes.string,
    children: PropTypes.object,
    pageMode: PropTypes.bool,
  };

  static defaultProps = {
    panelName: 'Panel Name',
    pageMode: false,
  };

  renderByPage = children => {
    const { classes, panelName } = this.props;

    return (
      <section className={classes.root}>
        <Typography className={classes.panelTitle} variant='h6'>
          {l10n(panelName)}
        </Typography>
        <Paper className={classes.padded} elevation={0}>
          {children}
        </Paper>
      </section>
    );
  };

  render = () => {
    const { classes, panelName, children, pageMode } = this.props;

    if (pageMode) return this.renderByPage(children);
    return (
      <Paper elevation={0} className={classes.panel}>
        <section>
          <Typography variant='h6'>{panelName}</Typography>
        </section>
        {children}
      </Paper>
    );
  };
}

export default withStyles(styles)(DashboardPanel);
