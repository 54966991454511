const initState = { merchants: [], query: '' };

const shopReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, loading: false, merchants: action.merchants };
    case 'FETCH_FAILURE':
      return { ...state, loading: false, error: action.error };
    case 'SEARCH_REQUEST':
      return { ...state, loading: true };
    case 'SEARCH_SUCCESS':
      return { ...state, loading: false, query: action.payload };
    case 'PRODUCT_SELECTED':
      return { ...state, product: action.payload };
    default:
      return state;
  }
};

export default shopReducer;
