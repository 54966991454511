import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { getEmployees } from './managerActions';

export const SET_VIRTUAL_CARD = 'SetVirtualCard';
export const SET_VIRTUAL_CARD_STATUS = 'SetVirtualCardStatus';

export const retrieveVirtualCard = user => async dispatch => {
  const res = await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/getVCardDetails`, {
    vCardId: user.issuingCard,
  });
  dispatch({ type: SET_VIRTUAL_CARD, payload: res.data });
};

export const updateVirtualCardStatus = (employees, action) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const db = getFirestore();
  const batch = db.batch();
  const { currentUser } = getState();

  const status = action === 'activate' ? 'active' : 'inactive';
  const issuingCards = employees.map(({ issuingCard }) => issuingCard);
  await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/setVCardStatus`, {
    vCardIds: issuingCards,
    status,
  });

  employees.forEach(({ id }) => {
    const docRef = db.collection('users').doc(id);
    batch.update(docRef, { cardStatus: status });
  });

  batch
    .commit()
    .then(() => {
      dispatch(getEmployees(currentUser.id, { getFirestore, dispatch }));
      NotificationManager.success('Card status change successfully!');
    })
    .catch(err => {
      NotificationManager.error(`Error on updating card status: ${err}`);
    });
};
