import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import l10n from '../../services/l10n';

const drawerWidth = 240;
const styles = theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  toolbar: theme.mixins.toolbar,
  nopads: {
    padding: 0,
  },
  dash: {
    margin: '0 30px',
    '& label': {
      fontWeight: 'bold',
    },
  },
  sectionTitle: {
    fontSize: 15,
  },
});

const theme = createMuiTheme({
  palette: {
    secondary: { main: '#ed5731' },
  },
});

const categories = [
  l10n('Leadership'),
  l10n('Relationship'),
  l10n('Critical Thinking'),
  l10n('Attitude'),
  l10n('Communication'),
  l10n('Execution'),
  l10n('Emotional Intelligence'),
];

const media = [l10n('Audiobooks'), l10n('Books'), l10n('Online Courses'), l10n('Assessments')];

const platforms = [
  l10n('Amazon'),
  l10n('Audible'),
  l10n('The Culture Works'),
  l10n('Udemy'),
  l10n('Walmart'),
];

const Filters = props => {
  const { classes, onCheck, onChange, filters, prices } = props;
  return (
    <Drawer
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <MuiThemeProvider theme={theme}>
        <List>
          <ListItem>
            <ListItemText primary='Price' primaryTypographyProps={{ variant: 'h6' }} />
          </ListItem>
          <ListItem button>
            <FormControl>
              <Input
                id='min'
                name='min'
                value={prices.min}
                onChange={onChange}
                startAdornment={<InputAdornment position='start'>$</InputAdornment>}
              />
            </FormControl>
            <FormControl className={classes.dash}>
              <FormLabel>&#8212;</FormLabel>
            </FormControl>
            <FormControl>
              <Input
                id='max'
                name='max'
                value={prices.max}
                onChange={onChange}
                startAdornment={<InputAdornment position='start'>$</InputAdornment>}
              />
            </FormControl>
          </ListItem>
        </List>
        <List>
          <ListItem>
            <ListItemText
              primary='Categories'
              primaryTypographyProps={{ variant: 'h6', className: classes.sectionTitle }}
            />
          </ListItem>
          {_.map(categories, (val, key) => (
            <ListItem
              button
              key={key}
              onClick={() => onCheck('categories', val, !filters.categories[val])}
            >
              <Checkbox
                className={classes.nopads}
                checked={!!filters.categories[val]}
                value={val}
                disableRipple
              />
              <ListItemText primary={val} />
            </ListItem>
          ))}
        </List>
        <List>
          <ListItem>
            <ListItemText
              primary='Media Type'
              primaryTypographyProps={{ variant: 'h6', className: classes.sectionTitle }}
            />
          </ListItem>
          {_.map(media, (val, key) => (
            <ListItem
              button
              key={key}
              onClick={() => onCheck('mediaTypes', val, !filters.mediaTypes[val])}
            >
              <Checkbox
                className={classes.nopads}
                checked={!!filters.mediaTypes[val]}
                value={val}
                disableRipple
              />
              <ListItemText primary={val} />
            </ListItem>
          ))}
        </List>
        <List>
          <ListItem>
            <ListItemText
              primary='Platform'
              primaryTypographyProps={{ variant: 'h6', className: classes.sectionTitle }}
            />
          </ListItem>
          {_.map(platforms, (val, key) => (
            <ListItem
              button
              key={key}
              onClick={() => onCheck('platforms', val, !filters.platforms[val])}
            >
              <Checkbox
                className={classes.nopads}
                checked={!!filters.platforms[val]}
                value={val}
                disableRipple
              />
              <ListItemText primary={val} />
            </ListItem>
          ))}
        </List>
      </MuiThemeProvider>
    </Drawer>
  );
};

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  filters: PropTypes.shape({
    categories: PropTypes.object,
    mediaTypes: PropTypes.object,
    platforms: PropTypes.object,
  }).isRequired,
  prices: PropTypes.object.isRequired,
  onCheck: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(Filters);
