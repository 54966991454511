const quotes = [
  {
    quote: 'Courage is grace under pressure.',
    source: 'Ernest Hemingway',
  },
  {
    quote:
      'Learn from yesterday, live for today, hope for tomorrow. The important thing is not to stop questioning.',
    source: 'Albert Einstein',
  },
  {
    quote: 'Success is walking from failure to failure with no loss of enthusiasm.',
    source: 'Winston Churchill',
  },
  {
    quote: 'Someone is sitting in the shade today because someone planted a tree a long time ago.',
    source: 'Warren Buffett',
  },
  {
    quote: 'You only live once, but if you do it right, once is enough.',
    source: 'Mae West',
  },
  {
    quote:
      'It is not the strongest of the species that survive, nor the most intelligent, but the one most responsive to change.',
    source: 'Charles Darwin',
  },
  {
    quote: 'Live as if you were to die tomorrow. Learn as if you were to live forever.',
    source: 'Mahatma Gandhi',
  },
  {
    quote: 'Live as if you were to die tomorrow. Learn as if you were to live forever.',
    source: 'Mahatma Gandhi',
  },
  {
    quote: 'The difference between winning and losing is most often not quitting.',
    source: 'Walt Disney',
  },
  {
    quote: 'No one can make you feel inferior without your consent.',
    source: 'Eleanor Roosevelt',
  },
  {
    quote: 'You must expect great things of yourself before you can do them.',
    source: 'Michael Jordan',
  },
  {
    quote: 'I find that the harder I work, the more luck I seem to have.',
    source: 'Thomas Jefferson',
  },
  {
    quote: 'Success is the sum of small efforts, repeated day-in and day-out.',
    source: 'Robert Collier',
  },
  {
    quote:
      'If your actions inspire others to dream more, learn more, do more, and become more, you are a leader.',
    source: 'John Quincy Adams',
  },
  {
    quote:
      "A dream doesn't become reality through magic; it takes sweat, determination, and hard work.",
    source: 'Colin Powell',
  },
  {
    quote: 'Nothing great was ever achieved without enthusiasm.',
    source: 'Ralph Waldo Emerson',
  },
  {
    quote:
      "Many of life's failures are people who did not realize how close they were to success when they gave up.",
    source: 'Thomas A. Edison',
  },
  {
    quote: "What would you do if you weren't afraid?",
    source: 'Sheryl Sandberg',
  },
  {
    quote: 'The best way to predict your future is to create it',
    source: 'Peter Drucker',
  },
  {
    quote: 'Knowledge is power is time is money.',
    source: 'Robert Thier',
  },
  {
    quote: "Don't blow off another's candle for it won't make yours shine brighter.",
    source: 'Jaachynma N.E. Agu',
  },
  {
    quote: "There's no luck in business. There's only drive, determination, and more drive.",
    source: 'Sophie Kinsella',
  },
  {
    quote: 'Truth builds trust.',
    source: 'Marilyn Suttle',
  },
  {
    quote: 'Great results, can be achieved with small forces.',
    source: 'Sun Tzu',
  },
  {
    quote: 'Strive not to be a success, but rather to be of value.',
    source: 'Albert Einstein',
  },
  {
    quote: 'Early to bed, early to rise, work like hell, and advertise',
    source: 'Ted Turner',
  },
  {
    quote:
      'A business has to be involving, it has to be fun, and it has to exercise your creative instincts.',
    source: 'Richard Branson',
  },
  {
    quote: 'You become what you think about',
    source: 'Napoleon Hill',
  },
  {
    quote: 'Although individuals need not be well-rounded, teams should be.',
    source: 'Tom Rath',
  },
  {
    quote: 'Whenever you see a successful business, someone once made a courageous decision.',
    source: 'Peter Drucker',
  },
  {
    quote: 'Being trustworthy requires: Doing the right thing. And doing things right.',
    source: 'Don Peppers',
  },
  {
    quote: 'The price of inaction is far greater than the cost of a mistake',
    source: 'Meg Whitman',
  },
  {
    quote: 'Get in touch with your passion and put it to work at work.',
    source: 'Marilyn Suttle',
  },
  {
    quote: 'Be a worthy worker and work will come.',
    source: 'Amit Kalantri',
  },
  {
    quote: 'People have within their own hands the tools to fashion their own destiny.',
    source: 'Murray D. Lincoln',
  },
  {
    quote: 'Listen to your customers, not your competitors.',
    source: 'Joel Spolsky',
  },
  {
    quote: 'If you can dream it, you can do it.',
    source: 'Walt Disney',
  },
  {
    quote:
      'Management is telling people what to do; leadership is inspiring them to want to do it.',
    source: 'Allen Filson',
  },
  {
    quote:
      'You are never old nor too late to do what you love , because there is no age limit in being happy.',
    source: 'De philosopher DJ Kyos',
  },
  {
    quote: 'Think about your future in a positive way and you will move towards your goals.',
    source: 'Aurora Berill',
  },
  {
    quote: 'Stay open to the possibility that a relationship may evolve over time. Have Patience.',
    source: 'Michelle Lederman',
  },
  {
    quote:
      'Loyalty and honesty are the most important yet often ignored qualities in business. Long term success depends on these qualities.',
    source: 'Arshad Wahedna',
  },
  {
    quote: 'Invest in who you are becoming.',
    source: 'Dr. Sonia Kennedy',
  },
  {
    quote: 'If people are not told they are appreciated, they will assume the opposite',
    source: 'Aubrey C Daniels',
  },
  {
    quote: 'Money flows where attention goes.',
    source: 'Steven Aitchison',
  },
  {
    quote:
      "Great things in business are never done by one person. They're done by a team of people.",
    source: 'Steve Jobs',
  },
  {
    quote: 'Delegation of kindness is an epidemic of success.',
    source: 'Goitsemang Mvula',
  },
];

export default seed => {
  const idx = Math.floor(seed * (quotes.length - 1));
  return quotes[idx];
};
