/** Form fields validation */

const validateFields = (fields, required) => {
  let fieldVal;
  let errEmail;
  let errField;
  let errPass;
  const validate = {
    error: false,
  };

  Object.keys(fields).forEach(field => {
    if (field in required) {
      fieldVal = fields[field];
      switch (field) {
        case 'email':
          errEmail = fieldVal.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
          validate.email = !errEmail;
          if (!validate.error) validate.error = !errEmail;
          break;
        case 'password':
          errPass = fieldVal.length <= 6;
          validate.password = errPass;
          if (!validate.error) validate.error = errPass;
          break;
        default:
          errField = fieldVal.length === 0;
          validate[field] = errField;
          if (!validate.error) validate.error = errField;
          break;
      }
    }
  });

  return validate;
};

module.exports = {
  validateFields,
};
