import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import Header from './LandingPage/Header';
import GetStartedAction from './LandingPage/GetStartedAction';
import Footer from './LandingPage/Footer';

import PricingImage from '../assets/pricing.png';
import ProductsImage from '../assets/products.png';
import CardImage from '../assets/Card.png';
import IdeaImage from '../assets/idea.png';
import TransferImage from '../assets/transfer.png';
import FileImage from '../assets/file.png';
import SecurityImage from '../assets/Security.png';

const styles = theme => ({
  hero: {
    backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${PricingImage})`,
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'cover',
      padding: 26,
    },
    [theme.breakpoints.up('sm')]: {
      backgroundSize: 'cover',
      height: '90vh',
    },
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
    marginTop: 80,
  },
  heroText: {
    font: 'Roboto',
    padding: 4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 35,
    },
    fontWeight: 500,
  },
  heroPrice: {
    font: 'Roboto',
    fontSize: 25,
    fontWeight: 500,
    padding: 4,
  },
  heroButton: {
    padding: '20px 60px',
    marginBottom: 20,
    marginTop: 20,
  },
  heroTag: {
    font: 'Roboto',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  heading: {
    font: 'Roboto Black',
    fontWeight: 800,
    fontSize: 25,
    marginBottom: 44,
  },
  section: {
    height: '100%',
  },
  sectionB: {
    padding: 80,
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
  },
  sectionBackground: {
    backgroundColor: '#EE6352',
  },
  reset: {
    padding: 0,
    margin: 0,
    width: '100%',
  },
});

const Pricing = props => {
  const { classes, width } = props;
  return (
    <Grid container direction='column'>
      <Grid item>
        <Header />
      </Grid>
      <Grid item>
        <Grid
          className={classes.hero}
          container
          alignItems='center'
          justify='center'
          direction='column'
        >
          <Grid item>
            <Typography className={classes.heroText} variant='h4' color='secondary'>
              Employee Development Without The Waste
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.heroPrice} variant='h4' color='secondary'>
              $25/Month
            </Typography>
          </Grid>
          <Grid item>
            <Button
              className={classes.heroButton}
              variant='contained'
              color='primary'
              to='register'
              component={Link}
            >
              START TODAY
            </Button>
          </Grid>
          <Grid item>
            <Typography className={classes.heroTag} variant='h4' color='secondary'>
              $0 for us, $25 for each of your employees
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classnames(classes.section, classes.sectionB)} item>
        <Grid container direction={width === 'xs' ? 'column' : 'row'}>
          <Grid xs={12} md={4} item>
            <Product
              text='Virtual cards created instantly for every employee - no applications'
              image={CardImage}
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <Product
              text='A simplified library of only the best books, audiobooks, and online classes'
              image={ProductsImage}
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <Product
              text='Employees learn in the car, while exercising, on the couch, or at the computer.'
              image={IdeaImage}
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <Product
              text='No more wasted subscription dollars - all budgets rollover, can be reallocated, and can be redeemed'
              image={TransferImage}
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <Product
              text='Track everything easily - no more reimbursement process'
              image={FileImage}
            />
          </Grid>
          <Grid xs={12} md={4} item>
            <Product
              text='Controlled purchases ensure your money is spent the right way.'
              image={SecurityImage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classnames(classes.section, classes.sectionBackground)} item>
        <GetStartedAction />
      </Grid>
      <Grid className={classnames(classes.section)} item>
        <Footer />
      </Grid>
    </Grid>
  );
};

Pricing.propTypes = {
  classes: PropTypes.shape({
    section: PropTypes.string,
    sectionBackground: PropTypes.string,
    hero: PropTypes.string,
    heroText: PropTypes.string,
    heroPrice: PropTypes.string,
    heroButton: PropTypes.string,
    heroTag: PropTypes.string,
    sectionB: PropTypes.string,
    heading: PropTypes.string,
  }),
  width: PropTypes.string,
};

const BaseProduct = ({ image, classes, text }) => (
  <Grid
    className={classes.product}
    container
    direction='column'
    alignItems='center'
    justify='center'
  >
    <Grid className={classes.productImageContainer} item>
      <img className={classes.productImage} src={image} alt='product' />
    </Grid>
    <Grid item>
      <Typography className={classes.productText} variant='h6'>
        {text}
      </Typography>
    </Grid>
  </Grid>
);

const productStyles = () => ({
  product: {
    padding: 20,
    marginBottom: 50,
  },
  productImage: {
    marginBottom: 50,
  },
  productImageContainer: {
    height: 120,
  },
  productText: {
    font: 'Roboto',
    fontSize: 15,
    width: 265,
    textAlign: 'center',
  },
});

BaseProduct.propTypes = {
  image: PropTypes.string,
  classes: PropTypes.shape({
    product: PropTypes.string,
    productImage: PropTypes.string,
    productImageContainer: PropTypes.string,
    productText: PropTypes.string,
  }),
  text: PropTypes.string,
};

const Product = withStyles(productStyles)(BaseProduct);

export default compose(
  withStyles(styles),
  withWidth(),
)(Pricing);
