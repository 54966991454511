import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { updateVirtualCardStatus } from '../../store/actions/virtualCardActions';

const FreezeEmployeeRoute = ({ history, updateCard, match: { params } }) => {
  const { cardId, userId } = params;
  const employees = [{ issuingCard: cardId, id: userId }];
  updateCard(employees, 'freeze');
  history.push('/dashboard');
  return null;
};

FreezeEmployeeRoute.propTypes = {
  updateCard: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  updateCard: (employees, activate) => dispatch(updateVirtualCardStatus(employees, activate)),
});

export default connect(
  null,
  mapDispatchToProps,
)(FreezeEmployeeRoute);
