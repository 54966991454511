import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import moment from 'moment';
import luhn from 'luhn';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';

import DashboardPanel from './DashboardPanel';
import { addCard } from '../../store/actions/managerActions';

const styles = () => ({
  secondaryTitle: {
    fontSize: 12,
  },
});

const validator = values => {
  const errors = {};
  if (!values.name) errors.name = 'Name is required';
  if (!values.number) errors.number = 'Credit card number is required';
  if (!luhn.validate(values.number)) errors.number = 'Credit card number is not valid';
  if (!/\d{2}\/\d{4}/g.test(values.expiryDate))
    errors.expiryDate = 'Card expiration date is invalid. Format: MM/YYYY';
  if (!moment(values.expiryDate, 'MM/YYYY').isAfter(new Date()))
    errors.expiryDate = 'Card is expired';
  if (!values.expiryDate) errors.expiryDate = 'Card expiration date is required';
  if (!values.cvc) errors.cvc = 'CVC is required';
  if (!/\d+/g.test(values.cvc)) errors.cvc = 'CVC is invalid';
  if (!values.address) errors.address = 'Address is required';
  if (!values.country) errors.country = 'Country is required';
  if (!values.state) errors.state = 'State is required';
  if (!values.city) errors.city = 'City is required';
  if (!values.postalCode) errors.postalCode = 'Postal Code is required';
  return errors;
};

class AddCreditCard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    cardAdd: PropTypes.func,
  };

  state = {
    source: {
      number: '',
      name: '',
      expiryDate: '',
      cvc: '',
      address: '',
      country: '',
      city: '',
      state: '',
      postalCode: '',
    },
  };

  handleFormChange = event => {
    const { name, value } = event.target;
    this.setState(prev => ({ source: { ...prev.source, [name]: value } }));
  };

  handleAddCreditCard = (values, { setSubmitting }) => {
    const { cardAdd } = this.props;
    cardAdd(values, setSubmitting);
  };

  render = () => {
    const { classes, history } = this.props;
    const { source } = this.state;

    return (
      <DashboardPanel panelName='CREDIT CARD INFORMATION' pageMode>
        <Formik initialValues={source} validate={validator} onSubmit={this.handleAddCreditCard}>
          {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <Grid container direction='column' spacing={32}>
                <Grid item container direction='column' alignItems='center'>
                  <Grid item>
                    <Typography variant='h6'>Add a credit card to your Brinq account</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.secondaryTitle} variant='h6'>
                      Every dollar spent goes right to your employee's Brinq Pre-paid Virtual Cards
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <TextField
                    value={values.name}
                    name='name'
                    label='Name on the Card'
                    error={!!errors.name}
                    helperText={errors.name}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    value={values.number}
                    name='number'
                    label='Card Number'
                    error={!!errors.number}
                    helperText={errors.number}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid container item direction='row' spacing={32}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      value={values.expiryDate}
                      name='expiryDate'
                      label='Expiration Date'
                      error={!!errors.expiryDate}
                      helperText={errors.expiryDate}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      value={values.cvc}
                      name='cvc'
                      label='CVC'
                      error={!!errors.cvc}
                      helperText={errors.cvc}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  <TextField
                    value={values.address}
                    name='address'
                    label='Address'
                    error={!!errors.address}
                    helperText={errors.address}
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Grid>
                <Grid container item direction='row' spacing={32}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      value={values.city}
                      name='city'
                      label='City'
                      error={!!errors.city}
                      helperText={errors.city}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      value={values.state}
                      name='state'
                      label='State'
                      error={!!errors.state}
                      helperText={errors.state}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container item direction='row' spacing={32}>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      value={values.postalCode}
                      name='postalCode'
                      label='Postal Code'
                      error={!!errors.postalCode}
                      helperText={errors.postalCode}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      value={values.country}
                      name='country'
                      label='Country'
                      error={!!errors.country}
                      helperText={errors.country}
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>

                <Grid container item direction='row' justify='flex-end'>
                  <Grid item>
                    <Button onClick={() => history.push('/dashboard/creditCard')}>CANCEL</Button>
                    <Button
                      type='submit'
                      disabled={isSubmitting}
                      color='primary'
                      variant='contained'
                    >
                      ADD CREDIT CARD
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </DashboardPanel>
    );
  };
}

const mapDispatchToProps = dispatch => ({
  cardAdd: (source, setSubmitting) => dispatch(addCard(source, setSubmitting)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(AddCreditCard);
