import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';

const DynamicAvatar = ({ currentUser, size, className }) => {
  const style = {};
  if (size) {
    style.width = size;
    style.height = size;
  }
  return (
    <Fragment>
      {!currentUser.id && (
        <Avatar className={className} style={style}>
          ...
        </Avatar>
      )}
      {currentUser && currentUser.avatar && (
        <Avatar className={className} style={style} src={currentUser.avatar} />
      )}
      {currentUser.id && !currentUser.avatar && (
        <Avatar className={className} style={style}>{`${currentUser.firstName.charAt(
          0,
        )}${currentUser.lastName.charAt(0)}`}</Avatar>
      )}
    </Fragment>
  );
};

DynamicAvatar.propTypes = {
  currentUser: PropTypes.shape({
    avatar: PropTypes.string,
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  size: PropTypes.number,
  className: PropTypes.string,
};

export default DynamicAvatar;
