const initState = JSON.parse(localStorage.getItem('user')) || {};

const userReducer = (state = initState, action) => {
  const { type } = action;
  const _state = { ...state };
  switch (type) {
    case 'SET_USER':
      return action.payload;
    case 'UPDATE_USER':
      return { ..._state, ...action.payload };
    default:
      return state;
  }
};

export default userReducer;
