export const SELECTED_PURCHASE = 'SELECTED_PURCHASE';
export const PURCHASE_HISTORY_REQUEST = 'PURCHASE_HISTORY_REQUEST';
export const PURCHASE_HISTORY_SUCCESS = 'PURCHASE_HISTORY_SUCCESS';
export const PURCHASE_HISTORY_FAILURE = 'PURCHASE_HISTORY_FAILURE';
export const PURCHASE_HISTORY_MERCHANTS = 'PURCHASE_HISTORY_MERCHANTS';
export const PURCHASE_HISTORY_UPLOAD = 'PURCHASE_HISTORY_UPLOAD';
export const PURCHASE_HISTORY_ROLE = 'PURCHASE_HISTORY_ROLE';

export const loadPurchaseHistory = () => (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  const { currentUser } = getState();

  dispatch({ type: PURCHASE_HISTORY_REQUEST });
  dispatch({ type: PURCHASE_HISTORY_ROLE, payload: currentUser.isManager });

  if (currentUser.isManager) {
    firestore
      .collection('transactions')
      .where('managerId', '==', currentUser.id)
      .get()
      .then(snap => {
        const purchases = [];
        snap.forEach(doc => {
          purchases.push(doc.data());
        });
        dispatch({ type: PURCHASE_HISTORY_SUCCESS, payload: purchases });
      })
      .catch(error => {
        dispatch({ type: PURCHASE_HISTORY_FAILURE, payload: error });
      });
  } else {
    firestore
      .collection('transactions')
      .where('cardholder', '==', currentUser.stripeId)
      .get()
      .then(snapshot => {
        const purchases = [];
        snapshot.forEach(doc => {
          purchases.push(doc.data());
        });

        dispatch({ type: PURCHASE_HISTORY_SUCCESS, payload: purchases });
      })
      .catch(error => {
        dispatch({ type: PURCHASE_HISTORY_FAILURE, payload: error });
      });
  }
};

export const selectPurchase = item => dispatch => {
  dispatch({ type: SELECTED_PURCHASE, payload: item });
};

export const loadMerchandiseTitle = () => (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  dispatch({ type: PURCHASE_HISTORY_REQUEST });

  const merchants = [];
  firestore
    .collection('merchs')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        const { name } = doc.data();
        merchants.push({ label: name });
      });

      dispatch({ type: PURCHASE_HISTORY_MERCHANTS, payload: merchants });
    })
    .catch(error => dispatch({ type: PURCHASE_HISTORY_FAILURE, payload: error }));
};

export const uploadPurchaseReceipt = ({ files, title }) => (
  dispatch,
  getState,
  { getFirestore, getFirebase },
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const storage = firebase.storage();
  const { purchase } = getState();

  const types = ['image/png', 'image/jpeg', 'image/gif'];

  if (types.every(type => files[0].type !== type)) {
    dispatch({
      type: PURCHASE_HISTORY_FAILURE,
      payload: { status: 'error', message: `'${files[0].type}' is not a supported format.` },
    });
  }

  if (files[0].size > 150000) {
    dispatch({
      type: PURCHASE_HISTORY_FAILURE,
      payload: {
        status: 'error',
        message: `'${files[0].name}' is too large, please pick a smaller file.`,
      },
    });
  }

  dispatch({ type: PURCHASE_HISTORY_REQUEST });

  const receiptRef = storage.ref(`receipts/${files[0].name}`);
  receiptRef.put(files[0]).then(snapshot => {
    const { downloadURL } = snapshot;

    firestore
      .collection('transactions')
      .doc(purchase.selected.id)
      .update({
        receipt: downloadURL,
        title,
      })
      .then(res => {
        dispatch({ type: PURCHASE_HISTORY_UPLOAD, payload: res });
        dispatch(loadPurchaseHistory())();
      })
      .catch(err => dispatch({ type: PURCHASE_HISTORY_FAILURE, payload: err }));
  });
};
