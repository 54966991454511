import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import { Route, Switch } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DashboardPanel from './DashboardPanel';
import VerifyEmailPanel from './VerifyEmailPanel';
import Employee from './Employee';
import PurchaseHistory from './PurchaseHistory';
import CreditCardInformation from './CreditCardInformation';
import AddCreditCard from './AddCreditCard';
import Walkthrough from './Walkthrough';
import FreezeEmployeeRoute from './FreezeEmployeeRoute';
import PrivateRoute from '../PrivateRoute';

import { finishWalkthrough } from '../../store/actions/modalActions';

import l10n from '../../services/l10n';
import menu from '../../json/dashboardMenu.json';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    backgroundColor: '#f0f2f5',
    minHeight: '100vh',
  },
  toolbar: theme.mixins.toolbar,
});

class Dashboard extends Component {
  static propTypes = {
    classes: PropTypes.object,
    currentUser: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    _finishWalkthrough: PropTypes.func,
    walkthroughOpen: PropTypes.bool,
  };

  state = { isEmailVerified: true, isDrawerOpen: false, selected: '' };

  componentDidUpdate = prevProps => {
    const { currentUser, location } = this.props;
    if (
      currentUser &&
      prevProps.currentUser &&
      currentUser.authUser !== prevProps.currentUser.authUser
    ) {
      this.setState({ isEmailVerified: currentUser.authUser.emailVerified });
    }

    if (
      prevProps.location.pathname !== location.pathname &&
      location.pathname.split('/').length === 2
    ) {
      this.setState({ selected: '' });
    }
  };

  handleSideMenuClick = (event, item) => {
    const { history } = this.props;

    if (item === 'menu') {
      this.setState(prev => ({ isDrawerOpen: !prev.isDrawerOpen }));
    } else {
      history.push(`/dashboard/${item}`);
      this.setState({ selected: item });
    }
  };

  handleClickMore = (e, url) => {
    const { history, location } = this.props;
    history.push(`${location.pathname}/${url}`);
  };

  showMenuItem = (menuItem, role) =>
    !menuItem.hidden && (!menuItem.roles || menuItem.roles.includes(role));

  walkthroughFinished = () => {
    const { _finishWalkthrough, currentUser } = this.props;
    _finishWalkthrough(currentUser);
  };

  render = () => {
    const { classes, currentUser, walkthroughOpen } = this.props;
    const { isEmailVerified, isDrawerOpen, selected } = this.state;
    const role = currentUser.isManager ? 'manager' : 'employee';

    return (
      <div className={classes.root}>
        <Walkthrough
          isManager={currentUser.isManager}
          onFinish={this.walkthroughFinished}
          open={walkthroughOpen}
        />
        <CssBaseline />
        <Drawer
          className={classNames(classes.drawer, {
            [classes.drawerClose]: isDrawerOpen,
            [classes.drawerOpen]: !isDrawerOpen,
          })}
          variant='permanent'
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerClose]: isDrawerOpen,
              [classes.drawerOpen]: !isDrawerOpen,
            }),
          }}
        >
          <div className={classes.toolbar} />
          <List>
            <Hidden mdUp>
              <ListItem button onClick={e => this.handleSideMenuClick(e, 'menu')}>
                <ListItemIcon>
                  <MenuIcon />
                </ListItemIcon>
                <ListItemText primary='Minimize' />
              </ListItem>
            </Hidden>
          </List>
          {menu.map(
            menuItem =>
              this.showMenuItem(menuItem, role) && (
                <List key={menuItem.text}>
                  <ListItem>
                    <ListItemText
                      primary={menuItem.text}
                      primaryTypographyProps={menuItem.primaryTypographyProps}
                      hidden={isDrawerOpen}
                    />
                  </ListItem>
                  {menuItem.children.map(
                    childItem =>
                      this.showMenuItem(childItem, role) && (
                        <ListItem
                          id={`SideNav-${childItem.value}`}
                          key={childItem.value}
                          button
                          selected={selected === childItem.value}
                          onClick={e => this.handleSideMenuClick(e, childItem.value)}
                        >
                          <ListItemIcon>
                            <Icon>{childItem.icon}</Icon>
                          </ListItemIcon>
                          <ListItemText primary={childItem.text} />
                        </ListItem>
                      ),
                  )}
                </List>
              ),
          )}
        </Drawer>

        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}
          <Switch>
            <Route
              exact
              path='/dashboard'
              render={() => (
                <Fragment>
                  {!isEmailVerified && <VerifyEmailPanel />}
                  <section name='views'>
                    <Grid container spacing={32}>
                      {currentUser && currentUser.isManager && (
                        <Grid item sm={12}>
                          <DashboardPanel panelName={l10n('EMPLOYEES')}>
                            <Employee tableOnly limit={5} />
                          </DashboardPanel>
                        </Grid>
                      )}
                      <Grid item sm={12}>
                        <DashboardPanel panelName={l10n('PURCHASE HISTORY')}>
                          <PurchaseHistory tableOnly limit={5} />
                        </DashboardPanel>
                      </Grid>
                      {/* {(currentUser && currentUser.isManager) && (
                        <Grid item sm={12}>
                          <DashboardPanel panelName={l10n('REQUEST CLASS')}>
                            <RequestClass tableOnly />
                          </DashboardPanel>
                        </Grid>
                      )} */}
                    </Grid>
                  </section>
                </Fragment>
              )}
            />
            <PrivateRoute
              allowedRoles={['manager']}
              path='/dashboard/employees'
              component={Employee}
            />
            <PrivateRoute
              allowedRoles={['employee', 'manager']}
              path='/dashboard/purchasehistory'
              component={PurchaseHistory}
            />
            {/* <PrivateRoute
              allowedRoles={['manager']}
              path="/dashboard/requestclass"
              component={RequestClass}
            /> */}
            <PrivateRoute
              allowedRoles={['manager']}
              path='/dashboard/creditcard'
              component={CreditCardInformation}
            />
            <PrivateRoute
              allowedRoles={['manager']}
              path='/dashboard/addcreditcard'
              component={AddCreditCard}
            />
            <PrivateRoute
              allowedRoles={['manager']}
              path='/dashboard/freezeEmployee/:cardId/:userId'
              component={FreezeEmployeeRoute}
            />
          </Switch>
        </main>
      </div>
    );
  };
}

const mapStateToProps = ({ currentUser, modals }) => ({
  currentUser,
  walkthroughOpen: modals.walkthroughOpen,
});
const mapDispatchToProps = dispatch => ({
  _finishWalkthrough: user => dispatch(finishWalkthrough(user)),
});

export default compose(
  withStyles(styles),
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Dashboard);
