import React, { Component } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PropTypes from 'prop-types';

class SortableTableHead extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        numeric: PropTypes.bool,
        id: PropTypes.string,
      }),
    ),
    order: PropTypes.string,
    orderBy: PropTypes.string,
    numSelected: PropTypes.number,
    rowCount: PropTypes.number,
    enableSelect: PropTypes.bool,
    onSelectAll: PropTypes.func,
    onRequestSort: PropTypes.func,
  };

  static defaultProps = {
    onRequestSort: () => {},
    onSelectAll: () => {},
  };

  handleClickSort = property => event => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render = () => {
    const {
      headers,
      order,
      orderBy,
      onSelectAll,
      rowCount,
      numSelected,
      enableSelect,
    } = this.props;
    return (
      <TableHead>
        <TableRow>
          {enableSelect && (
            <TableCell padding='checkbox'>
              <Checkbox
                color='primary'
                checked={numSelected === rowCount}
                indeterminate={numSelected > 0 && numSelected < rowCount}
                onChange={onSelectAll}
              />
            </TableCell>
          )}
          {headers.map(row => (
            <TableCell key={row.id} sortDirection={orderBy === row.id ? order : false}>
              {row.sort ? (
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={this.handleClickSort(row.id)}
                >
                  {row.label}
                </TableSortLabel>
              ) : (
                row.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };
}

export default SortableTableHead;
