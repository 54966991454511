import { VCARD_MODAL, LAUNCH_WALKTHROUGH, CLOSE_WALKTHROUGH } from '../actions/modalActions';

const initState = {
  vCardModal: false,
  walkthroughOpen: false,
};

const modalReducer = (state = initState, action) => {
  const _state = { ...state };
  switch (action.type) {
    case VCARD_MODAL:
      _state.vCardModal = action.payload.open;
      return _state;
    case LAUNCH_WALKTHROUGH:
      _state.walkthroughOpen = true;
      return _state;
    case CLOSE_WALKTHROUGH:
      _state.walkthroughOpen = false;
      return _state;
    default:
      return state;
  }
};

export default modalReducer;
