import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import PropTypes from 'prop-types';
import Header from '../LandingPage/Header';
import Footer from '../LandingPage/Footer';
import SetupPage from './SetupPage';
import ShopPage from './ShopPage';
import UploadReceiptPage from './UploadReceiptPage';

const styles = theme => ({
  root: {
    paddingLeft: theme.spacing.unit * 7,
    [theme.breakpoints.down('md')]: {
      padding: '0',
    },
  },
  list: {
    paddingLeft: 100,
    paddingTop: '10vh',
    [theme.breakpoints.up('lg')]: {
      position: 'fixed',
    },
  },
  listItem: {
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: 800,
    color: '#565252',
  },
  listSub: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  listSubitem: {
    fontFamily: 'Roboto',
    fontSize: 15,
    fontWeight: 800,
    color: '#565252',
  },
  toolbar: theme.mixins.toolbar,
  main: {
    flexGrow: 3,
    padding: theme.spacing.unit * 3,
  },
  content: {
    padding: `${theme.spacing.unit * 15}px ${theme.spacing.unit * 3}px ${theme.spacing.unit *
      3}px ${theme.spacing.unit * 3}px`,
    [theme.breakpoints.down('md')]: {
      padding: '0 16px',
    },
  },
  paper: {
    padding: theme.spacing.unit * 5,
    marginTop: theme.spacing.unit * 5,
  },
  drawer: {
    height: '100v',
  },
  header: {
    fontSize: 35,
    fontFamily: 'Roboto',
    fontWeight: 800,
    paddingBottom: theme.spacing.unit * 5,
  },
});

class HowToUsePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
  };

  handleListItemClick = (event, path) => {
    const { history } = this.props;
    if (path === 'employee' || path === 'manager') {
      history.push(`/howitworks/setup`);
    } else {
      history.push(`/howitworks/${path}`);
    }
  };

  render = () => {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction='column'>
          <Grid item>
            <Header />
          </Grid>
          <Grid item>
            <Grid container direction='row'>
              <Grid item sm={12} md={2}>
                <div className={classes.toolbar} />
                <List className={classes.list}>
                  <ListItem
                    className={classes.listItemSetup}
                    button
                    onClick={e => this.handleListItemClick(e, 'setup')}
                  >
                    <ListItemText
                      primaryTypographyProps={{ className: classes.listItem }}
                      primary={'setup'.toUpperCase()}
                    />
                  </ListItem>
                  <ListItem
                    className={classes.listSub}
                    button
                    onClick={e => this.handleListItemClick(e, 'manager')}
                    component='a'
                    href='#manager'
                  >
                    <ListItemText
                      primaryTypographyProps={{ className: classes.listSubitem }}
                      primary={'Manager'}
                    />
                  </ListItem>
                  <ListItem
                    className={classes.listSub}
                    button
                    onClick={e => this.handleListItemClick(e, 'employee')}
                    component='a'
                    href='#employee'
                  >
                    <ListItemText
                      primaryTypographyProps={{ className: classes.listSubitem }}
                      primary={'Employee'}
                    />
                  </ListItem>
                  <ListItem button onClick={e => this.handleListItemClick(e, 'shop')}>
                    <ListItemText
                      primaryTypographyProps={{ className: classes.listItem }}
                      primary={'shop'.toUpperCase()}
                    />
                  </ListItem>
                  <ListItem button onClick={e => this.handleListItemClick(e, 'upload')}>
                    <ListItemText
                      primaryTypographyProps={{ className: classes.listItem }}
                      primary={'upload receipt'.toUpperCase()}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item sm={12} md={9}>
                <main className={classes.content}>
                  <div className={classes.toolbar} />
                  <Typography className={classes.header} variant='h4'>
                    How to Use Brinq
                  </Typography>
                  <Typography variant='body1'>
                    Brinq is the fastest way to get a virtual prepaid card into the hands of your
                    employees. They can start buying development materials in minutes, and they'll
                    never see your credit card info. No applications, no waiting, no fees!
                  </Typography>

                  <Paper className={classes.paper} elevation={1} square>
                    <Switch>
                      <Route exact path='/howitworks/' component={SetupPage} />
                      <Route path='/howitworks/setup' component={SetupPage} />
                      <Route path='/howitworks/shop' component={ShopPage} />
                      <Route path='/howitworks/upload' component={UploadReceiptPage} />
                    </Switch>
                  </Paper>
                  <div className={classes.toolbar} />
                </main>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Footer />
        </Grid>
      </div>
    );
  };
}

export default withStyles(styles)(HowToUsePage);
