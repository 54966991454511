import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveUser, deleteUser } from '../store/actions/userActions';
import { getEmployee, setEditUser } from '../store/actions/managerActions';
import l10n from '../services/l10n';
import DynamicAvatar from './components/DynamicAvatar';

const styles = () => ({
  editUserPage: {
    backgroundColor: '#F0F2F5',
    minHeight: '100vh',
  },
  contentContainer: {
    maxWidth: 757,
    paddingTop: 33,
  },
  formContainer: {
    backgroundColor: '#fff',
    padding: '16px 32px',
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 33,
  },
  avatar: {
    marginRight: 40,
    backgroundColor: '#006EFF',
  },
  formContent: {
    borderBottom: '1px solid #E1DADA',
    paddingBottom: 16,
    marginBottom: 20,
  },
  form: {
    paddingRight: 60,
  },
  buttonContainer: {
    flex: 0,
    height: 36,
  },
});

class EditUserPage extends Component {
  get showBudget() {
    const { match, currentUser } = this.props;
    return match.employeeId && currentUser.isManager;
  }

  handleChange = (field, val) => {
    const { editUser, _setEditUser } = this.props;
    const user = { ...editUser };
    user[field] = val;
    _setEditUser(user);
  };

  handleDelete = user => {
    const { _deleteUser } = this.props;
    _deleteUser(user);
  };

  componentDidMount = () => {
    const {
      match: {
        params: { employeeId },
      },
      currentUser,
      _getEmployee,
      _setEditUser,
    } = this.props;
    if (employeeId && currentUser.isManager) {
      _getEmployee(employeeId);
    } else {
      _setEditUser(currentUser);
    }
  };

  commitChanges = () => {
    const { _saveUser, editUser } = this.props;
    _saveUser(editUser);
  };

  render() {
    const { classes, currentUser, editUser } = this.props;
    return (
      <Grid container className={classes.editUserPage} justify='center'>
        <Grid item className={classes.contentContainer}>
          <Typography className={classes.title} variant='h3'>
            {l10n('EDIT ACCOUNT INFORMATION')}
          </Typography>
          <Grid item container className={classes.formContainer}>
            <Grid item container className={classes.formContent} wrap='nowrap' justify='flex-start'>
              <DynamicAvatar className={classes.avatar} currentUser={currentUser} size={125} />
              <form className={classes.form} noValidate autoComplete='off'>
                <TextField
                  label='First Name'
                  className={classes.textField}
                  value={editUser.firstName || ''}
                  onChange={e => this.handleChange('firstName', e.target.value)}
                  margin='normal'
                  fullWidth
                />
                <TextField
                  label='Last Name'
                  className={classes.textField}
                  value={editUser.lastName || ''}
                  onChange={e => this.handleChange('lastName', e.target.value)}
                  margin='normal'
                  fullWidth
                />
                <FormControl className={classes.formControl} fullWidth margin='normal'>
                  <InputLabel htmlFor='role'>{l10n('Job Role')}</InputLabel>
                  <Select
                    value={editUser.role || ''}
                    onChange={e => this.handleChange('role', e.target.value)}
                    inputProps={{
                      name: 'role',
                      id: 'role',
                    }}
                  >
                    <MenuItem value=''>
                      <em>{l10n('--- None ---')}</em>
                    </MenuItem>
                    <MenuItem value='Animation'>{l10n('Animation')}</MenuItem>
                    <MenuItem value='Engineering'>{l10n('Engineering')}</MenuItem>
                    <MenuItem value='Construction'>{l10n('Construction')}</MenuItem>
                  </Select>
                  <FormHelperText>{l10n('Select Role (Optional)')}</FormHelperText>
                </FormControl>
                {this.showBudget && (
                  <TextField
                    label={l10n('Budget')}
                    className={classes.textField}
                    value={editUser.budget || ''}
                    onChange={e => this.handleChange('budget', e.target.value)}
                    type='number'
                    placeholder={l10n('Input monthly budget')}
                    helperText={l10n("This change will take effect in next month's budget update")}
                    fullWidth
                    margin='normal'
                  />
                )}
              </form>
              <Grid item container wrap='nowrap' className={classes.buttonContainer}>
                <Button
                  size='small'
                  variant='contained'
                  color='primary'
                  onClick={this.commitChanges}
                >
                  {l10n('Save')}
                </Button>
              </Grid>
            </Grid>
            <Grid container justify='flex-end'>
              <Button onClick={e => this.handleDelete(editUser, e)}>
                {l10n('Delete Account')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

EditUserPage.propTypes = {
  classes: PropTypes.object,
  _saveUser: PropTypes.func,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
    role: PropTypes.string,
    budget: PropTypes.string,
  }),
  editUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
    role: PropTypes.string,
    budget: PropTypes.string,
  }),
  match: PropTypes.object,
  _deleteUser: PropTypes.func,
  _getEmployee: PropTypes.func,
  _setEditUser: PropTypes.func,
};

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  editUser: state.manager.editUser,
});

const mapDispatchToProps = dispatch => ({
  _saveUser: user => dispatch(saveUser(user)),
  _getEmployee: id => dispatch(getEmployee(id)),
  _setEditUser: user => dispatch(setEditUser(user)),
  _deleteUser: user => dispatch(deleteUser(user)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(EditUserPage),
);
