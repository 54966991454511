import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import l10n from '../../services/l10n';
import { doResendEmailVerificationLink } from '../../services/Auth';

import Logo from '../../assets/header_logo.png';
import Envelope from '../../assets/envelope@2x.png';
import FooterQuote from '../components/FooterQuote';
import LineDivider from '../components/LineDivider';

const styles = () => ({
  content: {
    flex: 1,
  },
  getStarted: {
    marginBottom: 18,
    marginTop: 40,
  },
  option: {
    padding: '24px 16px',
    cursor: 'pointer',
    minHeight: 98,
  },
  optionText: {
    paddingLeft: 44,
  },
  optionTitle: {
    fontSize: 20,
    fontWeight: 'medium',
  },
  subTitle: {
    fontSize: 15,
    fontFamily: 'Open Sans',
    color: '#707070',
  },
  getStartedTitle: {
    fontSize: 25,
    fontWeight: 500,
    margin: '30px 0',
  },
  logo: {
    marginTop: 20,
  },
  verifyMessage: {
    marginBottom: 30,
  },
});

const seed = Math.random();

const VerifyEmail = ({ classes }) => (
  <DocumentTitle title={l10n('Sign Up')}>
    <Grid className={classes.content} container direction='column' justify='space-between'>
      <Grid item container justify='center'>
        <Grid
          className={classes.container}
          item
          container
          xs={8}
          md={4}
          direction='column'
          alignItems='center'
          wrap='nowrap'
        >
          <img className={classes.logo} height={100} src={Logo} alt='header logo' />
          <Grid
            className={classes.getStarted}
            item
            container
            direction='column'
            alignItems='center'
          >
            <img height={120} src={Envelope} alt='Email icon' />
            <Typography className={classes.getStartedTitle} variant='h2'>
              {l10n('Verify Email Address')}
            </Typography>
          </Grid>
          {/* <Grid item className={classes.verifyMessage}>
            <Typography className={classes.subTitle} align="center">{l10n('Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eget aliquam tellus. Vestibulum in massa est. Ut metus odio, venenatis sed ornare vel, fermentum a lorem. Fusce porttitor pellentesque turpis, sed tempor neque')}</Typography>
          </Grid> */}
          <LineDivider style={{ marginBottom: 16 }} />
          <Grid container item justify='flex-end'>
            <Button onClick={() => doResendEmailVerificationLink()}>
              {l10n('Resend verification email')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <FooterQuote seed={seed} />
    </Grid>
  </DocumentTitle>
);

VerifyEmail.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerifyEmail);
