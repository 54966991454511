import React, { PureComponent, Fragment } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Search from '@material-ui/icons/Search';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SortableTable from './SortableTable';
import CustomActions from './CustomActions';
import DefaultContent from './DefaultContent';
import DeleteEmployeeDialog from './DeleteEmployeeDialog';
import ReallocationDialog from './ReallocationDialog';
import EmployeeImage from '../../assets/employee.png';

import {
  getEmployees,
  deleteEmployees,
  resetManagerStatus,
} from '../../store/actions/managerActions';
import { updateVirtualCardStatus } from '../../store/actions/virtualCardActions';
import { updateSubscriptionStatus } from '../../store/actions/stripeActions';

const validStatuses = ['active', 'paused'];

const styles = theme => ({
  panelTitle: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  avatarCell: {
    paddingRight: 0,
    width: 1,
  },
  container: {
    height: '75vh',
  },
  padded: {
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 4}px ${0}px ${theme.spacing.unit *
      4}px`,
  },
  unpadded: {
    padding: 0,
  },
  budget: {
    fontWeight: 'bold',
  },
  icon: {
    marginRight: 5,
  },
  statusIcon: {
    fontSize: 10,
    marginRight: 5,
  },
  statusActive: {
    color: '#09A542',
  },
  statusDeleted: {
    color: '#EE6352',
  },
  statusInvited: {
    color: '#0061E3',
  },
  statusDisable: {
    color: '#707070',
  },
  contextActions: {
    color: '#0061E3',
  },
});

const getItemsById = (ids, items) => {
  return items.filter(it => ids.find(id => id.id === it.id));
};

const getPauseButtonStatus = (selectedIds, employees) => {
  const selectedEmployees = getItemsById(selectedIds, employees);
  if (selectedEmployees.every(emp => emp.status === 'deleted')) {
    return false;
  }
  return selectedEmployees.some(emp => emp.status === 'paused') ? 'unpause' : 'pause';
};

const getDeleteButtonStatus = (selectedIds, employees) => {
  const selectedEmployees = getItemsById(selectedIds, employees);
  return selectedEmployees.some(emp => emp.status !== 'deleted');
};

const getReallocateButtonStatus = (selectedIds, employees) => {
  const selectedEmployees = getItemsById(selectedIds, employees);
  return selectedEmployees.some(emp => emp.status === 'deleted' && emp.remainingBudget > 0);
};

const getFreezeButtonStatus = (selectedIds, employees) => {
  const selectedEmployees = getItemsById(selectedIds, employees);
  const isAllSelectedDisabled = selectedEmployees.every(
    emp => emp.status === 'invited' || emp.status === 'deleted',
  );
  if (!isAllSelectedDisabled) {
    if (selectedEmployees[0].status === 'invited') return false;
    return selectedEmployees.some(emp => emp.cardStatus === 'inactive') ? 'activate' : 'freeze';
  }
  return false;
};

const checkIfUserValid = user => validStatuses.includes(user.status);

class Employee extends PureComponent {
  static propTypes = {
    employees: PropTypes.array.isRequired,
    load: PropTypes.func.isRequired,
    updateCard: PropTypes.func.isRequired,
    updateSubscription: PropTypes.func.isRequired,
    managerId: PropTypes.string.isRequired,
    resetStatus: PropTypes.func,
    tableOnly: PropTypes.bool,
    history: PropTypes.object,
    classes: PropTypes.object,
    limit: PropTypes.number,
  };

  state = {
    showReallocationDialog: false,
    showDeleteDialog: false,
    selectedUsers: [],
    validEmployees: [],
  };

  detailedHeaders = [
    { id: 'name', label: 'Name', numeric: false, sort: true },
    { id: 'email', label: 'Email', numeric: false, sort: true },
    { id: 'remainingBudget', label: 'Remaining Budget', numeric: false, sort: false },
    { id: 'estatus', label: 'Subscription Status', numeric: false, sort: true },
    { id: 'card', label: 'Card Status', numeric: false, sort: true },
  ];

  componentDidMount = () => {
    const { load, managerId } = this.props;
    load(managerId);
  };

  handleAddEmployeeClick = () => {
    const { history } = this.props;
    history.push('/inviteMembers');
  };

  handleReallocateSelected = () => {
    const { selectedUsers } = this.state;
    const { employees } = this.props;
    const selectedEmployees = getItemsById(selectedUsers, employees);
    const validEmployees = selectedEmployees.filter(
      emp => emp.status === 'deleted' && emp.remainingBudget > 0,
    );

    if (validEmployees.length > 0) {
      this.setState({
        showReallocationDialog: true,
        validEmployees,
      });
    }
  };

  handleDeleteSelected = () => {
    const { selectedUsers } = this.state;
    const { employees } = this.props;
    const selectedEmployees = getItemsById(selectedUsers, employees);
    const validEmployees = selectedEmployees.filter(checkIfUserValid);
    this.setState({ showDeleteDialog: true, validEmployees });
  };

  handleCardStatusUpdate = isFreezed => {
    const { selectedUsers } = this.state;
    const { updateCard, employees } = this.props;

    const selectedEmployees = getItemsById(selectedUsers, employees);
    const users = selectedEmployees.filter(user => {
      if (user.cardStatus === 'active' && isFreezed === 'freeze') return true;
      if (user.cardStatus === 'inactive' && isFreezed === 'activate') return true;
      return false;
    });
    updateCard(users, isFreezed);
  };

  handleSelectUpdate = selections => {
    const users = selections.filter(sl => sl.selected);
    this.setState({ selectedUsers: users });
  };

  handleSuccessDelete = () => {
    const { resetStatus } = this.props;
    resetStatus();
    this.setState({ showDeleteDialog: false, selectedUsers: [] });
  };

  handleSubscriptionsSelected = isPaused => {
    const { selectedUsers } = this.state;
    const { updateSubscription, employees } = this.props;

    const selectedEmployees = getItemsById(selectedUsers, employees);
    const users = selectedEmployees.filter(user => {
      const isStatusPaused = user.status === 'paused';
      return isPaused === 'unpause' ? isStatusPaused : !isStatusPaused;
    });

    updateSubscription(users, isPaused);
  };

  formatData = em => {
    const { classes } = this.props;

    const status = !em.status ? 'active' : em.status;
    const cardStatus = !em.cardStatus ? 'pending' : em.cardStatus;

    return {
      ...em,
      name: `${em.firstName} ${em.lastName}`,
      email: em.email,
      remainingBudget: (
        <Typography className={classes.budget} variant='body2'>{`$${Number(
          em.remainingBudget,
        ).toFixed(2)}`}</Typography>
      ),
      estatus: {
        value: status.toUpperCase(),
        content: (
          <div>
            <Grid>
              <Icon
                className={classNames(
                  classes.statusIcon,
                  { [classes.statusActive]: status === 'active' },
                  { [classes.statusDisable]: status === 'paused' },
                  { [classes.statusInvited]: status === 'invited' },
                  { [classes.statusDeleted]: status === 'deleted' },
                )}
              >
                lens
              </Icon>
              <strong>{status.toUpperCase()}</strong>
            </Grid>
          </div>
        ),
      },
      card: {
        value: cardStatus.toUpperCase(),
        content: (
          <div>
            <Grid>
              <Icon
                className={classNames(
                  classes.statusIcon,
                  { [classes.statusActive]: cardStatus === 'active' },
                  { [classes.statusDisable]: cardStatus === 'inactive' },
                  { [classes.statusInvited]: status === 'invited' },
                  { [classes.statusDeleted]: status === 'deleted' },
                )}
              >
                lens
              </Icon>
              <strong>
                {status === 'invited' || status === 'deleted'
                  ? status.toUpperCase()
                  : cardStatus.toUpperCase()}
              </strong>
            </Grid>
          </div>
        ),
      },
    };
  };

  renderContent = data => {
    const { selectedUsers } = this.state;
    const { classes, tableOnly, employees, limit } = this.props;

    const inputProps = {
      endAdornment: (
        <InputAdornment position='end'>
          <Search />
        </InputAdornment>
      ),
    };

    if (data.length > 0) {
      return (
        <Fragment>
          <SortableTable
            data={data}
            headers={this.detailedHeaders}
            defaultSortedColumn='remainingBudget'
            defaultSortOrder='desc'
            enableToolbar={!tableOnly}
            limit={limit}
            contextActions={({ rowsSelected }) => {
              const showActions = rowsSelected.length > 0;
              const isPaused = getPauseButtonStatus(selectedUsers, employees);
              const isFreezed = getFreezeButtonStatus(selectedUsers, employees);
              const isDeleteVisible = getDeleteButtonStatus(selectedUsers, employees);
              const isReallocateVisible = getReallocateButtonStatus(selectedUsers, employees);
              const buttons = [
                {
                  action: isPaused,
                  icon: isPaused === 'pause' ? 'pause' : 'play_arrow',
                  visible: showActions && !!isPaused,
                  handler: this.handleSubscriptionsSelected,
                  value: isPaused,
                },
                {
                  action: isFreezed,
                  icon: 'credit_card',
                  visible: showActions && !!isFreezed,
                  handler: this.handleCardStatusUpdate,
                  value: isFreezed,
                },
                {
                  action: 'delete',
                  icon: 'delete',
                  inMenu: true,
                  handler: this.handleDeleteSelected,
                  visible: isDeleteVisible,
                },
                {
                  action: 'reallocate',
                  icon: 'sync_alt',
                  inMenu: true,
                  handler: this.handleReallocateSelected,
                  visible: isReallocateVisible,
                },
                // { action: 'redeem', icon: 'redo', inMenu: true },
              ];

              return (
                <CustomActions
                  rows={rowsSelected}
                  buttons={buttons}
                  onAddClick={this.handleAddEmployeeClick}
                  showMoreMenu={showActions}
                />
              );
            }}
            toolbar={{ label: 'Seach Employees', placeholder: 'Search Name, Email', inputProps }}
            enableSelect={!tableOnly}
            onSelect={this.handleSelectUpdate} // Will return data + selected field(bool)
          />
          {tableOnly && (
            <center>
              <Button fullWidth color='primary' onClick={this.handleAddEmployeeClick}>
                <PersonAddIcon className={classes.icon} />
                {'add employee'.toUpperCase()}
              </Button>
            </center>
          )}
        </Fragment>
      );
    }
    return (
      <DefaultContent
        image={EmployeeImage}
        title='Get Started'
        subheading='Shop now to gain more skills and enhance your character'
        buttonText='ADD EMPLOYEE'
        onButtonClick={this.handleAddEmployeeClick}
      />
    );
  };

  render = () => {
    const { classes, tableOnly, employees } = this.props;
    const { showReallocationDialog, showDeleteDialog, validEmployees } = this.state;
    const data = employees.map(this.formatData);
    return (
      <section>
        {!tableOnly && (
          <Typography className={classes.panelTitle} variant='h6'>
            EMPLOYEES
          </Typography>
        )}
        <Paper
          className={classNames(
            { [classes.padded]: !tableOnly && data.length <= 0 },
            { [classes.unpadded]: tableOnly },
          )}
          elevation={0}
        >
          <ReallocationDialog
            employees={validEmployees}
            options={employees.filter(emp => emp.status !== 'deleted')}
            open={showReallocationDialog}
            onClose={() => this.setState({ showReallocationDialog: false })}
          />
          <DeleteEmployeeDialog
            employees={validEmployees}
            open={showDeleteDialog}
            onClose={() => {
              this.setState({ showDeleteDialog: false });
            }}
            onSuccess={this.handleSuccessDelete}
          />
          {this.renderContent(data)}
        </Paper>
      </section>
    );
  };
}

const mapStateToProps = ({
  manager: { employees },
  firebase: {
    auth: { uid },
  },
}) => ({
  employees,
  managerId: uid,
});

const mapDispatchToProps = dispatch => ({
  load: id => dispatch(getEmployees(id)),
  deleteEms: users => dispatch(deleteEmployees(users)),
  resetStatus: () => dispatch(resetManagerStatus()),
  updateCard: (user, activate) => dispatch(updateVirtualCardStatus(user, activate)),
  updateSubscription: (users, action) => dispatch(updateSubscriptionStatus(users, action)),
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Employee);
