import React from 'react';
import DocumentTitle from 'react-document-title';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import uuid from 'uuid-random';

import Footer from './Footer';

import AttitudeImage from '../../assets/attitude.png';
import CommunicationImage from '../../assets/communication.png';
import CriticalThinkingImage from '../../assets/critical_thinking.png';
import EmotionalIntelligenceImage from '../../assets/emotional_intelligence.png';
import ExecutionImage from '../../assets/execution.png';
import LeadershipImage from '../../assets/leadership.png';
import RelationshipImage from '../../assets/relationship.png';
import Header from './Header';
import l10n from '../../services/l10n';
import faqsJson from '../../json/faqs.json';

const styles = theme => ({
  sectionAbout: {
    marginTop: theme.spacing.unit * 10,
    background: theme.palette.primary.main,
    height: theme.spacing.unit * 75,
    padding: `${theme.spacing.unit * 20}px ${theme.spacing.unit * 20}px ${theme.spacing.unit *
      20}px ${theme.spacing.unit * 30}px`,
  },
  sectionTraits: {
    marginTop: theme.spacing.unit * 10,
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 20}px ${theme.spacing.unit *
      20}px ${theme.spacing.unit * 30}px`,
  },
  title: {
    fontSize: 48,
    marginBottom: 32,
  },
  description: {
    fontSize: 22,
    fontFamily: 'Open Sans',
    fontWeight: 'light',
  },
  image: {
    borderRadius: '5%',
  },
  midsection: {
    marginBottom: theme.spacing.unit * 5,
  },
  trait: {
    fontSize: 26,
    marginBottom: 26,
  },
  traitIcon: {
    marginBottom: theme.spacing.unit * 3,
  },
  expansionPanel: {
    boxShadow: '0px 0px',
    backgroundColor: '#f9fcff',
  },
  expansionTitle: {
    paddingTop: theme.spacing.unit * 3,
    paddingBottom: theme.spacing.unit * 3,
  },
  faqs: {
    marginTop: theme.spacing.unit * 10,
    padding: `${theme.spacing.unit * 5}px ${theme.spacing.unit * 20}px ${theme.spacing.unit *
      20}px ${theme.spacing.unit * 30}px`,
    backgroundColor: '#f9fcff',
  },
  faqsAnswer: {
    whiteSpace: 'pre-line',
  },
  footer: {
    height: 200,
    backgroundColor: '#474747',
    padding: `${theme.spacing.unit * 20}px ${theme.spacing.unit * 20}px ${theme.spacing.unit *
      20}px ${theme.spacing.unit * 30}px`,
  },
  footer_sectionTitle: {
    fontSize: 17,
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginTop: 7,
    marginBottom: 7,
  },
  footer_sectionText: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    color: '#FFFFFF',
    marginTop: 7,
    marginBottom: 7,
  },
  finalCTA: {
    backgroundColor: '#EE6352',
    padding: `${theme.spacing.unit * 10}px ${theme.spacing.unit * 28}px ${theme.spacing.unit *
      10}px ${theme.spacing.unit * 28}px`,
  },
  finalCTAContent: {
    padding: '0 16px',
  },
  finalCTATitle: {
    fontSize: 45,
    fontWeight: 'bold',
    color: '#FFFFFF',
  },
  finalCTASubheading: {
    fontSize: 20,
    fontFamily: 'Open Sans',
    color: '#FFF',
  },
});

const FAQ = ({ classes }) => (
  <DocumentTitle title='FAQs'>
    <div>
      <Header />
      <section className={classes.sectionTraits}>
        <Grid container direction='row' justify='center' spacing={32}>
          <Grid className={classes.midsection} item>
            <Typography className={classes.title} variant='h6'>
              {l10n('About Brinq')}
            </Typography>
            <Typography classname={classes.description} variant='body1'>
              {l10n(`
              At Brinq we have seen, first hand, that soft-skills can be mastered, but leading experts in education have come to find out that it isn't without an awareness of deficiency
              coupled with strong training materials that this mastery can be achieved. That's where Brinq 100 come in. Using industry leading studies on employee effectiveness, we
              came up with 7 areas of oppurtunity and 100 supporting skills that help master what matters most.
              `)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TraitModule
              image={AttitudeImage}
              alt='attitude'
              title='Attitude'
              traits={[
                'Accountable',
                'Eager to Learn',
                'Enthusiastic',
                'Flexible',
                'Genuine',
                'Healthy',
                'Motivated',
                'Open minded',
                'Open to Feedback',
                'Optimistic',
                'Passionate',
                'Patient',
                'Proactive',
                'Respectful',
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TraitModule
              image={CommunicationImage}
              alt='communication'
              title='Communication'
              traits={[
                'Articulate',
                'Attentive',
                'Composed',
                'Conflict Resolver',
                'Direct',
                'Engaging',
                'Good Presenter',
                'Humourous',
                'Informative',
                'Listener',
                'Negotiate',
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TraitModule
              image={CriticalThinkingImage}
              alt='critical thinking'
              title='Critical Thinking'
              traits={[
                'Creative',
                'Decisive',
                'Entrepreneurial',
                'Financially Responsible',
                'Innovative',
                'Intuitive',
                'Methodical',
                'Perceptive',
                'Prepared',
                'Resourceful',
                'Self Reliant',
                'Strategic',
                'Technical',
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TraitModule
              image={EmotionalIntelligenceImage}
              alt='emotional intelligence'
              title='Emotional Intelligence'
              traits={[
                'Disciplined',
                'Empathetic',
                'Forgiving',
                'Happy',
                'Humble',
                'Logical',
                'Professional',
                'Reasonable',
                'Self Aware',
                'Socially Aware',
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TraitModule
              image={ExecutionImage}
              alt='execution'
              title='Execution'
              traits={[
                'Autonomous',
                'Consistent',
                'Dedicated',
                'Dependable',
                'Detailed',
                'Driven',
                'Effcient',
                'Focused',
                'Hard Working',
                'Organized',
                'Persistent',
                'Productive',
                'Punctual',
                'Responsible',
                'Sets Priorities',
                'Teachable',
                'Thorough',
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TraitModule
              image={LeadershipImage}
              alt='leadership'
              title='Leadership'
              traits={[
                'Ambitious',
                'Bold',
                'Business Savvy',
                'Confident',
                'Courageous',
                'Delegater',
                'Empowering',
                'Ethical',
                'Goal Oriented',
                'Influential',
                'Inspiring',
                'Manages Time',
                'Takes Risks',
                'Teacher',
                'Visionary',
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <TraitModule
              image={RelationshipImage}
              alt='relationship'
              title='Relationship'
              traits={[
                'Approachable',
                'Collaborative',
                'Considerate',
                'Friendly',
                'Generous',
                'Grateful',
                'Honest',
                'Interested',
                'Loyal',
                'Networks',
                'Outgoing',
                'Politically Savvy',
                'Tactful',
                'Team Player',
              ]}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3} />
        </Grid>
      </section>
      <section className={classes.faqs}>
        <Grid container direction='column'>
          <Grid item>
            <Typography className={classes.title} variant='h6'>
              {l10n('FAQs')}
            </Typography>
          </Grid>
          {faqsJson.map(faq => (
            <Grid item key={uuid()}>
              <Divider />
              <ExpansionPanel className={classes.expansionPanel}>
                <ExpansionPanelSummary
                  className={classes.expansionTitle}
                  expandIcon={<ChevronRightIcon color='primary' />}
                >
                  <Typography variant='h6'>{l10n(faq.question)}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography variant='subtitle1' className={classes.faqsAnswer}>
                    {l10n(faq.answer)}
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
          ))}
          <Divider />
        </Grid>
      </section>
      <section>
        <Grid container className={classes.finalCTA} justify='space-between' alignItems='center'>
          <Grid item className={classes.finalCTAContent} md={12} lg={6}>
            <Typography className={classes.finalCTATitle} variant='h6'>
              {l10n('Contact Us')}
            </Typography>
            <Typography className={classes.finalCTASubheading} variant='subtitle1'>
              {l10n(
                'For other questions about the Brinq Marketplace, email us at support@thebrinq.com',
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              component='a'
              href='/support'
              variant='contained'
              size='large'
              color='secondary'
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
      </section>
      <Footer />
    </div>
  </DocumentTitle>
);

FAQ.propTypes = {
  classes: PropTypes.object,
};

const _TraitModule = ({ classes, image, alt, title, traits }) => (
  <div>
    <img className={classes.traitIcon} src={image} alt={alt} />
    <Typography className={classes.trait} variant='h6'>
      {l10n(title)}
    </Typography>
    <Typography paragraph variant='body1'>
      {traits.map(trait => (
        <span key={trait}>
          {trait}
          <br />
        </span>
      ))}
    </Typography>
  </div>
);

_TraitModule.propTypes = {
  classes: PropTypes.object,
  image: PropTypes.object,
  alt: PropTypes.string,
  title: PropTypes.string,
  traits: PropTypes.array,
};

const TraitModuleStyles = theme => ({
  trait: {
    fontSize: 26,
    marginBottom: 26,
  },
  traitIcon: {
    marginBottom: theme.spacing.unit * 3,
  },
});

const TraitModule = withStyles(TraitModuleStyles)(_TraitModule);

export default withStyles(styles)(FAQ);
