import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isAuthenticated } from '../services/Auth';

class PrivateRoute extends Component {
  static propTypes = {
    component: PropTypes.func,
    location: PropTypes.object,
    render: PropTypes.func,
    allowedRoles: PropTypes.array.isRequired,
    currentUser: PropTypes.object,
  };

  static defaultProps = {
    render: () => {},
  };

  isUserLoaded = user => !!user.id;

  renderRoute = props => {
    const { location, render, component: Generic, allowedRoles, currentUser } = this.props;
    const role = currentUser.isManager ? 'manager' : 'employee';
    if (!this.isUserLoaded(currentUser)) {
      return <noscript />;
    }

    if (isAuthenticated() && allowedRoles.includes(role)) {
      return render(props) || <Generic {...props} />;
    }
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  };

  render = () => {
    const { location, render, component, allowedRoles, currentUser, ...rest } = this.props;
    return <Route {...rest} render={this.renderRoute} />;
  };
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default connect(mapStateToProps)(PrivateRoute);
