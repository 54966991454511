import React, { Component } from 'react';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';

import { isPlainObject } from 'lodash';
import SortableTableHead from './SortableTableHead';
import SortableTableToolbar from './SortableTableToolbar';

function getValue(obj) {
  if (isPlainObject(obj)) return obj.value;
  return obj;
}

function desc(a, b, orderBy) {
  const sortByA = getValue(a[orderBy]);
  const sortByB = getValue(b[orderBy]);
  if (sortByB < sortByA) {
    return -1;
  }
  if (sortByB > sortByA) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = theme => ({
  root: {
    overflowX: 'auto',
  },
  tableWrapper: {
    padding: `0px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px ${theme.spacing.unit *
      3}px`,
  },
  table: {
    minWidth: 700,
  },
});

class SortableTable extends Component {
  static propTypes = {
    data: PropTypes.array,
    headers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        label: PropTypes.string,
        numeric: PropTypes.bool,
        sort: PropTypes.bool,
      }),
    ),
    search: PropTypes.string,
    limit: PropTypes.number,
    enableToolbar: PropTypes.bool,
    toolbar: PropTypes.shape({
      adorments: PropTypes.object,
      label: PropTypes.string,
      placeholder: PropTypes.string,
    }),
    enableSelect: PropTypes.bool,
    onSelect: PropTypes.func,
    defaultSortedColumn: PropTypes.string,
    defaultSortOrder: PropTypes.string,
    contextActions: PropTypes.func,
    classes: PropTypes.object,
  };

  static defaultProps = {
    search: '',
    enableToolbar: true,
  };

  state = {
    order: 'asc',
    orderBy: '',
    search: '',
    selected: [],
    results: [],
  };

  componentDidMount = () => {
    const { defaultSortOrder, defaultSortedColumn, headers, data } = this.props;
    const fields = headers.map(h => h.id);
    const selected = data.map(dt => ({ id: dt.id, selected: false, status: dt.status }));
    this.setState({ order: defaultSortOrder, orderBy: defaultSortedColumn, fields, selected });
  };

  componentDidUpdate = prevProps => {
    const { selected } = this.state;
    const { data } = this.props;
    const isPropsDataEqual = isEqual(prevProps.data, data);
    if (!isPropsDataEqual) {
      const selectedUsers = selected.map((user, index) => ({
        ...user,
        status: data[index].status,
      }));
      this.setState({ selected: selectedUsers });
    }
  };

  handleRequestSort = (event, property) => {
    const { orderBy, order } = this.state;
    const _orderBy = property;
    let _order = 'desc';

    if (orderBy === _orderBy && order === _order) {
      _order = 'asc';
    }
    this.setState({ order: _order, orderBy: _orderBy });
  };

  handleSearch = search => {
    const { fields } = this.state;
    const { data } = this.props;

    const searchOptions = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: fields,
    };

    const filteredData = new Fuse(data, searchOptions);
    const results = filteredData.search(search);
    this.setState({ search, results });
  };

  handleSelect = row => () => {
    const { onSelect } = this.props;
    const { selected } = this.state;
    const updatedSelected = selected.map(dt => {
      if (dt.id === row.id) {
        return { ...dt, selected: !dt.selected };
      }
      return dt;
    });
    this.setState({ selected: updatedSelected });
    onSelect(updatedSelected);
  };

  handleSelectAll = event => {
    const { onSelect } = this.props;
    const { selected } = this.state;
    const updatedSelected = selected.map(sl => ({ ...sl, selected: event.target.checked }));
    this.setState({ selected: updatedSelected });
    onSelect(updatedSelected);
  };

  render = () => {
    const { order, orderBy, search, results, selected } = this.state;
    const {
      headers,
      classes,
      enableToolbar,
      defaultSortOrder,
      defaultSortedColumn,
      enableSelect,
      contextActions,
      data,
      toolbar,
      limit,
      ...others
    } = this.props;
    // eslint-disable-next-line
    const rowsSelected = selected.filter(sl => sl.selected);
    let presentedData = search.length > 0 ? results : data;

    return (
      <div className={classes.root}>
        {enableToolbar && (
          <SortableTableToolbar
            label={toolbar.label}
            placeholder={toolbar.placeholder}
            inputProps={toolbar.adorments}
            rowsSelected={rowsSelected}
            onSearch={this.handleSearch}
            contextActions={contextActions}
          />
        )}
        <section className={classes.tableWrapper}>
          {enableToolbar && <Divider />}
          <Table className={classes.table} aria-labelledby='tableTitle' {...others}>
            <SortableTableHead
              headers={headers}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
              onSelectAll={this.handleSelectAll}
              enableSelect={enableSelect}
              numSelected={rowsSelected.length}
              rowCount={data.length}
            />
            <TableBody>
              {stableSort(presentedData, getSorting(order, orderBy))
                .splice(0, limit || presentedData.length)
                .map(value => {
                  const selectedValue = selected.find(sl => sl.id === value.id) || {
                    selected: false,
                  };
                  return (
                    <TableRow hover tabIndex={-1} key={value.id}>
                      {enableSelect && (
                        <TableCell padding='checkbox'>
                          <Checkbox
                            color='primary'
                            checked={selectedValue.selected}
                            onClick={this.handleSelect(value)}
                          />
                        </TableCell>
                      )}
                      {headers.map(h => {
                        if (React.isValidElement(value[h.id])) {
                          return (
                            <TableCell key={h.id} {...value[h.id].options}>
                              {value[h.id]}
                            </TableCell>
                          );
                        }
                        if (isPlainObject(value[h.id])) {
                          const { content, ...props } = value[h.id];
                          return (
                            <TableCell key={h.id} {...props}>
                              <Typography component='span' variant='body2'>
                                {content}
                              </Typography>
                            </TableCell>
                          );
                        }
                        if (value[h.id] instanceof Function) {
                          return (
                            <TableCell key={h.id} {...value[h.id].options}>
                              <Typography component='span' variant='body2'>
                                {value[h.id]()}
                              </Typography>
                            </TableCell>
                          );
                        }
                        return (
                          <TableCell key={h.id}>
                            <Typography component='span' variant='body2'>
                              {value[h.id]}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </section>
      </div>
    );
  };
}

export default withStyles(styles)(SortableTable);
