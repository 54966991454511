import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import Header from '../LandingPage/Header';
import l10n from '../../services/l10n';
import { validateFields } from '../../services/Validation';
import FooterQuote from '../components/FooterQuote';
import { signIn } from '../../store/actions/authActions';
import { provider } from '../../services/Firebase';
import LineDivider from '../components/LineDivider';
import PasswordInput from '../components/PasswordInput';

import Logo from '../../assets/header_logo.png';
import Facebook from '../../assets/FacebookLogo.svg';
import Google from '../../assets/GoogleLogo.svg';

const styles = theme => ({
  logo: {
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing.unit,
    fontWeight: 'bold',
  },
  form: {
    maxWidth: 353,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 40px',
    },
  },
  right: {
    textAlign: 'right',
  },
  terms: {
    color: '#ee6352',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  title2: {
    fontSize: 18,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: 15,
  },
  verifyInput: {
    marginTop: 80,
  },
  content: {
    flex: 1,
  },
  componentContainer: {
    marginTop: 60,
  },
  submit: {
    width: 150,
    marginTop: 32,
    marginBottom: 30,
  },
  forgotPassword: {
    marginBottom: 30,
    fontSize: 14,
  },
  social: {
    maxWidth: 460,
  },
  btnLink: {
    backgroundColor: '#ffffff',
    width: 220,
    height: 60,
  },
  buttonLogo: {
    marginRight: 12,
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: '',
        password: '',
        provider: 'email',
      },
      required: {
        email: false,
        password: false,
      },
    };
  }

  seed = Math.random();

  onProviderLogin = oauthProvider => {
    const { _signIn } = this.props;
    const { user } = this.state;

    user.provider = oauthProvider;
    this.setState({ user });
    _signIn(user);
  };

  onChange = e => {
    const { user } = this.state;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  onSubmit = async () => {
    const { _signIn } = this.props;
    const { user, required } = this.state;
    const fields = validateFields(user, required);

    if (fields.error) {
      this.setState({ required: fields });
    } else {
      _signIn(user);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { auth, history } = nextProps;

    if (auth.error) NotificationManager.error(auth.message, auth.title);
    if (auth.success) history.push('/dashboard');
  }

  render() {
    const { classes, history, width } = this.props;
    const { user, required } = this.state;
    const isMobile = width === 'xs';

    return (
      <DocumentTitle title='Login'>
        <Fragment>
          <Grid
            container
            className={classes.content}
            direction='column'
            alignItems='center'
            justify={isMobile ? 'center' : 'space-between'}
          >
            {isMobile && (
              <Grid item>
                <Header isStatic={true} />
              </Grid>
            )}
            <Grid
              item
              container
              alignItems='center'
              direction='column'
              justify='flex-start'
              style={{ marginBottom: 20, marginTop: isMobile ? 80 : 0 }}
            >
              {!isMobile && (
                <Grid container item xs={10} justify='space-between' alignItems='center'>
                  <Grid className={classes.logo} item onClick={() => history.push('/')}>
                    <img src={Logo} height={112} alt='Brinq logo' />
                  </Grid>
                  <Grid item>
                    {l10n("Don't have an account?")}
                    <Button href='/register' size='large' className={classes.button}>
                      {l10n('Sign Up')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid item container direction='column' alignItems='center'>
                <Grid
                  onKeyDown={e => e.key === 'Enter' && this.onSubmit()}
                  className={classes.form}
                  item
                  container
                  direction='column'
                >
                  {isMobile && (
                    <Grid className={classes.header} item>
                      <Grid container direction='column' justify='flex-start'>
                        <Grid item>
                          <Typography variant='h4' className={classes.title}>
                            Sign in
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <TextField
                    fullWidth
                    required
                    name='email'
                    label={l10n('Work Email')}
                    value={user.email}
                    onChange={this.onChange}
                    margin='normal'
                    error={required.email}
                  />
                  <PasswordInput
                    fullWidth
                    required
                    name='password'
                    label={l10n('Password')}
                    onChange={this.onChange}
                    margin='normal'
                    error={required.password}
                  />
                  <Grid item container direction='column' alignItems='center'>
                    <Button
                      className={classes.submit}
                      size='large'
                      variant='contained'
                      color='primary'
                      onClick={this.onSubmit}
                    >
                      {l10n('Sign in')}
                    </Button>
                    <a className={classes.forgotPassword} href='/forgotpassword'>
                      {l10n('Forgot password? Click here')}
                    </a>
                  </Grid>
                </Grid>
                <LineDivider style={{ marginBottom: isMobile ? 20 : 60, maxWidth: 416 }} />
                <Grid className={classes.social} item container justify='center'>
                  <Grid
                    item
                    container
                    justify={isMobile ? 'center' : 'space-between'}
                    spacing={isMobile ? 32 : 8}
                  >
                    {isMobile && (
                      <Grid xs={12} item>
                        <Typography variant='h6' className={classes.title2}>
                          Or Sign in With
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <Button
                        variant='contained'
                        style={{ color: '#475993' }}
                        className={classes.btnLink}
                        onClick={() => this.onProviderLogin(provider.facebook)}
                      >
                        <img className={classes.buttonLogo} src={Facebook} alt='Facebook logo' />
                        {l10n('Sign in with Facebook')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        className={classes.btnLink}
                        onClick={() => this.onProviderLogin(provider.google)}
                      >
                        <img className={classes.buttonLogo} src={Google} alt='Google logo' />
                        {l10n('Sign in with Google')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile && <FooterQuote seed={this.seed} />}
          </Grid>
        </Fragment>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
});

const mapDispatchToProps = dispatch => ({
  _signIn: user => dispatch(signIn(user)),
});

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  _signIn: PropTypes.func.isRequired,
  auth: PropTypes.object,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default compose(
  withStyles(styles),
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Login);
