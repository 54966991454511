import React from 'react';
import ReactDOM from 'react-dom';
import Thunk from 'redux-thunk';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { reduxFirestore, getFirestore } from 'redux-firestore';
import { reactReduxFirebase, getFirebase } from 'react-redux-firebase';
import App from './js/App';
import theme from './theme';
import { unregister } from './registerServiceWorker';
import rootReducer from './store/reducers/rootReducer';
import firebaseConfig from './services/Firebase';

import './css/index.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(Thunk.withExtraArgument({ getFirestore, getFirebase })),
    reduxFirestore(firebaseConfig),
    reactReduxFirebase(firebaseConfig, { attachAuthIsReady: true }),
  ),
);

store.firebaseAuthIsReady.then(() => {
  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </MuiThemeProvider>,
    document.getElementById('root'),
  );
  unregister();
});
