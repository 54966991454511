import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import StarShaded from '@material-ui/icons/Star';
import StarBorder from '@material-ui/icons/StarBorder';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { connect } from 'react-redux';
import _ from 'lodash';
import uuid from 'uuid-random';
import l10n from '../../services/l10n';
import { toggleModal, VCARD_MODAL } from '../../store/actions/modalActions';
import GA from '../components/GA';

import cultureWorks from '../../assets/the-culture-works.png';
import walmart from '../../assets/Walmart_Spark.svg';
import merchandiseDefault from '../../assets/merchandise_item.png';
import amazon from '../../assets/amazon.png';
import udemy from '../../assets/udemy.svg';
import audible from '../../assets/audible.jpg';

import books from '../../assets/Book.svg';
import course from '../../assets/course.svg';
import audio from '../../assets/audio.svg';

const logo = { cultureWorks, walmart, amazon, udemy, audible };

const styles = () => ({
  card: {
    width: 200,
    '& button': {
      height: '100%',
      width: '100%',
    },
  },
  media: {
    maxHeight: 200,
    width: 200,
  },
  default: {
    width: 60,
    height: 60,
  },
  orange: {
    color: '#ed5731',
    fontSize: 14,
  },
  thumbnail: {
    height: 200,
    width: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  merchTitle: {
    fontSize: 15,
    fontWeight: 500,
    lineHeight: '18px',
    height: 36,
  },
  price: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  author: {
    color: '#696767',
    fontSize: 12,
  },
  logo: {
    height: 18,
    marginRight: 8,
    '&:last-child': {
      marginRight: 0,
    },
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardContentWrapper: {
    height: 105,
  },
  horizontalBorder: {
    borderTop: '1px solid #F8F8F8',
    margin: '0 -17px',
  },
  tile: {
    margin: 20,
  },
  footer: {
    padding: '8px 0',
  },
  rating: {
    color: '#ed5731',
    '& svg': {
      fontSize: 16,
    },
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  image: {
    position: 'absolute',
    top: 148,
    right: 0,
    width: 40,
    marginRight: 10,
  },
});

const areEqual = (prevProps, nextProps) => _.isEqual(prevProps.merchandise, nextProps.merchandise);

const Merchandise = props => {
  const { classes, merchandise, _toggleModal } = props;

  const onCardClick = m => {
    GA.trackEvent('Shop', 'Click Shop Card', m.name);
    _toggleModal(VCARD_MODAL, true, m);
  };

  return (
    <Grid container>
      {_.map(merchandise, m => (
        <Grid item key={m.id} className={classes.tile}>
          <LazyLoadComponent>
            <CardItem style={classes} item={m} onClick={() => onCardClick(m)} />
          </LazyLoadComponent>
        </Grid>
      ))}
    </Grid>
  );
};

Merchandise.propTypes = {
  classes: PropTypes.object.isRequired,
  merchandise: PropTypes.array,
  _toggleModal: PropTypes.func,
};

const MemoizeMechandise = React.memo(Merchandise, areEqual);

const getMediaTypeSVG = media => {
  const medium = media.toLowerCase();
  switch (medium) {
    case 'book':
      return books;
    case 'assessment':
      return course;
    case 'course':
      return course;
    case 'audiobook':
      return audio;
    default:
      return books;
  }
};

const getPriceByType = (price, discountPrice) => {
  let currentPrice = discountPrice || price || 'Click for price';
  if (typeof currentPrice === 'number') {
    currentPrice = `$${currentPrice.toFixed(2)}`;
  }
  return currentPrice;
};

const CardItem = props => {
  const { style, item, onClick } = props;

  const providers = Object.keys(item.affiliateLinks);
  const { price, discountPrice, totalReviews = 0 } = item;
  const title = _.truncate(item.name, { length: 35 });
  const author = _.truncate(item.author, { length: 25 });
  const rating = _.isNumber(item.rating) ? Math.floor(item.rating) : 0;

  const starBorder = _.times(5 - rating, () => <StarBorder key={uuid()} />);
  const starShaded = _.times(rating, () => <StarShaded key={uuid()} />);

  return (
    <Card className={style.card} onClick={() => onClick()}>
      <CardActionArea>
        <div className={style.thumbnail}>
          <LazyLoadImage
            className={item.imageUrl ? style.media : style.default}
            alt={item.name}
            src={item.imageUrl || merchandiseDefault}
          />
        </div>
        <img className={style.image} src={getMediaTypeSVG(item.media)} alt={course} />
        <CardContent className={style.cardContent}>
          <div className={style.cardContentWrapper}>
            <Typography className={style.merchTitle} variant='subtitle2'>
              {title}
            </Typography>
            <Typography className={style.author} variant='caption'>
              {l10n(`${author}`)}
            </Typography>
            <div className={style.rating}>
              {starShaded}
              {starBorder}
              {totalReviews > 0 && `(${totalReviews})`}
            </div>
            <Typography className={style.orange}>{l10n(`${item.category}`)}</Typography>
          </div>
          <hr className={style.horizontalBorder} />
          <Grid container justify='space-between' alignItems='center' className={style.footer}>
            <Grid item>
              <Typography className={style.price}>
                {getPriceByType(price, discountPrice, providers[0])}
              </Typography>
            </Grid>
            <Grid item>
              {providers.map(prov => (
                <img key={prov} className={style.logo} src={logo[prov]} alt={logo[prov]} />
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CardItem.propTypes = {
  style: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  _toggleModal: (type, state, product) => dispatch(toggleModal(type, state, product)),
});

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(MemoizeMechandise),
);
