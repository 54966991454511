import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';

import { searchShop } from '../store/actions/shopActions';

const styles = theme => ({
  search: {
    width: theme.spacing.unit * 90,
    [theme.breakpoints.down('md')]: {
      width: theme.spacing.unit * 60,
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing.unit * 30,
    },
  },
});

const MILSEC = 1000;

class GlobalSearch extends Component {
  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    classes: PropTypes.object,
    search: PropTypes.func,
  };

  state = {
    query: '',
    timeout: MILSEC * 0.5,
  };

  goToShop = () => {
    const { history } = this.props;
    setTimeout(() => {
      history.replace('/shop');
    }, 500);
  };

  handleChange = event => {
    this.setState({ query: event.target.value, loading: 'progress' });
  };

  handleSearch = event => {
    const { match } = this.props;

    if (event.key === 'Enter') {
      if (!match.path.includes('shop')) {
        this.goToShop();
      }
    }
  };

  handleQuery = () => {
    const { search } = this.props;
    const { query } = this.state;

    search(query);
  };

  render = () => {
    const { classes } = this.props;
    const { timeout } = this.state;
    const startAdornment = (
      <InputAdornment position='start'>
        <IconButton onClick={this.goToShop}>
          <SearchIcon />
        </IconButton>
      </InputAdornment>
    );

    // IdleTimer component for checking if input stops after timeout amount, then calls the onIdle function
    return (
      <Fragment>
        <TextField
          className={classes.search}
          placeholder='Search by title, author, or skill'
          margin='normal'
          variant='outlined'
          InputProps={{ startAdornment }}
          onChange={this.handleChange}
          onKeyDown={this.handleSearch}
        />
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          element={document}
          onIdle={this.handleQuery}
          timeout={timeout}
        />
      </Fragment>
    );
  };
}

const mapDispatchToProps = dispatch => ({
  search: query => dispatch(searchShop(query)),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(GlobalSearch);
