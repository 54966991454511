import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import l10n from '../../services/l10n';

const VerifyInviteForm = ({ classes, enterNewEmail, requestBrinq }) => (
  <Grid container alignItems='center' direction='column'>
    <Typography variant='h2' className={classes.title} gutterBottom>
      {l10n('Sorry! No one invited you')}
    </Typography>
    <Typography variant='subtitle1' className={classes.subtitle} style={{ marginBottom: 60 }}>
      {l10n('Lorem ipsum dolor sit amet.')}
    </Typography>
    <Grid justify='center' item container size={12} wrap='nowrap' spacing={16}>
      <Grid size={6} item>
        <Button className={classes.btn} onClick={enterNewEmail}>
          {l10n('Enter new Email')}
        </Button>
      </Grid>
      <Grid size={6} item>
        <Button variant='contained' color='primary' className={classes.btn} onClick={requestBrinq}>
          {l10n('Invite your manager to Brinq')}
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

VerifyInviteForm.propTypes = {
  classes: PropTypes.object.isRequired,
  enterNewEmail: PropTypes.func.isRequired,
  requestBrinq: PropTypes.func.isRequired,
};

export default VerifyInviteForm;
