import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import { reallocateBudget, resetManagerStatus } from '../../store/actions/managerActions';
import l10n from '../../services/l10n';

const styles = theme => ({
  title: {
    fontSize: 25,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: theme.spacing.unit * 15,
    maxWidth: theme.spacing.unit * 76,
  },
  checkbox: {
    width: theme.spacing.unit * 72,
  },
  root: {
    padding: theme.spacing.unit * 2,
  },
  actions: {
    padding: theme.spacing.unit * 2,
  },
  progress: {
    margin: theme.spacing.unit,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class ReallocationDialog extends Component {
  static propTypes = {
    open: PropTypes.bool,
    employees: PropTypes.array,
    reallocate: PropTypes.func,
    resetStatus: PropTypes.func,
    options: PropTypes.array,
    classes: PropTypes.object,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    employees: [],
    onClose: () => {},
  };

  state = {
    selected: [],
    deletedEmployees: [],
    checkAll: false,
    error: false,
  };

  handleChange = event => {
    this.setState({ selected: event.target.value });
  };

  handleClickAll = event => {
    const { options } = this.props;
    const { checked } = event.target;
    const totalSelects = options.map(opt => opt.id);
    this.setState(prev => ({ checkAll: !prev.checkAll, selected: checked ? totalSelects : [] }));
  };

  handleReallocate = () => {
    const { reallocate, onClose, employees } = this.props;
    const { selected } = this.state;

    if (selected.length > 0) {
      const data = {
        deletedEmployees: employees.map(emp => emp.id),
        selectedEmployees: selected,
      };
      reallocate(data);
      onClose();
    }
    this.setState({ error: !(selected.length > 0) });
  };

  handleClickDone = () => {
    const { resetStatus, onClose } = this.props;
    onClose();
    resetStatus();
  };

  getDisplayName = user => {
    return user.firstName ? `${user.firstName} ${user.lastName}` : user.email;
  };

  renderAllocation = () => {
    const { selected, checkAll, error } = this.state;
    const { classes, employees = [], options = [] } = this.props;
    const totalDeletedBudget = employees.reduce((acc, opt) => acc + opt.remainingBudget, 0);
    return (
      <Fragment>
        <DialogTitle id='alert-dialog-title'>
          <center>
            <Typography className={classes.title} color='primary' variant='h6'>
              {l10n('REALLOCATE FUNDS')}
            </Typography>
            <Typography variant='subtitle1'>
              You have <strong>${totalDeletedBudget}</strong> available for reallocation before 5%
              processing fee.
            </Typography>
          </center>
        </DialogTitle>
        <DialogContent>
          <center>
            <FormGroup>
              <FormControl
                fullWidth
                error={error && selected.length === 0}
                className={classes.formControl}
              >
                <InputLabel htmlFor='select-multiple-chip'>Select Employees</InputLabel>
                <Select
                  multiple
                  value={selected}
                  onChange={this.handleChange}
                  input={<Input id='select-multiple-chip' />}
                  renderValue={values =>
                    values.map((value, index) => {
                      const user = options.find(sel => sel.id === value);
                      return (
                        <span key={user.id}>{`${this.getDisplayName(user)}${
                          values.length === 1 || values.length - 1 === index ? '' : ', '
                        }`}</span>
                      );
                    })
                  }
                  MenuProps={MenuProps}
                >
                  {options.map(option => {
                    const isSelected = selected.includes(option.id);
                    return (
                      <MenuItem key={option.id} value={option.id}>
                        <Checkbox checked={isSelected} color='primary' value='checkedA' />
                        {this.getDisplayName(option)}
                      </MenuItem>
                    );
                  })}
                </Select>
                {error && (
                  <FormHelperText>
                    Please select at least one employee to receive the reallocation
                  </FormHelperText>
                )}
              </FormControl>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox checked={checkAll} color='primary' onClick={this.handleClickAll} />
                }
                label='Re-allocate to all employees'
              />
            </FormGroup>
          </center>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={this.handleReallocate} color='primary' variant='contained' autoFocus>
            {l10n('REALLOCATE')}
          </Button>
        </DialogActions>
      </Fragment>
    );
  };

  render = () => {
    const { open, onClose, classes } = this.props;
    return (
      <Dialog
        className={classes.root}
        open={open}
        onClose={onClose}
        maxWidth='sm'
        fullWidth
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {this.renderAllocation()}
      </Dialog>
    );
  };
}

const mapDispatchToProps = dispatch => ({
  reallocate: data => dispatch(reallocateBudget(data)),
  resetStatus: () => dispatch(resetManagerStatus()),
});

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(ReallocationDialog);
