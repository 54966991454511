import {
  SELECTED_PURCHASE,
  PURCHASE_HISTORY_REQUEST,
  PURCHASE_HISTORY_SUCCESS,
  PURCHASE_HISTORY_FAILURE,
  PURCHASE_HISTORY_MERCHANTS,
  PURCHASE_HISTORY_UPLOAD,
  PURCHASE_HISTORY_ROLE,
} from '../actions/purchaseHistoryActions';

const initState = {
  history: [],
  loading: true,
  manager: false,
  uploadModal: false,
  viewModal: false,
};

const purchaseHistoryReducer = (state = initState, action) => {
  const { type } = action;

  switch (type) {
    case PURCHASE_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PURCHASE_HISTORY_ROLE:
      return {
        ...state,
        loading: true,
        manager: action.payload,
      };
    case SELECTED_PURCHASE:
      return {
        ...state,
        loading: false,
        selected: action.payload,
      };
    case PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        history: action.payload,
      };
    case PURCHASE_HISTORY_MERCHANTS:
      return {
        ...state,
        loading: false,
        merchants: action.payload,
      };
    case PURCHASE_HISTORY_UPLOAD:
      return {
        ...state,
        loading: false,
        status: action.payload,
      };
    case PURCHASE_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default purchaseHistoryReducer;
