import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import authReducer from './authReducer';
import shopReducer from './shopReducer';
import userReducer from './userReducer';
import skillsCarouselReducer from './skillsCarouselReducer';
import managerReducer from './managerReducer';
import purchaseHistoryReducer from './purchaseHistoryReducer';
import notificationReducer from './notificationReducer';
import accountDetailsReducer from './accountDetailsReducer';
import virtualCardReducer from './virtualCardReducer';
import loadingReducer from './loadingReducer';
import modalReducer from './modalReducer';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer,
  currentUser: userReducer,
  shop: shopReducer,
  manager: managerReducer,
  notification: notificationReducer,
  skillsCarousel: skillsCarouselReducer,
  purchase: purchaseHistoryReducer,
  accountDetails: accountDetailsReducer,
  virtualCard: virtualCardReducer,
  loading: loadingReducer,
  modals: modalReducer,
});

export default rootReducer;
