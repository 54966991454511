import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  grid: {
    marginTop: 20,
  },
  sortTitle: {
    paddingRight: 5,
  },
  sortbar: {
    background: '#EEECEC',
    padding: 10,
  },
  resultsBar: {
    padding: 10,
  },
  selectInput: {
    background: '#FFF',
    fontWeight: 500,
    width: 150,
    paddingLeft: 5,
  },
});

const Sorter = ({ totalResults = 0, searchTerms = [], onSort = () => {}, classes }) => {
  const [selected, setSelected] = useState('lowToHigh');

  const onChange = event => {
    setSelected(event.target.value);
    onSort(event.target.value);
  };

  let textResult = `${totalResults} result${totalResults > 1 ? 's' : ''}`;
  textResult += searchTerms.length > 0 ? ` for ${searchTerms.join(', ')}` : '';

  return (
    <Grid className={classes.grid} container direction='column'>
      <Grid className={classes.resultsBar} item>
        <Typography variant='h6'>{textResult}</Typography>
      </Grid>
      <Grid item>
        <Grid className={classes.sortbar} container justify='flex-end' alignItems='center'>
          <Typography className={classes.sortTitle} variant='h6'>
            Sort By:
          </Typography>
          <Select
            value={selected}
            onChange={onChange}
            inputProps={{ name: 'sort', id: 'sort-simple', className: classes.selectInput }}
          >
            <MenuItem value='date'>Release Date</MenuItem>
            <MenuItem value='lowToHigh'>Price: Low to High</MenuItem>
            <MenuItem value='highToLow'>Price: High to Low</MenuItem>
            <MenuItem value='aToZ'>A to Z</MenuItem>
            <MenuItem value='zToA'>Z to A</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

Sorter.propTypes = {
  totalResults: PropTypes.number,
  searchTerms: PropTypes.array,
  onSort: PropTypes.func,
  classes: PropTypes.object,
};

export default withStyles(styles)(Sorter);
