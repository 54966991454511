import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import AppHeader from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import l10n from '../../services/l10n';

import logo from '../../assets/header_logo.png';
import stuckLogo from '../../assets/brinq logo_white.png';

const styles = theme => ({
  logo: {
    height: 80,
  },
  headerContent: {
    margin: '0 16px',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  header: {
    height: 80,
  },
  stuckHeader: {
    boxShadow: 'none',
    backgroundColor: '#EE6352',
  },
  scrollingHeader: {
    backgroundColor: '#FFFFFF',
  },
  headerTransition: {
    transition: theme.transitions.create(['box-shadow', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
  },
  stuckSignUpButton: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  scrollingSignUpButton: {
    color: '#EE6352',
    '&:hover': {
      color: '#EE6352',
    },
  },
  stuckSignInButton: {
    color: '#EE6352',
    '&:hover': {
      color: '#EE6352',
    },
  },
  scrollingSignInButton: {
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
    },
  },
  signUpButton: {
    marginRight: 12,
  },
  buttonTransition: {
    transition: theme.transitions.create(['color', 'background-color'], {
      duration: theme.transitions.duration.complex,
    }),
  },
});

class Header extends Component {
  constructor(props) {
    super(props);

    this.scrolled = false;
  }

  static propTypes = {
    classes: PropTypes.object,
    currentUser: PropTypes.object,
    isStatic: PropTypes.bool,
  };

  static defaultProps = {
    isStatic: false,
  };

  state = {
    scrolled: false,
  };

  componentDidMount() {
    this.setStatic();
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  setStatic = () => {
    const { isStatic } = this.props;
    if (isStatic) this.setState({ scrolled: isStatic });
  };

  handleScroll = () => {
    const { scrolled } = this.state;
    const { isStatic } = this.props;

    if (!isStatic) {
      if (window.scrollY > 10 && !scrolled) {
        this.setState({ scrolled: true });
        window.requestAnimationFrame(() => this.forceUpdate());
      }
      if (window.scrollY <= 10 && scrolled) {
        this.setState({ scrolled: false });
        window.requestAnimationFrame(() => this.forceUpdate());
      }
    }
  };

  render = () => {
    const { classes, currentUser } = this.props;
    const { scrolled } = this.state;

    return (
      <div>
        <CssBaseline />
        <AppHeader
          className={`${classes.header} ${classes.headerTransition} ${
            scrolled ? classes.scrollingHeader : classes.stuckHeader
          }`}
          position='fixed'
          color='secondary'
        >
          <Toolbar>
            <Grid
              className={classes.headerContent}
              container
              justify='space-between'
              alignItems='center'
            >
              <Grid item>
                <Grid container alignItems='center'>
                  <a href='/'>
                    {scrolled && (
                      <img
                        className={classes.logo}
                        src={logo}
                        alt='Brinq soft skills development'
                      />
                    )}
                    {!scrolled && (
                      <img
                        className={classes.logo}
                        src={stuckLogo}
                        alt='Brinq soft skills development'
                      />
                    )}
                  </a>
                </Grid>
              </Grid>
              <Grid item>
                {currentUser.id ? (
                  <Button
                    variant='contained'
                    color={`${scrolled ? 'primary' : 'secondary'}`}
                    component={Link}
                    className={`${
                      !scrolled ? classes.stuckSignInButton : classes.scrollingSignInButton
                    }`}
                    to={currentUser.isManager ? '/dashboard' : '/shop'}
                  >
                    {currentUser.isManager ? l10n('Go to Dashboard') : l10n('Go Shopping')}
                  </Button>
                ) : (
                  <Fragment>
                    <Button
                      component={Link}
                      className={`${classes.signUpButton} ${
                        !scrolled ? classes.stuckSignUpButton : classes.scrollingSignUpButton
                      }`}
                      to='/register'
                    >
                      {l10n('Sign Up')}
                    </Button>
                    <Button
                      variant='contained'
                      component={Link}
                      className={`${
                        !scrolled ? classes.stuckSignInButton : classes.scrollingSignInButton
                      }`}
                      color={`${scrolled ? 'primary' : 'secondary'}`}
                      to='/login'
                    >
                      {l10n('Sign In')}
                    </Button>
                  </Fragment>
                )}
              </Grid>
            </Grid>
          </Toolbar>
        </AppHeader>
      </div>
    );
  };
}

const mapStateToProps = ({ currentUser }) => ({ currentUser });

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
)(Header);
