import { HANDLE_USER_CHANGE, VERIFY_INVITATION, CLEAR_INVITE_USER } from '../actions/authActions';

const initState = {
  auth: {
    error: null,
    success: null,
    title: '',
    message: '',
  },
  user: {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: '',
  },
  inviteUser: undefined,
};

const authReducer = (state = initState, action) => {
  let _state;
  switch (action.type) {
    case 'LOGIN_ERROR':
      return {
        auth: {
          error: true,
          title: 'Login Failed!',
          message: action.res.message,
        },
      };

    case 'LOGIN_SUCCESS':
      return {
        auth: {
          success: true,
          uid: action.res.uid,
        },
      };

    case 'SIGNOUT_SUCCESS':
      return {
        auth: {
          logout: true,
          title: 'Logout Success!',
          message: 'Logout successful.',
        },
      };

    case 'SIGNUP_SUCCESS':
      _state = { ...state };
      _state.auth = {
        success: true,
        title: action.res.title,
        message: action.res.message,
      };
      return _state;

    case 'SIGNUP_ERROR':
      _state = { ...state };
      _state.auth = {
        error: true,
        title: 'Registration Failed!',
        message: action.res.message,
      };
      return _state;

    case HANDLE_USER_CHANGE:
      _state = { ...state };
      _state.user = action.payload.user;
      return _state;

    case VERIFY_INVITATION:
      _state = { ...state };
      _state.inviteUser = action.payload.inviteUser;
      _state.inviteManager = action.payload.inviteManager;
      return _state;

    case CLEAR_INVITE_USER:
      _state = { ...state };
      _state.inviteManager = undefined;
      _state.inviteUser = undefined;
      return _state;

    default:
      return state;
  }
};

export default authReducer;
