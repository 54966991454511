import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';

import l10n from '../../services/l10n';
import FooterQuote from '../components/FooterQuote';
import PasswordInput from '../components/PasswordInput';
import { sendPasswordResetRequest, sendNewPasswordRequest } from '../../store/actions/authActions';

import Logo from '../../assets/header_logo.png';

const styles = theme => ({
  logo: {
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing.unit,
    fontWeight: 'bold',
  },
  form: {
    maxWidth: 400,
    marginTop: 100,
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  content: {
    flex: 1,
  },
  submit: {
    width: 200,
    marginTop: 32,
    marginBottom: 30,
  },
  forgotPassword: {
    marginBottom: 30,
    fontSize: 14,
  },
  checkIcon: {
    fontSize: 95,
  },
  submittedForm: {
    marginTop: 100,
  },
  gap: {
    height: 50,
  },
});

const extractToken = search => {
  let params = search.slice(1);
  params = params.split('=');
  return params[0] === 'token' && params[1];
};

class ForgotPassword extends Component {
  seed = Math.random();

  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    sendPasswordReset: PropTypes.func,
    sendNewPassword: PropTypes.func,
  };

  state = {
    email: '',
    isSubmitted: false,
    error: false,
    token: '',
    newPassword: '',
    confirmPassword: '',
  };

  componentDidMount = () => {
    const { location } = this.props;
    this.setState({ token: extractToken(location.search) });
  };

  onChange = e => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  onSubmit = () => {
    const { token, newPassword, confirmPassword, email } = this.state;
    const { sendPasswordReset, sendNewPassword } = this.props;

    if (token) {
      if (newPassword === confirmPassword) {
        this.setState({ isSubmitted: true });
        sendNewPassword(confirmPassword, token);
      } else NotificationManager.error('Passwords do not match!');
    } else {
      const emailRegex = /(\w|\d)+@(\w|\d)+\.(\w|\d)+/g;
      if (!email || !emailRegex.test(email)) {
        this.setState({ error: true });
        NotificationManager.error('Valid email is required!');
      } else {
        this.setState({ isSubmitted: true });
        sendPasswordReset(email);
      }
    }
  };

  renderForm = () => {
    const { email, error } = this.state;
    const { classes } = this.props;
    return (
      <Grid
        onKeyDown={e => e.key === 'Enter' && this.onSubmit()}
        className={classes.form}
        item
        container
        direction='column'
      >
        <Typography className={classes.title} variant='h5'>
          {l10n('Password Recovery')}
        </Typography>
        <Typography variant='body1'>
          {l10n('Enter your email, we will send a link to reset your password')}
        </Typography>
        <TextField
          fullWidth
          required
          name='email'
          label={l10n('Work Email')}
          value={email}
          onChange={this.onChange}
          margin='normal'
          error={error}
        />
        <Button
          className={classes.submit}
          size='large'
          variant='contained'
          color='primary'
          onClick={this.onSubmit}
        >
          {l10n('Reset Password')}
        </Button>
      </Grid>
    );
  };

  renderFormSubmitted = message => {
    const { classes, history } = this.props;
    return (
      <Grid className={classes.submittedForm} item container direction='column' alignItems='center'>
        <Icon className={classes.checkIcon} color='primary'>
          check_circle
        </Icon>
        <div className={classes.gap} />
        <Typography className={classes.title} variant='h4'>
          {l10n(message)}
        </Typography>
        <div className={classes.gap} />
        <Grid item container direction='row' justify='center'>
          <Button className={classes.buttonSize} size='large' onClick={() => history.push('/')}>
            Back to Home
          </Button>
          <Button
            className={classes.buttonSize}
            variant='contained'
            color='primary'
            size='large'
            onClick={() => history.push('/register')}
          >
            Go to Sign up
          </Button>
        </Grid>
      </Grid>
    );
  };

  renderPasswordResetForm = () => {
    const { classes } = this.props;
    const { newPassword, confirmPassword } = this.state;
    return (
      <Grid className={classes.form} item container direction='column' spacing={16}>
        <Grid item>
          <Typography className={classes.title} variant='h4'>
            {l10n('Reset your password')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='body1'>
            {l10n('Type in your new password for your account')}
          </Typography>
        </Grid>
        <Grid item>
          <PasswordInput
            margin='normal'
            name='newPassword'
            fullWidth
            required
            label='New Password'
            value={newPassword}
            onChange={this.onChange}
          />
        </Grid>
        <Grid item>
          <PasswordInput
            margin='normal'
            name='confirmPassword'
            fullWidth
            required
            label='Confirm New Password'
            value={confirmPassword}
            onChange={this.onChange}
          />
        </Grid>
        <Grid item>
          <Button
            className={classes.buttonSize}
            variant='contained'
            color='primary'
            size='large'
            fullWidth
            onClick={this.onSubmit}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
    );
  };

  renderContent = () => {
    const { isSubmitted, token } = this.state;

    if (token)
      return isSubmitted
        ? this.renderFormSubmitted('Password update request is sent')
        : this.renderPasswordResetForm();
    return isSubmitted
      ? this.renderFormSubmitted('We sent you an email with the instructions')
      : this.renderForm();
  };

  render() {
    const { classes, history } = this.props;

    return (
      <DocumentTitle title='Forgot Password'>
        <Fragment>
          <Grid
            container
            className={classes.content}
            direction='column'
            alignItems='center'
            justify='space-between'
          >
            <Grid
              item
              container
              alignItems='center'
              direction='column'
              justify='flex-start'
              style={{ marginBottom: 20 }}
            >
              <Grid container item xs={10} justify='space-between' alignItems='center'>
                <Grid className={classes.logo} item onClick={() => history.push('/')}>
                  <img src={Logo} height={112} alt='Brinq logo' />
                </Grid>
                <Grid item>
                  {l10n("Don't have an account?")}
                  <Button href='/register' size='large' className={classes.button}>
                    {l10n('Sign Up')}
                  </Button>
                </Grid>
              </Grid>

              <Grid container direction='column' alignItems='center'>
                {this.renderContent()}
              </Grid>
            </Grid>
            <FooterQuote seed={this.seed} />
          </Grid>
        </Fragment>
      </DocumentTitle>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sendPasswordReset: email => dispatch(sendPasswordResetRequest(email)),
  sendNewPassword: (pw, token) => dispatch(sendNewPasswordRequest(pw, token)),
});

export default compose(
  withStyles(styles),
  connect(
    null,
    mapDispatchToProps,
  ),
)(ForgotPassword);
