import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import { connect } from 'react-redux';
import changeSkill from '../../store/actions/skillsCarouselActions';
import l10n from '../../services/l10n';

const SkillsArr = [
  l10n('Sales'),
  l10n('Leadership'),
  l10n('Negotiation'),
  l10n('Communication'),
  l10n('Critical Thinking'),
  l10n('Presenting'),
  l10n('Strategy'),
  l10n('Execution'),
  l10n('Time Management'),
  l10n('Goal Setting'),
  l10n('Entrepreneurship'),
  l10n('Decision Making'),
];

class _SkillsCarousel extends Component {
  constructor(...args) {
    super(...args);
    this.unusedSkills = [...SkillsArr];
  }

  componentDidMount() {
    const { changeSkill: updateSkill } = this.props;
    const idx = Math.floor(Math.random() * this.unusedSkills.length);
    updateSkill(idx);
  }

  fadeIn() {
    const { changeSkill: updateSkill } = this.props;
    this.updateUnused();
    const idx = Math.floor(Math.random() * this.unusedSkills.length);
    updateSkill(idx);
  }

  updateUnused() {
    const { skillsIdx } = this.props;
    this.unusedSkills = [
      ...this.unusedSkills.slice(0, skillsIdx),
      ...this.unusedSkills.slice(skillsIdx + 1),
    ];
    if (this.unusedSkills.length === 0) {
      this.unusedSkills = [...SkillsArr];
    }
  }

  render() {
    const { classes, skillsIdx, enter } = this.props;
    return (
      <Grid
        container
        justify='space-between'
        alignItems='baseline'
        wrap='nowrap'
        className={classes.SkillsCarousel}
      >
        <Grid item className={classes.bracket}>
          [
        </Grid>
        <Fade in={enter} timeout={{ enter: 1000, exit: 1000 }} onExited={() => this.fadeIn()}>
          <Grid item className={classes.skill}>{`${this.unusedSkills[skillsIdx]}`}</Grid>
        </Fade>
        <Grid item className={classes.bracket}>
          ]
        </Grid>
      </Grid>
    );
  }
}

_SkillsCarousel.propTypes = {
  classes: PropTypes.object,
  changeSkill: PropTypes.func,
  skillsIdx: PropTypes.number,
  enter: PropTypes.bool,
};

const SkillsCarouselStyles = () => ({
  SkillsCarousel: {
    margin: '60px 0',
    width: 320,
  },
  bracket: {
    fontSize: 35,
    color: '#ffffff',
    fontWeight: 'bold',
    lineHeight: '35px',
  },
  skill: {
    fontSize: 30,
    color: '#474747',
    fontWeight: 'bold',
    lineHeight: '30px',
  },
  be: {
    color: '#ffffff',
    fontSize: 30,
    fontWeight: 'bold',
    marginRight: 8,
  },
});

const mapStateToProps = state => ({
  ...state.skillsCarousel,
});

const mapDispatchToProps = {
  changeSkill,
};

export default withStyles(SkillsCarouselStyles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(_SkillsCarousel),
);
