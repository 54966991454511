import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import l10n from '../../services/l10n';

import PasswordInput from '../components/PasswordInput';

const SignUpForm = ({ classes, onChange, required, user, onSubmit }) => (
  <Grid container justify='center'>
    <Grid item xs={10} className={classes.form} noValidate autoComplete='off'>
      <Typography variant='h2' className={classes.title} gutterBottom>
        {l10n('Sign up')}
      </Typography>
      <Typography variant='subtitle1' className={classes.subtitle} gutterBottom>
        {l10n(`It's free. Start to use it now`)}
      </Typography>
      <TextField
        fullWidth
        required
        name='firstName'
        label={l10n('First Name')}
        value={user.firstName}
        onChange={onChange}
        margin='normal'
        error={required.firstName}
      />
      <TextField
        fullWidth
        required
        name='lastName'
        label={l10n('Last Name')}
        value={user.lastName}
        onChange={onChange}
        margin='normal'
        error={required.lastName}
      />
      <TextField
        fullWidth
        required
        name='email'
        label={l10n('Work Email')}
        value={user.email}
        onChange={onChange}
        margin='normal'
        error={required.email}
      />
      <PasswordInput
        fullWidth
        required
        name='password'
        label={l10n('Password')}
        onChange={onChange}
        value={user.password}
        error={required.password}
      />
      <TextField
        fullWidth
        required
        name='zip'
        label={l10n('Zip Code')}
        value={user.zip}
        onChange={onChange}
        margin='normal'
        error={required.zip}
      />
      <FormControl className={classes.formControl} fullWidth>
        <Button variant='contained' color='primary' className={classes.btn} onClick={onSubmit}>
          {l10n('Create Account')}
        </Button>
      </FormControl>
    </Grid>
    <FormControl className={classes.formControl} fullWidth>
      <Typography variant='body2' gutterBottom align='center'>
        {l10n('By signing up you agree to Brinq')}{' '}
        <a href='/terms' className={classes.terms}>
          {l10n('Terms of Use')}
        </a>{' '}
        {l10n('and')}{' '}
        <a href='/privacy' className={classes.terms}>
          {l10n('Privacy Policy')}
        </a>
      </Typography>
    </FormControl>
  </Grid>
);

SignUpForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SignUpForm;
