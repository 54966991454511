export const VCARD_MODAL = 'vCardModal';
export const CLOSE_WALKTHROUGH = 'CLOSE_WALKTHROUGH';
export const LAUNCH_WALKTHROUGH = 'LAUNCH_WALKTHROUGH';

export const toggleModal = (type, state, product) => dispatch => {
  dispatch({ type, payload: { open: state } });
  if (product) {
    dispatch({ type: 'PRODUCT_SELECTED', payload: product });
  }
  if (!product) {
    dispatch({ type: 'PRODUCT_SELECTED', payload: null });
  }
};

export const finishWalkthrough = user => async (dispatch, getState, { getFirestore }) => {
  const firestore = getFirestore();
  await firestore
    .collection('users')
    .doc(user.id)
    .update({ finishedWalkthrough: true });
  dispatch({ type: CLOSE_WALKTHROUGH });
};

export const launchWalkthrough = () => dispatch => {
  dispatch({ type: LAUNCH_WALKTHROUGH });
};
