import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';

import { registerAuthListener } from '../store/actions/userActions';
import Header from './Header';
import Dashboard from './Dashboard/Dashboard';
import InviteMembersLanding from './Dashboard/InviteMembersLanding';
import LandingPage from './LandingPage/LandingPage';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Pricing from './Pricing';
import Login from './Auth/Login';
import RegisterLanding from './Auth/RegisterLanding';
import RegisterManager from './Auth/RegisterManager';
import RegisterEmployee from './Auth/RegisterEmployee';
import SetupAccount from './AccountSetup/SetupAccount';
import VerifyEmail from './Auth/VerifyEmail';
import VerifyLanding from './Auth/VerifyLanding';
import ForgotPassword from './Auth/ForgotPassword';
import Shop from './Shop';
import Subscribe from './Subscribe';
import FAQ from './LandingPage/FAQ';
import PrivateRoute from './PrivateRoute';
import EditUserPage from './EditUserPage';
import ContactUs from './ContactUs';
import HowToUsePage from './HowToUse/HowToUsePage';
import l10n from '../services/l10n';
import GA from './components/GA';

import '../css/App.css';
import 'react-notifications/lib/notifications.css';

const SimplePage = component => (
  <div className='container'>
    {/* <Header /> */}
    {/* <div className="content-container"> */}
    {component}
    {/* </div> */}
    <NotificationContainer />
    {/* <Footer /> */}
  </div>
);

const StandardPage = component => (
  <div className='container'>
    <Header />
    {component}
    <NotificationContainer />
  </div>
);

class App extends Component {
  componentDidMount() {
    const { _registerAuthListener } = this.props;
    GA.initGa();
    GA.pageView();
    _registerAuthListener();
  }

  render() {
    return (
      <DocumentTitle title='Brinq'>
        <Router>
          <React.Fragment>
            <Route
              render={() => {
                GA.pageView(); /* send page views on each route change to google analytics */
                return null;
              }}
            />
            <Switch>
              <Route exact path='/' component={LandingPage} />
              <Route path='/login' render={props => SimplePage(<Login {...props} />)} />
              <Route
                path='/register'
                render={props => SimplePage(<RegisterLanding {...props} />)}
              />
              <Route
                path='/registerManager'
                render={props => SimplePage(<RegisterManager {...props} />)}
              />
              <Route
                path='/registerEmployee'
                render={props => SimplePage(<RegisterEmployee {...props} />)}
              />
              <Route
                path='/setupAccount'
                render={props => SimplePage(<SetupAccount {...props} />)}
              />
              <Route path='/verifyEmail' render={props => SimplePage(<VerifyEmail {...props} />)} />
              <Route path='/verify' render={props => SimplePage(<VerifyLanding {...props} />)} />
              <Route path='/subscribe' render={props => SimplePage(<Subscribe {...props} />)} />
              <Route path='/faqs' render={props => SimplePage(<FAQ {...props} />)} />
              <Route path='/support' render={props => SimplePage(<ContactUs {...props} />)} />
              <Route path='/howitworks' render={props => SimplePage(<HowToUsePage {...props} />)} />
              <Route path='/pricing' render={props => SimplePage(<Pricing {...props} />)} />
              <Route
                path='/terms'
                render={props => SimplePage(<TermsAndConditions {...props} />)}
              />
              <Route path='/privacy' render={props => SimplePage(<PrivacyPolicy {...props} />)} />
              <Route
                path='/forgotpassword'
                render={props => SimplePage(<ForgotPassword {...props} />)}
              />
              <PrivateRoute
                allowedRoles={['manager']}
                path='/inviteMembers'
                render={props => SimplePage(<InviteMembersLanding {...props} />)}
              />
              <PrivateRoute
                allowedRoles={['employee', 'manager']}
                path='/editProfile/:employeeId?'
                render={props => StandardPage(<EditUserPage {...props} />)}
              />
              <PrivateRoute
                allowedRoles={['employee', 'manager']}
                path='/dashboard'
                render={props => StandardPage(<Dashboard {...props} />)}
              />
              <PrivateRoute
                allowedRoles={['employee', 'manager']}
                path='/shop'
                render={props => StandardPage(<Shop {...props} />)}
              />
              <Route
                render={() => (
                  <DocumentTitle title='No Match'>
                    <div>{l10n('No Match')}</div>
                  </DocumentTitle>
                )}
              />
            </Switch>
          </React.Fragment>
        </Router>
      </DocumentTitle>
    );
  }
}

App.propTypes = {
  _registerAuthListener: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  _registerAuthListener: () => dispatch(registerAuthListener()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
