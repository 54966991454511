import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import memoize from 'memoize-one';

import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Filters from './Shop/Filters';
import Merchandise from './Shop/Merchandise';
import Sorter from './Shop/Sorter';
import { shopLoad } from '../store/actions/shopActions';

import NotFoundImage from '../assets/search.png';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    background: '#eee',
    height: '100%',
    minHeight: '100vh',
    padding: theme.spacing.unit * 3,
    flex: 1,
  },
  loader: {
    margin: '20% auto 0',
    display: 'block',
  },
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing.unit * 2,
  },
  searchLink: {
    color: '#238bdb',
  },
  noResults: {
    height: '75vh',
  },
});

const getValueForFilter = filter => {
  switch (filter) {
    case 'Books':
      return 'Book';
    case 'Online Courses':
      return 'Course';
    case 'Assessments':
      return 'Assessment';
    case 'The Culture Works':
      return 'CultureWorks';
    case 'Audiobooks':
      return 'AudioBook';
    default:
      return filter;
  }
};

const isItemInPriceRange = (m, prices) => {
  if (!prices.max && !prices.min) return true;

  let price = m.discountPrice || m.price;
  price = price ? price.toString().replace(/\$/g, '') : 0;
  price = Number(price);
  const min = Number(prices.min) || 0;
  const max = Number(prices.max) || Number.MAX_SAFE_INTEGER;
  return price >= min && price <= max;
};

const isItemLikeQuery = (item, query) =>
  item.name.toLowerCase().includes(query) ||
  item.author.toLowerCase().includes(query) ||
  item.trait.toLowerCase().includes(query);

const isItemCategory = (item, category) => {
  if (category.length === 0 || !category) return true;
  return _.find(category, f => f.toLowerCase() === item.category.toLowerCase());
};

const isItemOfMediaType = (item, types) => {
  if (types.length === 0 || !types) return true;
  return _.find(types, f => f.toLowerCase().replace(/\s/g, '') === item.media.toLowerCase());
};

const isItemInPlatform = (item, platforms) => {
  if (platforms.length === 0 || !platforms) return true;
  let returnValue = false;
  _.forEach(item.affiliateLinks, (value, key) => {
    if (_.find(platforms, f => f.toLowerCase() === key.toLowerCase())) {
      returnValue = true;
    }
  });
  return returnValue;
};

function desc(a, b) {
  if (b < a) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
}

const sorter = orderBy => (a, b) => {
  let priceA = a.discountPrice ? a.discountPrice : a.price;
  priceA = priceA ? priceA.toString().replace(/\$/g, '') : 0;
  let priceB = b.discountPrice ? b.discountPrice : b.price;
  priceB = priceB ? priceB.toString().replace(/\$/g, '') : 0;
  priceA = Number(priceA);
  priceB = Number(priceB);

  switch (orderBy) {
    case 'lowToHigh':
      return -desc(priceA, priceB);
    case 'highToLow':
      return desc(priceA, priceB);
    case 'aToZ':
      return -desc(a.name, b.name);
    case 'zToA':
      return desc(a.name, b.name);
    default:
      return desc(a.year, b.year);
  }
};

const getKeysAsFilters = filters => {
  const _f = Object.keys(filters);
  return _f.map(getValueForFilter);
};

const flattenFilters = filters => {
  const arrayFilters = _.flatMap(filters);
  return _.flatMap(arrayFilters.map(item => Object.keys(item)));
};

class Shop extends Component {
  static defaultProps = {
    query: '',
  };

  state = {
    filters: {
      categories: {},
      mediaTypes: {},
      platforms: {},
    },
    prices: {},
    merchants: {},
    order: 'lowToHigh',
  };

  onCheck = (type, name, checked) => {
    const { filters, prices } = this.state;
    const { merchandise, query } = this.props;

    if (checked) filters[type][name] = checked;
    else delete filters[type][name];

    // TODO async non-blocking execution. Will look for other way to do this (prob aync keyword, but haven't tried)
    setTimeout(() => {
      const _m = _.filter(merchandise, this.isFilteredItem(filters, prices, query));
      this.setState({ merchants: _m });
    }, 500);

    this.setState({
      filters,
    });

    window.scrollTo(0, 0);
  };

  onPrice = e => {
    const { prices, filters } = this.state;
    const { name, value } = e.target;
    const { merchandise, query } = this.props;

    prices[name] = value;

    // TODO async non-blocking execution. Will look for other way to do this (prob aync keyword, but haven't tried)
    setTimeout(() => {
      const _m = _.filter(merchandise, this.isFilteredItem(filters, prices, query));
      this.setState({ merchants: _m });
    }, 500);

    this.setState({ prices });
  };

  onSort = value => {
    this.setState({ order: value });
  };

  isFilteredItem = (filters, prices, query) => item => {
    const priceBool = isItemInPriceRange(item, prices);
    const queryBool = isItemLikeQuery(item, query);
    const categoryBool = isItemCategory(item, getKeysAsFilters(filters.categories));
    const mediaTypeBool = isItemOfMediaType(item, getKeysAsFilters(filters.mediaTypes));
    const platformBool = isItemInPlatform(item, getKeysAsFilters(filters.platforms));
    return priceBool && queryBool && categoryBool && mediaTypeBool && platformBool;
  };

  filterMerchandise = memoize((merchandise, filterer) => _.filter(merchandise, filterer));

  componentDidMount() {
    const { loadShop } = this.props;
    loadShop();
  }

  renderMerchandise = ({ merchants, isLoading, classes }) => {
    if (isLoading) return <CircularProgress className={classes.loader} size={80} />;
    if (merchants.length > 0) return <Merchandise merchandise={merchants} />;
    return (
      <Grid
        className={classes.noResults}
        container
        direction='column'
        justify='center'
        alignContent='center'
        alignItems='center'
        spacing={8}
      >
        <Grid item>
          <img src={NotFoundImage} alt='no search results' />
        </Grid>
        <Grid item>
          <Typography variant='h5'>
            <strong>Sorry No Result Found :(</strong>
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant='h6'>
            No result matching your search. Try <span className={classes.searchLink}>Brain</span>,{' '}
            <span className={classes.searchLink}>Social</span>,{' '}
            <span className={classes.searchLink}>Heart</span>
          </Typography>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { filters, prices, order } = this.state;
    const { isLoading, classes, merchandise, query } = this.props;

    let merchants = this.filterMerchandise(
      merchandise,
      this.isFilteredItem(filters, prices, query),
    );
    merchants = merchants.sort(sorter(order));

    const flatFilters = flattenFilters(filters);
    if (query) flatFilters.push(query);

    return (
      <Grid container className={classes.root}>
        <CssBaseline />
        <Filters onCheck={this.onCheck} onChange={this.onPrice} filters={filters} prices={prices} />
        <main className={classes.content}>
          <Sorter totalResults={merchants.length} searchTerms={flatFilters} onSort={this.onSort} />
          {this.renderMerchandise({ merchants, isLoading, classes })}
        </main>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  merchandise: state.shop.merchants,
  isLoading: state.shop.loading,
  isSearchBarFocus: state.shop.isSearchBarFocus,
  query: state.shop.query,
});

const mapDispatchToProps = dispatch => ({
  loadShop: () => dispatch(shopLoad()),
});

Shop.propTypes = {
  loadShop: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  merchandise: PropTypes.array,
  isLoading: PropTypes.bool,
  query: PropTypes.string,
};

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Shop),
);
