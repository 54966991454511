import React, { Component, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import l10n from '../../services/l10n';

import {
  uploadPurchaseReceipt,
  loadMerchandiseTitle,
} from '../../store/actions/purchaseHistoryActions';

const renderInputComponent = inputProps => {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
};

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component='div'>
      <div>
        {parts.map(part =>
          part.highlight ? (
            <span style={{ fontWeight: 500 }}>{part.text}</span>
          ) : (
            <strong style={{ fontWeight: 300 }}>{part.text}</strong>
          ),
        )}
      </div>
    </MenuItem>
  );
};

const getSuggestionValue = suggestion => suggestion.label;

class UploadReceiptModal extends Component {
  state = {
    title: '',
    validated: {
      file: false,
      name: false,
    },
    suggestions: [],
    files: [],
  };

  getSuggestions = ({ value }) => {
    const { merchandise } = this.props;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    return inputLength === 0
      ? []
      : merchandise.filter(suggestion => {
          const keep =
            count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;
          if (keep) count += 1;
          return keep;
        });
  };

  handleSuggestionsFetchRequested = value => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    const { validated } = this.state;

    validated.name = false;
    if (newValue) validated.name = true;

    this.setState({
      validated,
      [name]: newValue,
    });
  };

  handleUpload = () => {
    const { upload, close } = this.props;
    const { files, title } = this.state;
    upload({ files, title });

    this.setState({
      validated: {
        file: false,
        name: false,
      },
    });

    close();
  };

  handleDrop = files => {
    const { validated } = this.state;
    validated.file = true;

    this.setState({
      files: files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
      validated,
    });
  };

  componentDidMount() {
    const { loadMerchTitle } = this.props;
    loadMerchTitle();
  }

  componentWillUnmount() {
    const { files } = this.state;
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  render() {
    const { open, close, classes, selected } = this.props;
    const { suggestions, title, files, validated } = this.state;
    const required = Object.values(validated);

    const thumbs = files.map(file => (
      <div className={classes.thumb} key={file.name}>
        <div className={classes.thumbInner}>
          <img alt={file.name} src={file.preview} className={classes.img} />
        </div>
      </div>
    ));

    const autosuggestProps = {
      renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
    };

    return (
      <Dialog open={open} onClose={close} aria-labelledby='upload-receipt'>
        <DialogTitle id='upload-receipt' className={classes.title}>
          {l10n('Upload Receipt')}
        </DialogTitle>
        {selected && (
          <DialogContent>
            <Grid container>
              <Grid item>
                <TextField
                  label={l10n('Vendor')}
                  value={selected.source}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={l10n('Date')}
                  value={moment.unix(selected.created).format('MM/DD/YYYY')}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label={l10n('Price')}
                  value={`$${selected.authorized_amount / 100}`}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Autosuggest
                {...autosuggestProps}
                inputProps={{
                  classes,
                  label: l10n('Title'),
                  placeholder: l10n('Fifth Discipline'),
                  value: title,
                  onChange: this.handleChange('title'),
                  required: true,
                }}
                theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={options => (
                  <Paper {...options.containerProps} square>
                    {options.children}
                  </Paper>
                )}
              />
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                {files.length ? (
                  <aside className={classes.thumbsContainer}>{thumbs}</aside>
                ) : (
                  <Dropzone onDrop={this.handleDrop}>
                    {({ getRootProps, getInputProps, isDragActive }) => (
                      <div
                        {...getRootProps()}
                        className={classNames(classes.dropzone, {
                          [classes.isActive]: isDragActive,
                        })}
                      >
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p>{l10n('Drop files here...')}</p>
                        ) : (
                          <p>
                            {l10n('Drag Files or')}{' '}
                            <strong className={classes.orange}>{l10n('BROWSE')}</strong>
                          </p>
                        )}
                      </div>
                    )}
                  </Dropzone>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions className={classes.actions}>
          <Button onClick={close}>{l10n('Cancel')}</Button>
          <Button
            onClick={this.handleUpload}
            disabled={required.includes(false)}
            color='primary'
            variant='contained'
          >
            {l10n('Upload')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dropzone: {
    alignItems: 'center',
    background: '#f1f1f1',
    cursor: 'pointer',
    display: 'flex',
    height: 150,
    marginTop: theme.spacing.unit * 2,
    '& p': {
      textAlign: 'center',
      width: '100%',
    },
  },
  isActive: {
    background: '#fefefe',
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  orange: {
    color: '#ed5731',
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
    textAlign: 'center',
    '& h6': {
      color: '#ed5731',
    },
  },
  upload: {
    backgroundColor: '#ed5731',
    color: '#fff',
  },
  actions: {
    padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 2}px`,
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing.unit * 2,
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    width: 150,
    height: 150,
    padding: 4,
    boxSizing: 'border-box',
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },
});

const mapStateToProps = state => ({
  merchandise: state.purchase.merchants,
  selected: state.purchase.selected,
});

const mapDispatchToProps = dispatch => ({
  loadMerchTitle: () => dispatch(loadMerchandiseTitle()),
  upload: file => dispatch(uploadPurchaseReceipt(file)),
});

UploadReceiptModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  upload: PropTypes.func.isRequired,
  loadMerchTitle: PropTypes.func.isRequired,
  merchandise: PropTypes.array,
  selected: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(memo(UploadReceiptModal));
