import Firebase, { provider } from './Firebase';
import db from './Firestore';

const auth = Firebase.auth();
const actionCodeSettings = {
  url: `https://${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  handleCodeInApp: true,
};

let changesToCommit = [];

auth.onAuthStateChanged(user => {
  if (user && changesToCommit.length > 0) {
    changesToCommit.forEach(change => {
      change();
    });
    changesToCommit = [];
  }
});

const setLastLogin = (uid, lastSignInTime) => {
  const usersRef = db.collection('users');
  usersRef.doc(uid).set(
    {
      lastLogin: lastSignInTime,
    },
    { merge: true },
  );
};

// Sign Up
export const doCreateUserWithEmailAndPassword = (user, inviteUser) =>
  auth
    .createUserWithEmailAndPassword(user.email, user.password)
    .then(async newUser => {
      let retVal = {};
      let saveUser = user;

      saveUser.id = newUser.uid;
      delete saveUser.password;

      if (inviteUser) {
        saveUser = { ...inviteUser.data(), ...saveUser };
      }

      const { currentUser } = auth;
      if (currentUser) {
        if (!user.isManager) {
          const managerDoc = await db
            .collection('users')
            .doc(saveUser.managerId)
            .get();
          saveUser.billingAddress = managerDoc.data().billingAddress
            ? managerDoc.data().billingAddress
            : {};
        }
        // currentUser.sendEmailVerification(actionCodeSettings);
        db.collection('users')
          .doc(newUser.uid)
          .set(saveUser);

        retVal = {
          status: 'success',
          title: 'Confirm Email',
          message: 'Please check your email and verify.',
        };
      } else {
        changesToCommit.push(() => {
          db.collection('users')
            .doc(newUser.uid)
            .set(saveUser);
        });

        retVal = {
          status: 'success',
          title: 'User Info',
          message: 'User information has been saved.',
        };
      }

      return retVal;
    })
    .catch(e => e);

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth
    .signInWithEmailAndPassword(email, password)
    .then(res => {
      setLastLogin(res.uid, res.metadata.lastSignInTime);
      return auth.currentUser; // return auth user for link provider
    })
    .catch(e => e);

// Sign out
export const doSignOut = () => auth.signOut();

// Password Reset
export const doPasswordReset = email => auth.sendPasswordResetEmail(email);

// Password Change
export const doPasswordUpdate = password => auth.currentUser.updatePassword(password);

// Provider Helper
const getProviderForProviderId = method => {
  const m = method.replace('.com', '');
  return provider[m];
};

// Provider Login
export const doSignInWithPopup = thisProvider =>
  auth
    .signInWithPopup(thisProvider)
    .then(res => {
      const { currentUser } = auth;
      if (currentUser) {
        const { uid, email, phoneNumber, photoURL, displayName, metadata } = res.user;
        const name = displayName.split(' ');

        setLastLogin(uid, metadata.lastSignInTime);
        db.collection('users')
          .doc(uid)
          .set(
            {
              id: uid,
              avatar: photoURL,
              firstName: name[0],
              lastName: name[1],
              email,
              phone: phoneNumber,
            },
            { merge: true },
          );
      }

      const retVal = {
        method: 'login',
        user: currentUser,
      };

      return retVal;
    })
    .catch(e => {
      if (e.code === 'auth/account-exists-with-different-credential') {
        const pendingCred = e.credential;
        let retVal = {};

        return auth.fetchProvidersForEmail(e.email).then(methods => {
          if (methods[0] === 'password') {
            retVal = {
              method: methods[0],
              email: e.email,
              pendingCred,
            };

            return retVal;
          }

          // other providers
          const currentProvider = getProviderForProviderId(methods[0]);
          retVal = {
            method: methods[0],
            provider: currentProvider,
            pendingCred,
          };

          return retVal;
        });
      }

      return e;
    });

export const doResendEmailVerificationLink = () =>
  auth.currentUser.sendEmailVerification(actionCodeSettings);

export const isAuthenticated = () => auth.currentUser !== null;

export default {
  doCreateUserWithEmailAndPassword,
  doSignInWithEmailAndPassword,
  doSignOut,
  doPasswordReset,
  doPasswordUpdate,
  doSignInWithPopup,
  doResendEmailVerificationLink,
};
