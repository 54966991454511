import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { getEmployees } from './managerActions';

export const updateSubscriptionStatus = (users, action) => (
  dispatch,
  getState,
  { getFirestore },
) => {
  const { currentUser } = getState();

  axios
    .post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/updateSubscriptionStatus`, {
      users,
      stripeId: currentUser.stripeId,
      action,
    })
    .then(() => {
      dispatch(getEmployees(currentUser.id, { getFirestore, dispatch }));
      NotificationManager.success(
        `${action === 'pause' ? 'Paused' : 'Unpaused'} users successfully!`,
      );
    })
    .catch(err => {
      NotificationManager.error(`Error on performing action: ${err}`);
    });
};
