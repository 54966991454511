import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import l10n from '../../services/l10n';

const VerifyInviteForm = ({ classes, onChange, required, user, onSubmit, isMobile }) => (
  <Fragment>
    <form className={classes.form} noValidate autoComplete='off'>
      <Typography variant='h2' className={classes.title} gutterBottom>
        {l10n('Enter your email')}
      </Typography>
      <TextField
        className={classes.verifyInput}
        fullWidth
        required
        name='email'
        label='Work Email'
        value={user.email}
        onChange={onChange}
        margin='normal'
        error={required.email}
        helperText='This should match the email your manager used to invite you'
      />
      <FormControl className={classes.formControl} fullWidth>
        <Button variant='contained' color='primary' className={classes.btn} onClick={onSubmit}>
          {l10n('Verify')}
        </Button>
      </FormControl>
    </form>
    {!isMobile && (
      <FormControl className={classes.formControl} fullWidth>
        <Typography variant='body2' gutterBottom align='center'>
          {l10n('By signing up you agree to Brinq')}{' '}
          <a href='/terms' className={classes.terms}>
            {l10n('Terms of User')}
          </a>{' '}
          {l10n('and')}{' '}
          <a href='/privacy' className={classes.terms}>
            {l10n('Privacy Policy')}
          </a>
        </Typography>
      </FormControl>
    )}
  </Fragment>
);

VerifyInviteForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default VerifyInviteForm;
