import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import _ from 'lodash';
import { NotificationManager } from 'react-notifications';
import classnames from 'classnames';
import { retrieveVirtualCard } from '../store/actions/virtualCardActions';
import { toggleModal, VCARD_MODAL } from '../store/actions/modalActions';
import l10n from '../services/l10n';

import Logo from '../assets/brinq logo_white.png';
import Visa from '../assets/Visa-Logo.png';

import cultureWorks from '../assets/the-culture-works.png';
import walmart from '../assets/Walmart_Spark.svg';
import amazon from '../assets/amazon.png';
import udemy from '../assets/udemy.svg';
import audible from '../assets/audible.jpg';

const styles = () => ({
  modalContainer: {
    height: '100%',
    pointerEvents: 'none',
  },
  cardContainer: {
    padding: 40,
    backgroundColor: '#fff',
    display: 'flex',
    borderRadius: 5,
    maxWidth: '80vw',
    maxHeight: '80vh',
    overflow: 'auto',
    flexWrap: 'nowrap',
    pointerEvents: 'all',
  },
  modalTitle: {
    marginBottom: 40,
  },
  modalTitleSuspended: {
    marginBottom: 20,
    color: '#E61717',
    fontSize: 35,
    fontWeight: 600,
  },
  modalSubtitle: {
    marginBottom: 40,
    fontFamily: 'Roboto',
    fontSize: 15,
    color: '#403F3F',
    width: 550,
    textAlign: 'center',
  },
  card: {
    width: 500,
    backgroundColor: '#EE6352',
    padding: '16px 30px',
    borderRadius: '10px',
    pointerEvents: 'auto',
    margin: 8,
    flexShrink: 0,
  },
  opacity: {
    opacity: 0.5,
  },
  titleBar: {
    marginBottom: 22,
    marginTop: 8,
  },
  title: {
    fontWeight: 900,
    fontSize: '15px',
    color: '#fff',
  },
  subtitle: {
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#fff',
    marginBottom: 8,
  },
  numberContainer: {
    marginBottom: 16,
  },
  cardNumber: {
    fontFamily: '"Open Sans"',
    fontWeight: 'bold',
    fontSize: '25px',
    letterSpacing: '0.1em',
    color: '#fff',
  },
  detailsContainer: {
    marginBottom: 28,
  },
  cardDetail: {
    fontFamily: '"Open Sans"',
    fontWeight: 600,
    fontSize: '20px',
    color: '#fff',
  },
  copy: {
    marginLeft: '12px',
    fontSize: '12px',
    backgroundColor: '#fff',
    color: '#EE6352',
    borderRadius: '2px',
    padding: '8px',
    cursor: 'pointer',
  },
  numGroup: {
    marginRight: 8,
  },
  strip: {
    height: 50,
    backgroundColor: '#333333',
    width: '500px',
    margin: '12px -30px 64px',
  },
  promoContainer: {
    display: 'flex',
    backgroundColor: '#dddddd',
    padding: '8px 24px',
    marginBottom: 40,
    borderRadius: 5,
  },
  timer: {
    marginTop: 12,
  },
  promoAmount: {
    fontWeight: 'bold',
  },
  redirectButtonContainer: {
    marginTop: 40,
  },
  redirectButton: {
    margin: '0 20px',
    color: '#000',
  },
  buttonIcon: {
    width: 18,
    height: 18,
    marginRight: 10,
  },
  purchaseHistoryText: {
    fontWeight: 600,
    margin: 10,
  },
});

class VirtualCardModal extends Component {
  componentDidMount = async () => {
    const { _retrieveVirtualCard, currentUser } = this.props;

    /* Set up copy to clipboard */
    this.clipboard = new Clipboard('.clippy');
    this.clipboard.on('success', event => {
      event.clearSelection();
      console.log(event);
      NotificationManager.success('Copied!', '', 1000);
    });

    /* get Virtual card */
    _retrieveVirtualCard(currentUser);
  };

  getMerchantName(key) {
    const names = {
      walmart: 'Walmart',
      amazon: 'Amazon',
      ebay: 'eBay',
      coursera: 'Coursera',
      audible: 'Audible',
      cultureWorks: 'The Culture Works',
      udemy: 'Udemy',
      lorman: 'Lorman',
    };
    return names[key];
  }

  componentWillUnmount() {
    this.clipboard.destroy();
  }

  handleViewPurchaseHistoryClick = () => {
    const { history, _toggleModal } = this.props;
    _toggleModal(VCARD_MODAL, false);
    history.push('/dashboard/purchaseHistory');
  };

  getButtons() {
    const {
      currentUser: { cardStatus },
      classes,
    } = this.props;

    if (cardStatus === 'active') {
      const {
        product: { affiliateLinks },
      } = this.props;
      const keys = Object.keys(affiliateLinks);
      return keys.map(key => (
        <Button
          className={classes.redirectButton}
          variant='contained'
          color='secondary'
          target='_blank'
          href={affiliateLinks[key]}
        >
          <img className={classes.buttonIcon} src={this.getMerchantIcon(key)} alt={key} />
          {l10n(`Continue to ${this.getMerchantName(key)}`)}
        </Button>
      ));
    }
    return (
      <Button
        className={classes.redirectButton}
        variant='contained'
        color='secondary'
        onClick={this.handleViewPurchaseHistoryClick}
      >
        <Typography className={classes.purchaseHistoryText} color='primary'>
          {l10n('View Purchase History')}
        </Typography>
      </Button>
    );
  }

  getMerchantIcon(key) {
    const names = {
      walmart,
      amazon,
      audible,
      cultureWorks,
      udemy,
    };
    return names[key];
  }

  render = () => {
    const {
      classes,
      card,
      product,
      currentUser: { cardStatus },
    } = this.props;

    const isLoading = !card.number;
    const isActive = cardStatus === 'active';
    let headerText = isLoading
      ? "Hold tight, we're powering up your virtual card"
      : 'Make sure to use your Brinq Virtual Card at checkout';
    headerText = isActive ? headerText : 'Virtual Card Frozen';

    return (
      <Grid className={classes.modalContainer} container justify='center' alignItems='center'>
        <Grid
          item
          container
          className={classes.cardContainer}
          justify='space-between'
          direction='column'
          alignItems='center'
        >
          <Typography
            variant='h4'
            className={classnames({
              [classes.modalTitle]: isActive,
              [classes.modalTitleSuspended]: !isActive,
            })}
          >
            {l10n(headerText)}
          </Typography>
          <Typography variant='h4' className={classes.modalSubtitle}>
            {!isActive &&
              l10n(
                'Your Brinq Card has been temporarily frozen. Please ensure that you have all receipts submitted. If you aren’t missing receipts, contact your manager.',
              )}
          </Typography>
          {product && product.promo && (
            <div className={classes.promoContainer}>
              <Typography variant='body1' className={classes.promoCode}>
                {l10n('Use promo code ')}
                <span className={classes.promoAmount}>{product.promo}</span>
                {l10n(' at checkout for an exclusive discount')}
              </Typography>
            </div>
          )}
          <Grid item container justify='space-evenly' className={classes.cards}>
            <Card isActive={isActive} isLoading={isLoading} classes={classes} card={card} />
            <CardBack isActive={isActive} isLoading={isLoading} classes={classes} card={card} />
          </Grid>
          {(product || !isActive) && (
            <Grid item className={classes.redirectButtonContainer}>
              {this.getButtons()}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  };
}

const formatExp = exp => {
  let [month, year] = exp.split('/');
  if (!month || !year) return '';
  if (month.length === 1) {
    month = `0${month}`;
  }
  year = year.substring(2);
  return `${month}/${year}`;
};

const Card = ({ classes, card, isLoading, isActive }) => (
  <Grid item container className={classnames(classes.card, { [classes.opacity]: !isActive })}>
    <Grid item container className={classes.titleBar} justify='space-between' alignItems='center'>
      <Typography variant='h6' className={classes.title}>
        {l10n('VIRTUAL PREPAID CARD')}
      </Typography>
      <img alt='Brinq logo' src={Logo} width={89} height={54} />
    </Grid>
    <Grid item container className={classes.numberContainer} direction='column'>
      <Typography variant='h6' className={classes.subtitle}>
        {l10n('Account Number')}
      </Typography>
      <Grid item container alignItems='center'>
        <div variant='h6' id='virtualCardModal-cardNumber' className={classes.cardNumber}>
          {isLoading || !isActive ? (
            <Bar width={350} />
          ) : (
            <CCNumber classes={classes} number={card.number} />
          )}
        </div>
        {!isLoading && isActive && (
          <Copy classes={classes} selector='virtualCardModal-cardNumber' />
        )}
      </Grid>
    </Grid>
    <Grid item container className={classes.detailsContainer} justify='space-between' wrap='nowrap'>
      <Grid item>
        <Typography variant='h6' className={classes.subtitle}>
          {l10n('Cardholder')}
        </Typography>
        <Grid item container alignItems='center'>
          <div variant='h6' id='virtualCardModal-cardHolder' className={classes.cardDetail}>
            {isLoading || !isActive ? <Bar width={160} /> : card.cardholder}
          </div>
          {!isLoading && isActive && (
            <Copy classes={classes} selector='virtualCardModal-cardHolder' />
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='h6' className={classes.subtitle}>
          {l10n('Expiration')}
        </Typography>
        <Grid item container alignItems='center'>
          <div variant='h6' id='virtualCardModal-expiration' className={classes.cardDetail}>
            {isLoading || !isActive ? <Bar width={80} /> : formatExp(card.expiration)}
          </div>
          {!isLoading && isActive && (
            <Copy classes={classes} selector='virtualCardModal-expiration' />
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='h6' className={classes.subtitle}>
          {l10n('CVC')}
        </Typography>
        <Grid item container alignItems='center'>
          <div variant='h6' id='virtualCardModal-cvc' className={classes.cardDetail}>
            {isLoading || !isActive ? <Bar width={80} /> : card.cvc}
          </div>
          {!isLoading && isActive && <Copy classes={classes} selector='virtualCardModal-cvc' />}
        </Grid>
      </Grid>
    </Grid>
    <Grid item container justify='flex-end'>
      <img alt='Visa logo' src={Visa} width={70} height={23} />
    </Grid>
  </Grid>
);

const CardBack = ({ isLoading, isActive, classes, card }) => (
  <Grid item container className={classnames(classes.card, { [classes.opacity]: !isActive })}>
    <div className={classes.strip} />
    <BillingAddress isActive={isActive} isLoading={isLoading} classes={classes} card={card} />
  </Grid>
);

const CCNumber = ({ classes, number }) => (
  <Fragment>
    <span className={classes.numGroup}>{number.slice(0, 4)}</span>
    <span className={classes.numGroup}>{number.slice(4, 8)}</span>
    <span className={classes.numGroup}>{number.slice(8, 12)}</span>
    <span>{number.slice(12, 16)}</span>
  </Fragment>
);

const BillingAddress = ({ isActive, isLoading, classes, card }) => (
  <Grid container>
    <Grid item container className={classes.numberContainer} direction='column'>
      <Typography variant='h6' className={classes.subtitle}>
        {l10n('Address')}
      </Typography>
      <Grid item container alignItems='center'>
        <div variant='h6' id='virtualCardModal-address1' className={classes.cardDetail}>
          {isLoading || !isActive ? <Bar width={300} /> : card.address1}
        </div>
        {!isLoading && isActive && <Copy classes={classes} selector='virtualCardModal-address1' />}
      </Grid>
    </Grid>
    <Grid item container className={classes.numberContainer} justify='space-between'>
      <Grid item>
        <Typography variant='h6' className={classes.subtitle}>
          {l10n('City')}
        </Typography>
        <Grid item container alignItems='center'>
          <div variant='h6' id='virtualCardModal-city' className={classes.cardDetail}>
            {isLoading || !isActive ? <Bar width={100} /> : _.capitalize(card.city)}
          </div>
          {!isLoading && isActive && <Copy classes={classes} selector='virtualCardModal-city' />}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='h6' className={classes.subtitle}>
          {l10n('State')}
        </Typography>
        <Grid item container alignItems='center'>
          <div variant='h6' id='virtualCardModal-state' className={classes.cardDetail}>
            {isLoading || !isActive ? <Bar width={80} /> : card.state}
          </div>
          {!isLoading && isActive && <Copy classes={classes} selector='virtualCardModal-state' />}
        </Grid>
      </Grid>
      <Grid item>
        <Typography variant='h6' className={classes.subtitle}>
          {l10n('Zip Code')}
        </Typography>
        <Grid item container alignItems='center'>
          <div variant='h6' id='virtualCardModal-zip' className={classes.cardDetail}>
            {isLoading || !isActive ? <Bar width={100} /> : card.zip}
          </div>
          {!isLoading && isActive && <Copy classes={classes} selector='virtualCardModal-zip' />}
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const barStyles = () => ({
  bar: {
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    opacity: 0.5,
    height: 30,
  },
});

const BarComponent = ({ classes, width, firstBar = 9, secondBar = 3 }) => {
  return (
    <div style={{ width: `${width}px` }}>
      <Grid container justify='space-between'>
        <Grid style={{ width: `${width - 35}px` }} item>
          <div className={classes.bar} />
        </Grid>
        <Grid style={{ width: '25px' }} item>
          <div className={classes.bar} />
        </Grid>
      </Grid>
    </div>
  );
};

const Bar = withStyles(barStyles)(BarComponent);

BarComponent.propTypes = {
  classes: PropTypes.object,
  width: PropTypes.number,
  firstBar: PropTypes.number,
  secondBar: PropTypes.number,
};

Card.propTypes = {
  classes: PropTypes.object,
  card: PropTypes.shape({
    number: PropTypes.string,
    cardholder: PropTypes.string,
    expiration: PropTypes.string,
    cvc: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

CardBack.propTypes = {
  classes: PropTypes.object,
  card: PropTypes.shape({
    number: PropTypes.string,
    cardholder: PropTypes.string,
    expiration: PropTypes.string,
    cvc: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

VirtualCardModal.propTypes = {
  classes: PropTypes.object,
  card: PropTypes.shape({
    number: PropTypes.string,
    cardholder: PropTypes.string,
    expiration: PropTypes.string,
    cvc: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  _retrieveVirtualCard: PropTypes.func,
  _toggleModal: PropTypes.func,
  currentUser: PropTypes.object,
  product: PropTypes.shape({
    affiliateLinks: PropTypes.object,
    promo: PropTypes.string,
  }),
};

BillingAddress.propTypes = {
  classes: PropTypes.object,
  card: PropTypes.shape({
    number: PropTypes.string,
    cardholder: PropTypes.string,
    expiration: PropTypes.string,
    cvc: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
};

CCNumber.propTypes = {
  classes: PropTypes.object,
  number: PropTypes.string,
};

const mapStateToProps = state => ({
  card: state.virtualCard,
  currentUser: state.currentUser,
  product: state.shop.product,
});

const mapDispatchToProps = dispatch => ({
  _retrieveVirtualCard: user => dispatch(retrieveVirtualCard(user)),
  _toggleModal: (type, state) => dispatch(toggleModal(type, state)),
});

const Copy = ({ classes, selector }) => (
  <div data-clipboard-target={`#${selector}`} className={`${classes.copy} clippy`}>
    Copy
  </div>
);

Copy.propTypes = {
  classes: PropTypes.object,
  selector: PropTypes.string,
};

export default withRouter(
  withStyles(styles)(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(VirtualCardModal),
  ),
);
