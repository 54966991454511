import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import ReceiptImg from '../../assets/ReceiptImage.png';
import PurchaseHistoryImg from '../../assets/Group_551.png';
import UploadReceiptImg from '../../assets/Group_552.png';

const marginTopFactor = 5;

const styles = theme => ({
  header: {
    color: '#1D6FDD',
    fontFamily: 'Roboto',
    fontSize: 45,
    fontWeight: 800,
  },
  text: {
    marginTop: theme.spacing.unit * marginTopFactor,
    fontFamily: 'Roboto',
    fontSize: 25,
    fontWeight: 800,
  },
  img: {
    marginTop: theme.spacing.unit * marginTopFactor,
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

const UploadReceiptPage = ({ classes }) => (
  <Fragment>
    <Typography className={classes.header} variant='h4'>
      Upload Receipt
    </Typography>
    <Typography className={classes.text} variant='h6'>
      Step 1: Take a screenshot of the receipt (email) you received from our partner site (PC: Alt +
      Print Screen) Mac(Shift + Command + 4)
    </Typography>
    <Typography variant='body1'>
      On Mac, the screenshot will automatically be saved to your desktop. On PC, you will need to
      open a new file in Microsoft Paint and press CTRL + V to paste the screenshot to the page - at
      this point you can save the file to your destop as a .PNG/.JPG/.BMP to upload to Brinq. The
      screenshot should feature our name, the title of the item you purchased, and purchase price.
    </Typography>
    <img className={classes.img} src={ReceiptImg} alt='receipt' />
    <Typography className={classes.text} variant='h6'>
      Step 2: From your Brinq Dashboard, find the item you bought and click "upload"
    </Typography>
    <Typography variant='body1'>
      You can locate your dashboard by clicking "dashboard" in the top left of any screen.
    </Typography>
    <img className={classes.img} src={PurchaseHistoryImg} alt='virtual card' />
    <Typography className={classes.text} variant='h6'>
      Step 3: Drag your saved image or browse our computer to find it
    </Typography>
    <Typography variant='body1'>
      Make sure you capture the date, purchase amount, and title of the item you purchased, then
      click upload.
    </Typography>
    <img className={classes.img} src={UploadReceiptImg} alt='virtual card' />
  </Fragment>
);

UploadReceiptPage.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(UploadReceiptPage);
