import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  navButton: {
    fontSize: 16,
    textTransform: 'none',
    fontFamily: 'Roboto',
    color: '#707070',
    opacity: 0.8,
    borderRadius: 0,
    lineHeight: '80px',
    margin: '-1px 0',
    padding: '0 16px',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
  },
  active: {
    borderBottom: '2px solid #EE6352',
    color: '#000000',
  },
});

const NavButton = ({ classes, active, onClick, children, id }) => (
  <ButtonBase
    focusRipple
    className={`${classes.navButton} ${active && classes.active}`}
    focusVisibleClassName={classes.focusVisible}
    onClick={onClick}
    id={id}
  >
    {children}
  </ButtonBase>
);

NavButton.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default withStyles(styles)(NavButton);
