import axios from 'axios';
import uuid from 'uuid-random';
import { NotificationManager } from 'react-notifications';

export const HANDLE_NEXT = 'HANDLE_NEXT';
export const HANDLE_PREVIOUS = 'HANDLE_PREVIOUS';
export const HANDLE_CARD_CHANGE = 'HANDLE_CARD_CHANGE';
export const HANDLE_TEAM_CHANGE = 'HANDLE_TEAM_CHANGE';
export const HANDLE_REQUIRED_CHANGE = 'HANDLE_REQUIRED_CHANGE';

// eslint-disable-next-line
export const handleNext = step => (dispatch, getState, { getFirestore, getFirebase }) => {
  const payload = { step: step + 1 };
  dispatch({ type: HANDLE_NEXT, payload });
};

// eslint-disable-next-line
export const handlePrevious = step => (dispatch, getState, { getFirestore, getFirebase }) => {
  const payload = { step: step - 1 };
  dispatch({ type: HANDLE_PREVIOUS, payload });
};

// eslint-disable-next-line
export const handleCardChange = card => (dispatch, getState, { getFirestore, getFirebase }) => {
  const payload = { card };
  dispatch({ type: HANDLE_CARD_CHANGE, payload });
};

// eslint-disable-next-line
export const handleTeamChange = team => dispatch => {
  const payload = { team };
  dispatch({ type: HANDLE_TEAM_CHANGE, payload });
};

export const saveAccountDetails = (card, id, team) => async (
  dispatch,
  getState,
  { getFirestore },
) => {
  const [expMonth, expYear] = card.expiration.split('/');
  const idempotencyKey = uuid();
  const db = getFirestore();

  db.collection('users')
    .doc(id)
    .update({
      billingAddress: {
        city: card.city,
        address1: card.address1,
        address2: card.address2,
        state: card.state,
        zip: card.zip,
      },
    });

  // eslint-disable-next-line
  Stripe.setPublishableKey(process.env.REACT_APP_STRIPE_KEY);

  const tokenCard = {
    number: card.cardNumber,
    exp_month: expMonth,
    exp_year: expYear,
    cvc: card.ccCode,
    address_city: card.city,
    address_country: 'US',
    address_line1: card.address1,
    address_line2: card.address2,
    address_state: card.state,
    address_zip: card.zip,
    name: card.cardholder,
  };

  // eslint-disable-next-line
  Stripe.card.createToken(tokenCard, async (status, res) => {
    if (res.error) {
      return res.error;
    }
    await axios.post(`${process.env.REACT_APP_FIREBASE_ENV_PATH}/finalizeSignup`, {
      businessName: card.businessName,
      token: res.id,
      userId: id,
      subscription: team.budget,
      users: team.emails.split(',').length,
      idempotencyKey,
    });
  });
};

export const inviteTeamMembers = (team, mgr, isSetupAccount = true) => (
  dispatch,
  getState,
  { getFirestore },
) => {
  const {
    currentUser: { stripeId },
  } = getState();
  const db = getFirestore();
  const managerId = mgr.id;
  const emails = team.emails.split(',');
  const budget = team.budget.includes('$')
    ? parseInt(team.budget.slice(1), 10)
    : parseInt(team.budget, 10);
  const inviteUserRef = db.collection('inviteUsers');
  const userRef = db.collection('users');

  const promises = emails.map(async email => {
    const trimmedEmail = email.trim();
    if (trimmedEmail.length === 0) {
      return Promise.resolve();
    }

    const hasEmail = await userRef
      .where('email', '==', trimmedEmail)
      .get()
      .then(res => res);

    if (hasEmail.empty) {
      inviteUserRef.doc(trimmedEmail).delete(); // delete the doc first to trigger the cloud func onCreate
      return inviteUserRef.doc(trimmedEmail).set({
        email: trimmedEmail,
        managerId,
        budget,
        remainingBudget: 0,
      });
    }
  });

  if (!isSetupAccount) {
    const axiosPromise = axios({
      method: 'post',
      url: `${process.env.REACT_APP_FIREBASE_ENV_PATH}/addEmployeesToSubscription`,
      data: {
        emails,
        customer: stripeId,
        amount: budget,
      },
    });

    promises.push(axiosPromise);
  }

  return Promise.all(promises)
    .then(() => NotificationManager.success('Employee invited successfully'))
    .catch(err => NotificationManager.error(`Error on inviting employee: ${err}`));
};
