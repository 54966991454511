import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import EmailImage from '../../assets/envelope.png';

import { sendEmailVerification } from '../../store/actions/authActions';

const styles = () => ({
  paperVerifier: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 30,
    height: 100,
    marginBottom: 20,
  },
  paperVerifier_imagetext: {
    display: 'flex',
    alignItems: 'center',
  },
  paperVerifier_title: {
    fontFamily: 'Open Sans',
    fontSize: 17,
    marginLeft: 20,
  },
});

class VerifyEmailPanel extends Component {
  static propTypes = {
    classes: PropTypes.object,
    verify: PropTypes.func,
  };

  static defaultProps = {
    verify: () => {},
  };

  handleResendEmail = () => {
    const { verify } = this.props;
    verify();
  };

  render = () => {
    const { classes } = this.props;

    return (
      <Paper elevation={0} className={classes.paperVerifier}>
        <section className={classes.paperVerifier_imagetext}>
          <img height={50} width={50} src={EmailImage} alt='email' />
          <Typography variant='subtitle1' className={classes.paperVerifier_title}>
            Your Email is not verified. Verify it now to unlock features
          </Typography>
        </section>
        <Button color='primary'>
          {/*  TODO: update email verification sent by this button*/}
          RESEND EMAIL
        </Button>
      </Paper>
    );
  };
}

const mapDispatchToProps = dispatch => ({ verify: () => dispatch(sendEmailVerification()) });

export default compose(connect(null, mapDispatchToProps), withStyles(styles))(VerifyEmailPanel);
