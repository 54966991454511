import {
  EMPLOYEE_LOAD,
  PURCHASEHISTORY_LOAD,
  REQUESTCLASS_LOAD,
  SET_EDIT_USER,
  DELETE_EMPLOYEES,
  REALLOCATE_DELETED_EMPLOYEE_FUNDS,
  RESET_MANAGER_ACTION_STATUS,
  CHANGE_ACTION_STATUS,
  GET_CUSTOMER,
  UPDATE_MANAGER_SUCCESS,
} from '../actions/managerActions';

const initialState = {
  employees: [],
  purchaseHistory: [],
  requestClass: [],
  editUser: {},
  sources: [],
  defaultSource: null,
  status: '',
};

const managerReducer = (state = initialState, action) => {
  let _state;
  switch (action.type) {
    case EMPLOYEE_LOAD:
      _state = { ...state };
      _state.employees = action.payload.employees;
      return _state;
    case DELETE_EMPLOYEES:
      _state = { ...state };
      _state.employees = _state.employees.map(em => ({
        ...em,
        status: action.payload.users.find(user => em.id === user.id) ? 'deleted' : em.status,
      }));
      _state.status = action.payload.status;
      return _state;
    case REALLOCATE_DELETED_EMPLOYEE_FUNDS:
      _state = { ...state };
      _state.employees = action.payload.users;
      _state.status = action.payload.status;
      return _state;
    case PURCHASEHISTORY_LOAD:
      _state = { ...state };
      _state.purchaseHistory = action.payload.purchaseHistory;
      return _state;
    case REQUESTCLASS_LOAD:
      _state = { ...state };
      _state.requestClass = action.payload.requestClass;
      return _state;
    case SET_EDIT_USER:
      _state = { ...state };
      _state.editUser = action.payload;
      return _state;
    case CHANGE_ACTION_STATUS:
      _state = { ...state };
      _state.status = action.payload.status;
      return _state;
    case RESET_MANAGER_ACTION_STATUS:
      _state = { ...state };
      _state.status = '';
      return _state;
    case GET_CUSTOMER:
      _state = { ...state };
      _state.sources = action.payload.sources;
      _state.defaultSource = action.payload.defaultSource;
      return _state;
    case UPDATE_MANAGER_SUCCESS:
      _state = { ...state, ...action.payload };
      return _state;
    default:
      return state;
  }
};

export default managerReducer;
