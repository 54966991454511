import React from 'react';
import { compose } from 'redux';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import PropTypes from 'prop-types';

import l10n from '../../services/l10n';

const styles = () => ({
  footer: {
    paddingTop: 50,
    backgroundColor: '#474747',
  },
});

const Footer = ({ classes, width }) => (
  <Grid
    className={classes.footer}
    container
    direction='column'
    justify='center'
    alignItems='center'
  >
    <Grid item>
      <Grid container direction={width === 'sm' || width === 'sm' ? 'column' : 'row'}>
        <Grid item>
          <FooterModule
            title='Terms and Conditions'
            links={[
              { text: 'Terms of Use', href: '/terms' },
              { text: 'Privacy Policy', href: '/privacy' },
            ]}
          />
        </Grid>
        <Grid item>
          <FooterModule
            title='Support'
            links={[
              { text: 'Pricing', href: '/pricing' },
              { text: 'How Brinq Works', href: '/howitworks' },
              { text: 'FAQS', href: '/faqs' },
              { text: 'Contact Us', href: '/support' },
            ]}
          />
        </Grid>
        <Grid item>
          <FooterModule
            title='Stay Connected'
            links={[
              { text: 'Facebook', href: 'https://www.facebook.com/Brinqskills/' },
              { text: 'Blog', href: 'https://blog.thebrinq.com/' },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <FooterModule title='@2018 The Brinq. All Rights Reserved.' />
    </Grid>
  </Grid>
);

Footer.propTypes = {
  classes: PropTypes.object,
};

const _FooterModule = ({ classes, links = [], title }) => (
  <Grid className={classes.footerModule} container direction='column'>
    <Grid item>
      <Typography className={classes.footer_sectionTitle}>{title}</Typography>
    </Grid>
    {links.map(link => (
      <Grid item key={link.href}>
        <Typography component='a' href={link.href} className={classes.footer_sectionText}>
          {l10n(link.text)}
        </Typography>
      </Grid>
    ))}
  </Grid>
);

_FooterModule.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
};

const FooterModuleStyles = () => ({
  footerModule: {
    padding: '20px 120px',
  },
  footer_sectionTitle: {
    fontSize: 17,
    color: '#FFFFFF',
    fontWeight: 'bold',
    marginTop: 7,
    marginBottom: 7,
  },
  footer_sectionText: {
    fontSize: 14,
    fontFamily: 'Open Sans',
    color: '#FFFFFF',
    marginTop: 7,
    marginBottom: 7,
  },
});

const FooterModule = withStyles(FooterModuleStyles)(_FooterModule);

export default compose(
  withStyles(styles),
  withWidth(),
)(Footer);
