import { NotificationManager } from 'react-notifications';

export const SEND_MESSAGE_REQUEST = 'SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE';

export const sendMessage = values => (dispatch, getState, { getFirestore }) => {
  dispatch({ type: SEND_MESSAGE_REQUEST });
  const db = getFirestore();

  db.collection('/messages')
    .add({
      ...values,
    })
    .then(() => {
      dispatch({ type: SEND_MESSAGE_SUCCESS });
      NotificationManager.success('Message sent successfully!');
    })
    .catch(error => {
      dispatch({ type: SEND_MESSAGE_FAILURE });
      NotificationManager.error(`Error in sending message: ${error}`);
    });
};
