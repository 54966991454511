import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const LineDivider = ({ style }) => (
  <Grid container item style={style}>
    <Divider style={{ flex: 1 }} />
  </Grid>
);

LineDivider.propTypes = {
  style: PropTypes.object,
};

export default LineDivider;
