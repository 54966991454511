import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { NotificationManager } from 'react-notifications';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import l10n from '../../services/l10n';
import { validateFields } from '../../services/Validation';
import { signUp } from '../../store/actions/authActions';
import Header from '../LandingPage/Header';
import Logo from '../../assets/header_logo.png';
import FooterQuote from '../components/FooterQuote';
import PasswordInput from '../components/PasswordInput';

const styles = theme => ({
  form: {
    margin: '1em auto 0',
    maxWidth: 500,
  },
  formControl: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 30,
    },
  },
  formButton: {
    marginTop: 30,
  },
  terms: {
    color: '#ee6352',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: 15,
  },
  container: {
    flex: 1,
  },
});

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        company: '',
      },
      required: {
        firstName: false,
        lastName: false,
        email: false,
        password: false,
      },
    };
    this.seed = Math.random();
  }

  get formValid() {
    const { user } = this.state;
    return (
      user.firstName &&
      user.firstName.length > 0 &&
      user.firstName.trim().length > 0 &&
      user.lastName &&
      user.lastName.length > 0 &&
      user.lastName.trim().length > 0 &&
      user.email &&
      user.email.length > 0 &&
      user.email.trim().length > 0 &&
      user.password &&
      user.password.length > 0 &&
      user.password.trim().length > 0
    );
  }

  onChange = e => {
    const { user } = this.state;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  onSubmit = async () => {
    const { _signUp } = this.props;
    const { user, required } = this.state;
    const fields = validateFields(user, required);

    if (fields.error) {
      this.setState({ required: fields });
    } else {
      user.isManager = true;
      _signUp(user);
    }
  };

  componentWillReceiveProps(nextProps) {
    const { auth, history } = nextProps;

    if (auth.error) NotificationManager.error(auth.message, auth.title);
    if (auth.success) {
      history.push('/verifyEmail');
    }
  }

  render() {
    const { classes, width } = this.props;
    const { user, required } = this.state;
    const isMobile = width === 'xs';

    return (
      <DocumentTitle title='Registration'>
        <Grid
          className={classes.container}
          container
          direction='column'
          justify={isMobile ? 'center' : 'space-between'}
        >
          {isMobile && (
            <Grid item>
              <Header isStatic />
            </Grid>
          )}
          <Grid item container justify='center' alignItems='center'>
            {!isMobile && (
              <Grid item container xs={10} justify='space-between' alignItems='center'>
                <Grid item>
                  <img src={Logo} height={112} alt='Brinq logo' />
                </Grid>
                <Grid item>
                  {l10n('Have an account?')}{' '}
                  <Button href='/login' size='large' className={classes.button}>
                    {l10n('Login')}
                  </Button>
                </Grid>
              </Grid>
            )}
            <Grid xs={10} item>
              <form className={classes.form} noValidate autoComplete='off'>
                {!isMobile && (
                  <Fragment>
                    <Typography variant='h2' className={classes.title} gutterBottom>
                      {l10n('Sign up')}
                    </Typography>
                  </Fragment>
                )}
                <TextField
                  fullWidth
                  required
                  name='firstName'
                  label='First Name'
                  value={user.firstName}
                  onChange={this.onChange}
                  margin='normal'
                  error={required.firstName}
                />
                <TextField
                  fullWidth
                  required
                  name='lastName'
                  label='Last Name'
                  value={user.lastName}
                  onChange={this.onChange}
                  margin='normal'
                  error={required.lastName}
                />
                <TextField
                  fullWidth
                  required
                  name='email'
                  label='Work Email'
                  value={user.email}
                  onChange={this.onChange}
                  margin='normal'
                  error={required.email}
                />
                <PasswordInput
                  fullWidth
                  required
                  name='password'
                  label='Password'
                  onChange={this.onChange}
                  margin='normal'
                  error={required.password}
                />
                <TextField
                  fullWidth
                  required
                  name='company'
                  label='Company Name'
                  value={user.company}
                  onChange={this.onChange}
                  margin='normal'
                  error={required.company}
                />
                <FormControl className={classes.formButton} fullWidth>
                  <Button
                    variant='contained'
                    color='primary'
                    disabled={!this.formValid}
                    onClick={this.onSubmit}
                  >
                    {l10n('Create Account')}
                  </Button>
                </FormControl>
              </form>
            </Grid>
            <Grid xs={11} item>
              <FormControl className={classes.formControl} fullWidth>
                <Typography variant='body2' gutterBottom align='center'>
                  {l10n('By signing up you agree to Brinq')}
                  <a href='/terms' className={classes.terms}>
                    {l10n('Terms of Use')}
                  </a>
                  {l10n('and')}
                  <a href='/privacy' className={classes.terms}>
                    {l10n('Privacy Policy')}
                  </a>
                </Typography>
              </FormControl>
            </Grid>
          </Grid>
          {!isMobile && <FooterQuote seed={this.seed} />}
        </Grid>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
});

const mapDispatchToProps = dispatch => ({
  _signUp: user => dispatch(signUp(user)),
});

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  _signUp: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
  withWidth(),
)(Register);
