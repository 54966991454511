import React from 'react';
import PropTypes from 'prop-types';
import Joyride, { EVENTS } from 'react-joyride';

const empSteps = [
  {
    target: 'body',
    placement: 'center',
    content:
      "We're happy to have you aboard. Let's take a look at a few things that will help you navigate Brinq.",
    title: 'Welcome to Brinq!',
    hideCloseButton: true,
  },
  {
    target: '#Header-Shop',
    placement: 'auto',
    disableBeacon: true,
    content: 'Use your budget here to purchase learning materials and improve your skills.',
    title: 'Shop the Brinq Marketplace',
    offset: -10,
    hideCloseButton: true,
  },
  {
    target: '#Header-VirtualCardButton',
    placement: 'bottom',
    disableBeacon: true,
    content:
      'This amount indicates how much money you have left in your budget. Click on the virtual card icon to copy credit card information to use on partner sites.',
    title: 'Use Your Brinq Prepaid Virtual Card',
    offset: -10,
    hideCloseButton: true,
  },
  {
    target: '#SideNav-purchasehistory',
    placement: 'right',
    disableBeacon: true,
    content:
      'Here you can access a history of yours purchases as well as validate transactions with any receipts you have received.',
    title: 'Review your purchase history',
    offset: -10,
    hideCloseButton: true,
    locale: { last: 'Done' },
  },
];

const mgrSteps = [
  {
    target: 'body',
    placement: 'center',
    content: "Let's get started with a few features that will help to develop your employees.",
    title: 'Welcome to Brinq!',
    hideCloseButton: true,
  },
  {
    target: '#SideNav-employees',
    placement: 'right',
    disableBeacon: true,
    content: 'Click here to add new employees or make adjustments to their budgets.',
    title: 'Manage Your Employees',
    offset: -10,
    hideCloseButton: true,
  },
  {
    target: '#SideNav-purchasehistory',
    placement: 'right',
    disableBeacon: true,
    content: 'Stay up-to-date on the purchases your employees are making.',
    title: 'Review Employee Purchases',
    offset: -10,
    hideCloseButton: true,
  },
  {
    target: '#SideNav-creditCard',
    placement: 'right',
    disableBeacon: true,
    content:
      'Review, add, or remove credit cards on file to ensure your employees have have access to learning materials.',
    title: 'Manage your funding source',
    offset: -10,
    hideCloseButton: true,
  },
  {
    target: '#Header-Shop',
    placement: 'auto',
    disableBeacon: true,
    content:
      'Click Shop to review the learning and development materials available to your employees.',
    title: 'Access the Brinq Marketplace',
    offset: -10,
    hideCloseButton: true,
    locale: { last: 'Done' },
  },
];

const onChange = (type, onFinish) => {
  if (type === EVENTS.TOUR_END) {
    onFinish();
  }
};

const Walkthrough = ({ isManager, onFinish, open }) => {
  const steps = isManager ? mgrSteps : empSteps;
  if (!open) return <noscript />;
  return (
    <Joyride
      disableOverlay
      showSkipButton
      showProgress
      hideCloseButton
      continuous
      steps={steps}
      callback={({ type }) => onChange(type, onFinish)}
      styles={{
        options: {
          arrowColor: '#1492E6',
          backgroundColor: '#1492E6',
          primaryColor: '#fff',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
          textColor: '#fff',
          width: 430,
          zIndex: 2147483647,
        },
        buttonNext: {
          color: '#000',
        },
      }}
    />
  );
};

Walkthrough.propTypes = {
  isManager: PropTypes.bool,
  onFinish: PropTypes.func,
  open: PropTypes.bool,
};

export default Walkthrough;
