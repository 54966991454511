/**
 * Shop Actions
 */

export const shopLoad = () => (dispatch, getState, { getFirestore }) => {
  const merchants = [];
  const firestore = getFirestore();

  dispatch({ type: 'FETCH_REQUEST' });
  firestore
    .collection('merchs')
    .get()
    .then(snapshot => {
      snapshot.forEach(doc => {
        merchants.push({ ...doc.data(), id: doc.id });
      });

      dispatch({ type: 'FETCH_SUCCESS', merchants });
    })
    .catch(error => dispatch({ type: 'FETCH_FAILURE', error }));
};

export const searchShop = query => async dispatch => {
  dispatch({ type: 'SEARCH_REQUEST' });
  const lowerCasedQuery = query.toLowerCase();
  dispatch({ type: 'SEARCH_SUCCESS', payload: lowerCasedQuery });
};
