/* eslint camelcase: 0 */
/* eslint no-case-declarations: 0 */

import { SET_VIRTUAL_CARD } from '../actions/virtualCardActions';

const initState = {
  number: '',
  cardholder: '',
  expiration: '',
  cvc: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
};

const virtualCardReducer = (state = initState, action) => {
  let _state;
  switch (action.type) {
    case SET_VIRTUAL_CARD:
      const { card, number, exp_month, exp_year, cvc } = action.payload;
      _state = {
        number,
        cardholder: card.name,
        expiration: `${exp_month}/${exp_year}`,
        cvc,
        address1: card.cardholder.billing.address.line1,
        address2: card.cardholder.billing.address.line2,
        city: card.cardholder.billing.address.city,
        state: card.cardholder.billing.address.state,
        zip: card.cardholder.billing.address.postal_code,
      };
      return _state;
    default:
      return state;
  }
};

export default virtualCardReducer;
