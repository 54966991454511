import ReactGA from 'react-ga';

class GA {
  constructor(trackingCode) {
    this.trackingCode = trackingCode;
  }

  _isNotDev = () => process.env.NODE_ENV !== 'development';

  initGa = () => this._isNotDev() && ReactGA.initialize(this.trackingCode);

  pageView = () =>
    this._isNotDev() && ReactGA.pageview(`${window.location.pathname}${window.location.search}`);

  trackEvent = (category, action, label) =>
    this._isNotDev() && ReactGA.event({ category, action, label });

  setAttribute = attr => this._isNotDev() && ReactGA.set(attr);
}

const GAInstance = new GA('UA-141106117-1');

export default GAInstance;
