const initState = {
  skillsIdx: null,
  enter: false,
};

const skillsCarouselReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FADE_OUT':
      return {
        ...state,
        enter: action.enter,
      };
    case 'SKILL_CHANGED':
      return {
        ...state,
        enter: action.enter,
        skillsIdx: action.skillsIdx,
      };
    default:
      return state;
  }
};

export default skillsCarouselReducer;
