import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import CreateNewTeamImg from '../../assets/Group_540.png';
import RegisterTeamImg from '../../assets/Group_541.png';
import InviteTeamImg from '../../assets/Group_542.png';
import AddCreditCardImg from '../../assets/Group_543.png';
import InviteEmailImg from '../../assets/Group_544.png';
import CreateAccountImg from '../../assets/Group_545.png';
import ConfirmEmail from '../../assets/confirm_email.png';

const marginTopFactor = 5;

const styles = theme => ({
  tabs: {
    borderBottom: '1px solid #e8e8e8',
    marginTop: theme.spacing.unit * marginTopFactor,
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: theme.spacing.unit * marginTopFactor,
  },
  header: {
    color: '#1D6FDD',
    fontFamily: 'Roboto',
    fontSize: 45,
    fontWeight: 800,
  },
  subheader: {
    marginTop: theme.spacing.unit * marginTopFactor,
    fontFamily: 'Roboto',
    fontSize: 45,
    fontWeight: 800,
  },
  text: {
    marginTop: theme.spacing.unit * marginTopFactor,
    fontFamily: 'Roboto',
    fontSize: 25,
    fontWeight: 800,
  },
  tab: {
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 800,
  },
});

class SetupPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  render = () => {
    const { classes } = this.props;

    return (
      <div>
        <Typography className={classes.header} variant='h4'>
          Setup
        </Typography>
        <ManagerSetup classes={classes} />
        <EmployeeSetup classes={classes} />
      </div>
    );
  };
}

const ManagerSetup = ({ classes }) => (
  <Fragment>
    <Typography className={classes.subheader} variant='h4' component='a' name='manager'>
      Manager
    </Typography>
    <Typography className={classes.text} variant='h6'>
      Step 1: Register your account and verify your email
    </Typography>
    <Typography variant='body1'>
      Be sure to check your spam folder if you don't see the verification email
    </Typography>
    <img className={classes.img} src={CreateNewTeamImg} alt='create new team' />
    <img className={classes.img} src={RegisterTeamImg} alt='register team' />
    <Typography className={classes.text} variant='h6'>
      Step 2: Enter your team email addresses and decide on a monthly budget
    </Typography>
    <Typography variant='body1'>
      These funds rollover every month and can be redeemed at any time
    </Typography>
    <img className={classes.img} src={InviteTeamImg} alt='invite team' />
    <Typography className={classes.text} variant='h6'>
      Step 3: Add your credit card as a funding source
    </Typography>
    <Typography variant='body1'>
      Every dollar you spend goes right to your team's budgets. We don't charge a fee
    </Typography>
    <img className={classes.img} src={AddCreditCardImg} alt='add credit card' />
  </Fragment>
);

ManagerSetup.propTypes = {
  classes: PropTypes.object,
};

const EmployeeSetup = ({ classes }) => (
  <Fragment>
    <Typography className={classes.subheader} variant='h4' component='a' name='employee'>
      Employee
    </Typography>
    <Typography className={classes.text} variant='h6'>
      Step 1: Check your email address for a Brinq invitation from your manager.
    </Typography>
    <Typography variant='body1'>
      Check your spam folder if you don't see your manager's invitation email
    </Typography>
    <img className={classes.img} src={InviteEmailImg} alt='create account' />
    <Typography className={classes.text} variant='h6'>
      Step 2: Enter the email address where you received the Brinq invitation
    </Typography>
    <Typography variant='body1'>
      This will double check that your email matches what your manager gave us.
    </Typography>
    <img className={classes.img} src={ConfirmEmail} alt='create account' />
    <Typography className={classes.text} variant='h6'>
      Step 3: Quickly register your account and verify your email address
    </Typography>
    <Typography variant='body1'>
      Make sure to use the same email address that received the invitation
    </Typography>
    <img className={classes.img} src={CreateAccountImg} alt='create account' />
  </Fragment>
);

EmployeeSetup.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(SetupPage);
