import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

import Header from './LandingPage/Header';

import privacyJson from '../json/privacy';

const styles = theme => ({
  subheader: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 240,
    marginTop: 80,
    backgroundColor: '#eaeaea',
  },
  subheaderTitle: {
    font: 'Roboto Black',
    fontWeight: 800,
  },
  paper: {
    padding: '100px 500px 100px 100px',
    marginBottom: 100,
  },
  content: {
    marginTop: 26,
    paddingRight: 100,
  },
  section: {
    marginBottom: 40,
  },
  sectionTitle: {
    font: 'Roboto Black',
    fontSize: 25,
    fontWeight: 800,
    marginBottom: 40,
  },
  sectionDescription: {
    fontSize: 18,
    font: 'Roboto',
  },
  listItem: {
    fontSize: 18,
    fontWeight: 800,
  },
  brinq: {
    fontWeight: 800,
  },
});

const PrivacyPolicy = ({ classes }) => {
  return (
    <Grid container direction='column'>
      <Grid item>
        <Header />
      </Grid>
      <Grid item>
        <SubHeader classes={classes} />
      </Grid>
      <Grid item>
        <Grid className={classes.content} container direction='row'>
          <Grid item sm={12} md={3} />
          <Grid item sm={12} md={9}>
            <Paper className={classes.paper} elevation={1} square>
              <Typography
                className={classNames(classes.section, classes.sectionDescription)}
                variant='body1'
              >
                <span className={classes.brinq}>Brinq LLC</span> operates the{' '}
                <a href='www.thebrinq.com'>www.thebrinq.com</a> website, which provides the SERVICE.
              </Typography>
              <Typography
                className={classNames(classes.section, classes.sectionDescription)}
                variant='body1'
              >
                This page is used to inform website visitors regarding our policies with the
                collection, use, and disclosure of Personal Information if anyone decided to use our
                Service, the <a href='www.thebrinq.com'>www.thebrinq.com</a> website.
              </Typography>
              <Typography
                className={classNames(classes.section, classes.sectionDescription)}
                variant='body1'
              >
                If you choose to use our Service, then you agree to the collection and use of
                information in relation with this policy. The Personal Information that we collect
                are used for providing and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy Policy.
              </Typography>
              <Typography
                className={classNames(classes.section, classes.sectionDescription)}
                variant='body1'
              >
                The terms used in this Privacy Policy have the same meanings as in our Terms and
                Conditions, which is accessible at <a href='www.thebrinq.com'>www.thebrinq.com</a>,
                unless otherwise defined in this Privacy Policy.
              </Typography>

              {privacyJson.map(item => {
                if (item.title) {
                  return (
                    <div className={classes.section} key={item.descriptions[0]}>
                      <Typography className={classes.sectionTitle} variant='h4'>
                        {item.title}
                      </Typography>
                      {item.descriptions.map(desc => (
                        <Typography
                          className={classes.sectionDescription}
                          variant='body1'
                          key={desc}
                        >
                          {desc}
                        </Typography>
                      ))}
                    </div>
                  );
                }
                if (item.list) {
                  return (
                    <div key={item.list[0]} className={classes.section}>
                      {item.list.map(li => (
                        <Typography
                          className={classes.listItem}
                          key={li}
                          variant='body1'
                        >{`- ${li}`}</Typography>
                      ))}
                    </div>
                  );
                }
                return item.descriptions.map(desc => (
                  <Typography
                    className={classNames(classes.section, classes.sectionDescription)}
                    variant='body1'
                    key={desc}
                  >
                    {desc}
                  </Typography>
                ));
              })}
              <div className={classes.section}>
                <Typography className={classes.sectionTitle} variant='h4'>
                  Contact Us
                </Typography>
                <Typography className={classes.sectionDescription} variant='body1'>
                  If you have any questions or suggestions about our Privacy Policy, do not hesitate
                  to contact us at <a href='mailto:support@thebrinq.com'>support@thebrinq.com</a>
                </Typography>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PrivacyPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
};

const SubHeader = ({ classes }) => (
  <div className={classes.subheader}>
    <Typography className={classes.subheaderTitle} variant='h3'>
      Brinq Privacy Policy
    </Typography>
  </div>
);

SubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrivacyPolicy);
