import React, { Component, Fragment, memo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ViewIcon from '@material-ui/icons/Photo';
import UploadIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import UploadReceiptModal from './UploadReceiptModal';
import SortableTable from './SortableTable';
import l10n from '../../services/l10n';

import { loadPurchaseHistory, selectPurchase } from '../../store/actions/purchaseHistoryActions';

const headers = [
  { id: 'title', label: l10n('Title'), numeric: false, sort: true },
  { id: 'date', label: l10n('Date'), numeric: false, sort: true },
  { id: 'price', label: l10n('Price'), numeric: true, sort: true },
  { id: 'vendor', label: l10n('Vendor'), numeric: false, sort: true },
  { id: 'actions', label: l10n('Status'), numeric: false, sort: false },
];

const managerHeaders = [
  { id: 'name', label: l10n('Name'), numeric: false, sort: true },
  { id: 'title', label: l10n('Title'), numeric: false, sort: true },
  { id: 'date', label: l10n('Date'), numeric: false, sort: true },
  { id: 'price', label: l10n('Price'), numeric: true, sort: true },
  { id: 'vendor', label: l10n('Vendor'), numeric: false, sort: true },
  { id: 'actions', label: l10n('Receipts'), numeric: false, sort: false },
];

const styles = theme => ({
  panelTitle: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  padded: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px ${theme.spacing.unit *
      2}px ${theme.spacing.unit * 4}px`,
  },
  search: {
    marginBottom: 20,
    width: '45%',
  },
  loader: {
    margin: '10% auto 0',
    display: 'block',
  },
  receiptImage: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
});

class PurchaseHistory extends Component {
  state = {
    uploadModalOpen: false,
    viewModalOpen: false,
  };

  componentDidMount() {
    const { load } = this.props;
    load();
  }

  handleClickMore = () => {
    const { history } = this.props;
    history.push('/dashboard/purchasehistory');
  };

  handleReceiptModal = (modal, purchase) => {
    const { select } = this.props;

    select(purchase);
    this.setState({
      [modal]: true,
    });
  };

  closeReceiptModal = modal => {
    this.setState({
      [modal]: false,
    });
  };

  employeeData = doc => ({
    title: {
      value: doc.title || 'Awaiting Receipt',
      content: (
        <Typography color={doc.title ? 'default' : 'primary'} variant='body2'>
          {l10n(`${doc.title || 'Awaiting Receipt'}`)}
        </Typography>
      ),
    },
    date: moment.unix(doc.created).format('MM/DD/YYYY'),
    price: {
      value: doc.authorized_amount,
      content: `$${(doc.authorized_amount / 100).toFixed(2)}`,
    },
    vendor: {
      value: doc.source.toLowerCase(),
      content: doc.source,
    },
    actions: [
      doc.receipt ? (
        <Tooltip key={`${doc.id}-viewreceipttooltip`} title='View Receipt'>
          <IconButton
            aria-label='View'
            onClick={() => this.handleReceiptModal('viewModalOpen', doc)}
          >
            <ViewIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip key={`${doc.id}-uploadreceipttooltip`} title='Upload Receipt'>
          <IconButton
            aria-label='Upload'
            onClick={() => this.handleReceiptModal('uploadModalOpen', doc)}
          >
            <UploadIcon />
          </IconButton>
        </Tooltip>
      ),
    ],
  });

  managerData = doc => ({
    name: doc.card.name,
    title: {
      value: doc.title || 'Awaiting Receipt',
      content: (
        <Typography color={doc.title ? 'default' : 'primary'} variant='body2'>
          {l10n(`${doc.title || 'Awaiting Receipt'}`)}
        </Typography>
      ),
    },
    date: moment.unix(doc.created).format('MM/DD/YYYY'),
    price: {
      value: doc.authorized_amount,
      content: `$${(doc.authorized_amount / 100).toFixed(2)}`,
    },
    vendor: {
      value: doc.source.toLowerCase(),
      content: doc.source,
    },
    actions: [
      doc.receipt ? (
        <Tooltip key={`${doc.id}-viewreceipttooltip`} title='View Receipt'>
          <IconButton
            aria-label='View'
            onClick={() => this.handleReceiptModal('viewModalOpen', doc)}
          >
            <ViewIcon />
          </IconButton>
        </Tooltip>
      ) : (
        l10n('Awaiting Receipt')
      ),
    ],
  });

  renderContent = data => {
    const { tableOnly, limit, isManager } = this.props;
    const showOnly = limit < data.length;
    const _headers = isManager ? managerHeaders : headers;

    return (
      <Fragment>
        <SortableTable
          data={data}
          headers={_headers}
          defaultSortedColumn='date'
          defaultSortOrder='desc'
          enableToolbar={!tableOnly}
          limit={limit}
          toolbar={{
            label: l10n('Search Title'),
            placeholder: l10n('Try Fifth Discipline...'),
            adorments: {
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            },
          }}
        />
        {showOnly && (
          <center>
            <Button fullWidth color='primary' onClick={this.handleClickMore}>
              {l10n('SEE MORE')}
            </Button>
          </center>
        )}
      </Fragment>
    );
  };

  render() {
    const { isLoading, classes, purchases, selected, tableOnly, isManager } = this.props;
    const { uploadModalOpen, viewModalOpen } = this.state;
    const data = purchases.map(isManager ? this.managerData : this.employeeData);
    return (
      <Fragment>
        {!tableOnly && (
          <Typography className={classes.panelTitle} variant='h6'>
            {l10n('PURCHASE HISTORY')}
          </Typography>
        )}
        {isLoading ? (
          <CircularProgress className={classes.loader} size={80} />
        ) : (
          <Paper className={classes.padded} elevation={0}>
            {this.renderContent(data)}
          </Paper>
        )}
        {selected && (
          <ViewReceiptModal
            open={viewModalOpen}
            close={() => this.closeReceiptModal('viewModalOpen')}
            item={selected}
            classes={classes}
          />
        )}
        <UploadReceiptModal
          open={uploadModalOpen}
          close={() => this.closeReceiptModal('uploadModalOpen')}
        />
      </Fragment>
    );
  }
}

const ViewReceiptModal = ({ open, close, item, classes }) => (
  <Dialog
    fullWidth
    maxWidth='md'
    open={open}
    onClose={close}
    aria-labelledby='view-receipt-title'
    aria-describedby='view-receipt-description'
  >
    <DialogContent>
      <Grid container justify='center' alignItems='center'>
        <img alt={item.title} src={item.receipt} className={classes.receiptImage} />
      </Grid>
    </DialogContent>
  </Dialog>
);

PurchaseHistory.propTypes = {
  load: PropTypes.func.isRequired,
  select: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  purchases: PropTypes.array.isRequired,
  selected: PropTypes.object,
  isLoading: PropTypes.bool,
  isManager: PropTypes.bool,
  tableOnly: PropTypes.bool,
  limit: PropTypes.number,
  history: PropTypes.object,
};

ViewReceiptModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  purchases: state.purchase.history,
  selected: state.purchase.selected,
  isLoading: state.purchase.loading,
  isManager: state.purchase.manager,
});

const mapDispatchToProps = dispatch => ({
  load: () => dispatch(loadPurchaseHistory()),
  select: item => dispatch(selectPurchase(item)),
});

export default compose(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(memo(PurchaseHistory));
