import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import l10n from '../../services/l10n';

import Header from '../LandingPage/Header';
import CreateTeamIcon from '../../assets/CreateTeamIcon.svg';
import ExistingTeamIcon from '../../assets/ExistingTeamIcon.svg';
import AarowButton from '../../assets/AarowButton.svg';
import FooterQuote from '../components/FooterQuote';
import LineDivider from '../components/LineDivider';

const styles = () => ({
  content: {
    flex: 1,
  },
  getStarted: {
    marginBottom: 80,
    marginTop: 40,
  },
  option: {
    padding: '24px 16px',
    cursor: 'pointer',
    minHeight: 98,
  },
  optionText: {
    paddingLeft: 44,
  },
  optionTitle: {
    fontSize: 20,
    fontWeight: 500,
  },
  subTitle: {
    fontSize: 15,
    fontFamily: 'Open Sans',
  },
  getStartedTitle: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  logo: {
    marginTop: 20,
  },
});

const seed = Math.random();

const RegisterLanding = ({ classes, history, width }) => {
  return (
    <DocumentTitle title={l10n('Sign Up')}>
      <Grid className={classes.content} container direction='column' justify='space-between'>
        <Grid item>
          <Header isStatic />
        </Grid>
        <Grid item container justify='center'>
          <Grid item container xs={11} md={4} direction='column' alignItems='center' wrap='nowrap'>
            <Grid
              className={classes.getStarted}
              item
              container
              direction='column'
              alignItems='center'
            >
              <Typography className={classes.getStartedTitle} variant='h2'>
                {l10n("Let's get started")}
              </Typography>
            </Grid>
            <LineDivider />
            <Grid
              item
              container
              className={classes.option}
              justify='space-between'
              alignItems='center'
              wrap='nowrap'
              spacing={width === 'xs' ? 8 : 32}
              onClick={() => history.push('/registerManager')}
            >
              <Grid item>
                <img src={CreateTeamIcon} height={48} width={48} alt='Create Team Icon' />
              </Grid>
              <Grid container item direction='column' className={classes.optionText}>
                <Typography variant='h3' className={classes.optionTitle}>
                  {l10n('Create New Team')}
                </Typography>
                <Typography variant='subtitle1' className={classes.subTitle}>
                  {l10n("I'm a manager/owner setting up my team")}
                </Typography>
              </Grid>
              <img src={AarowButton} height={37} width={37} alt='Continue Arrow' />
            </Grid>
            <LineDivider />
            <Grid
              item
              container
              className={classes.option}
              justify='space-between'
              wrap='nowrap'
              alignItems='center'
              spacing={width === 'xs' ? 8 : 32}
              onClick={() => history.push('/registerEmployee')}
            >
              <Grid item>
                <img src={ExistingTeamIcon} height={48} width={48} alt='Existing Team Icon' />
              </Grid>
              <Grid container item direction='column' className={classes.optionText}>
                <Typography variant='h3' className={classes.optionTitle}>
                  {l10n('Join an Existing Team')}
                </Typography>
                <Typography variant='subtitle1' className={classes.subTitle}>
                  {l10n('I was invited by my manager')}
                </Typography>
              </Grid>
              <img src={AarowButton} height={37} width={37} alt='Continue Arrow' />
            </Grid>
            <LineDivider />
          </Grid>
        </Grid>
        <FooterQuote seed={seed} />
      </Grid>
    </DocumentTitle>
  );
};

RegisterLanding.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
};

export default compose(
  withStyles(styles),
  withWidth(),
)(RegisterLanding);
