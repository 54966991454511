import React, { useState, Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';

import PropTypes from 'prop-types';

const styles = () => ({
  icon: {
    marginRight: 5,
  },
  actionButtons: {
    color: '#0061E3',
  },
});

const CustomActions = ({ classes, onAddClick = () => {}, buttons = [], showMoreMenu = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClick(actionName) {
    setAnchorEl(null);
    onAddClick(actionName);
  }

  return (
    <Fragment>
      <Grid container alignItems='flex-end' spacing={16}>
        {buttons.reduce(
          (acc, button) =>
            acc.concat(
              !button.inMenu && button.visible && (
                <Grid key={button.action} item>
                  <Button
                    className={classes.actionButtons}
                    onClick={() => {
                      button.handler(button.value);
                    }}
                  >
                    <Icon className={classes.icon}>{button.icon}</Icon>
                    {button.action.toUpperCase()}
                  </Button>
                </Grid>
              ),
            ),
          [],
        )}
        {showMoreMenu && (
          <Grid item>
            <Button className={classes.actionButtons} onClick={handleOpen}>
              <Icon className={classes.icon}>more_horiz</Icon>
              MORE
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button variant='contained' color='primary' onClick={() => handleClick('add employee')}>
            Add Employee
          </Button>
        </Grid>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        getContentAnchorEl={null}
      >
        {buttons.reduce(
          (acc, button) =>
            acc.concat(
              button.inMenu && button.visible && (
                <MenuItem
                  key={button.action}
                  onClick={() => {
                    handleClose();
                    button.handler(button.value);
                  }}
                  className={classes.actionButtons}
                >
                  <Icon className={classes.icon}>{button.icon}</Icon>
                  {button.action.toUpperCase()}
                </MenuItem>
              ),
            ),
          [],
        )}
      </Menu>
    </Fragment>
  );
};

CustomActions.propTypes = {
  classes: PropTypes.object,
  onAddClick: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
      icon: PropTypes.string.isRequired,
      inMenu: PropTypes.bool,
      handler: PropTypes.func,
      value: PropTypes.any,
    }),
  ).isRequired,
};

export default withStyles(styles)(CustomActions);
