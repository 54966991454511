import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import l10n from '../../services/l10n';
import { deleteEmployees, getEmployees } from '../../store/actions/managerActions';

const styles = () => ({
  container: {
    margin: '13px 23px',
    width: 460,
  },
  title: {
    fontSize: 25,
    fontFamily: 'Roboto',
    fontWeight: 800,
    textAlign: 'center',
    width: 336,
  },
});

class DeleteEmployeeDialog extends Component {
  static propTypes = {
    employees: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    managerId: PropTypes.string,
    deleteEms: PropTypes.func,
    load: PropTypes.func,
    onClose: PropTypes.func,
    classes: PropTypes.object,
  };

  static defaultProps = {
    employees: [],
    onClose: () => {},
  };

  handleDelete = () => {
    const { employees, deleteEms, load, managerId, onClose } = this.props;
    deleteEms(employees);
    load(managerId);
    onClose();
  };

  renderContent = () => {
    const { classes, onClose } = this.props;
    return (
      <div className={classes.container}>
        <DialogTitle id='alert-dialog-title'>
          <center>
            <Typography className={classes.title} color='primary' variant='h6'>
              {l10n('ARE YOU SURE YOU WANT TO DELETE THIS EMPLOYEE?')}
            </Typography>
          </center>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleted users will no longer be able to access to their Brinq account. If you proceed
            with deletion, you will be able to reallocate or redeem any remaining budget.
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onClose}>{l10n('CANCEL')}</Button>
          <Button onClick={this.handleDelete} color='primary' variant='contained' autoFocus>
            {l10n('DELETE')}
          </Button>
        </DialogActions>
      </div>
    );
  };

  render = () => {
    const { onClose, open } = this.props;
    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='sm'
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        {this.renderContent()}
      </Dialog>
    );
  };
}

const mapStateToProps = ({
  firebase: {
    auth: { uid },
  },
}) => ({ managerId: uid });

const mapDispatchToProps = dispatch => ({
  load: id => dispatch(getEmployees(id)),
  deleteEms: users => dispatch(deleteEmployees(users)),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles),
)(DeleteEmployeeDialog);
