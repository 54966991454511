import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import PropTypes from 'prop-types';

const steps = ['Invite team', 'Billing Info'];

const styles = () => ({
  step: {
    marginBottom: 20,
  },
});

const AccountSetupStepper = ({ step, mode = 'desktop', classes }) => {
  const isMobile = mode === 'mobile';
  if (isMobile)
    return (
      <MobileStepper
        className={classes.step}
        variant='dots'
        steps={2}
        position='static'
        activeStep={step}
      />
    );
  return (
    <Stepper activeStep={step}>
      {steps.map(label => {
        const props = {};
        const labelProps = {};
        return (
          <Step key={label} {...props}>
            <StepLabel {...labelProps}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

AccountSetupStepper.propTypes = {
  step: PropTypes.number,
};

export default withStyles(styles)(AccountSetupStepper);
