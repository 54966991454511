import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import { withRouter, Link } from 'react-router-dom';
import withWidth from '@material-ui/core/withWidth';
import DocumentTitle from 'react-document-title';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Banner from './Banner';

import l10n from '../services/l10n';
import db from '../services/Firestore';

import Brinq from '../assets/header_logo.png';

class Subscribe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        name: '',
        email: '',
        company: '',
      },
      formInvalid: false,
    };
  }

  onChange = e => {
    const { user } = this.state;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  validateForm = () => {
    const { user } = this.state;

    return user.name && user.email && user.company;
  };

  onSubmit = async () => {
    const { user } = this.state;
    const { history } = this.props;
    const valid = this.validateForm();

    if (!valid) {
      this.setState({ formInvalid: true });
      return false;
    }

    db.collection('leads')
      .add(user)
      .then(() => {
        NotificationManager.success(
          "Thank you for your interest. We'll be in touch soon!",
          'Interest logged',
          3000,
        );
        setTimeout(() => history.push('/'), 4000);
      });
  };

  render() {
    const { classes, history, width } = this.props;
    const { user, formInvalid } = this.state;

    return (
      <DocumentTitle title='Subscribe'>
        <Grid container>
          <Hidden only='xs'>
            <Banner />
          </Hidden>
          <Grid
            item
            xs={12}
            sm={6}
            container
            justify='center'
            alignItems='flex-start'
            className={classes.formContainer}
          >
            <Grid
              container
              item
              direction='column'
              wrap='nowrap'
              alignItems='flex-start'
              justify='space-evenly'
              className={classes.form}
            >
              <Grid
                item
                container
                justify='space-between'
                alignItems='center'
                className={classes.logoContainer}
              >
                <Link to='/'>
                  <img className={classes.logo} src={Brinq} height='100px' alt='Brinq logo' />
                </Link>
                <Button color='default' onClick={() => history.push('/')}>
                  {l10n('Home')}
                </Button>
              </Grid>
              <Typography variant='h2' className={classes.subscriptionHeading} gutterBottom>
                {l10n("We're Launching Soon!")}
              </Typography>
              <Typography variant='subtitle1' className={classes.subscriptionSubheading}>
                {l10n(
                  'Add your contact info below to join our waitlist. You’ll get access to important updates, freebies, and enhanced customer support. No commitment to sign up when we launch… but we think you’d be crazy not to.',
                )}
              </Typography>
              <form className={classes.form} noValidate autoComplete='off'>
                <TextField
                  margin={`${width === 'xs' ? 'dense' : 'normal'}`}
                  fullWidth
                  required
                  name='name'
                  label={l10n('Name')}
                  value={user.name}
                  onChange={this.onChange}
                  placeholder={l10n('Enter your name')}
                  error={!user.name && formInvalid}
                />
                <TextField
                  margin={`${width === 'xs' ? 'dense' : 'normal'}`}
                  fullWidth
                  required
                  name='email'
                  label={l10n('Email')}
                  value={user.email}
                  onChange={this.onChange}
                  placeholder={l10n('Enter your email')}
                  error={!user.email && formInvalid}
                />
                <TextField
                  margin={`${width === 'xs' ? 'dense' : 'normal'}`}
                  fullWidth
                  name='company'
                  label={l10n('Company')}
                  value={user.company}
                  onChange={this.onChange}
                  placeholder={l10n("Enter your company's name")}
                  error={!user.company && formInvalid}
                />
                <FormControl fullWidth className={classes.formControl}>
                  <Button
                    variant='contained'
                    color='primary'
                    size='large'
                    disabled={!(user.name.trim().length > 0 && user.email.trim().length > 0)}
                    className={classes.btn}
                    onClick={this.onSubmit}
                  >
                    {l10n('Join Waitlist')}
                  </Button>
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </DocumentTitle>
    );
  }
}

const styles = () => ({
  formContainer: {
    overflowY: 'auto',
    height: '100vh',
    padding: 20,
  },
  form: {
    maxWidth: 400,
  },
  logoContainer: {
    minHeight: 100,
  },
  logo: {
    marginLeft: -20,
  },
  formControl: {
    marginTop: 16,
  },
  subscriptionHeading: {
    fontSize: 35,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  subscriptionSubheading: {
    fontSize: 16,
  },
});

Subscribe.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  width: PropTypes.string,
};

export default compose(
  withStyles(styles),
  withRouter,
  withWidth(),
)(Subscribe);
