import React, { Component, Fragment } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import l10n from '../../services/l10n';
import { validateFields } from '../../services/Validation';
import {
  signUp,
  changeUser,
  verifyInvitation,
  clearInviteUser,
} from '../../store/actions/authActions';
import FooterQuote from '../components/FooterQuote';

import Header from '../LandingPage/Header';
import VerifyInviteForm from './VerifyInviteForm';
import SignUpForm from './EmployeeSignUpForm';
import NoInviteFound from './NoInviteFound';

import Logo from '../../assets/header_logo.png';

const styles = theme => ({
  logo: {
    cursor: 'pointer',
  },
  button: {
    margin: theme.spacing.unit,
    fontWeight: 'bold',
  },
  form: {
    maxWidth: 353,
  },
  right: {
    textAlign: 'right',
  },
  formControl: {
    marginTop: '16px',
    '& label': { font: '12px' },
  },
  btn: {
    marginTop: '1em',
    width: '100%',
  },
  terms: {
    color: '#ee6352',
  },
  title: {
    fontSize: 25,
    fontWeight: 'bold',
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: 15,
  },
  verifyInput: {
    marginTop: 80,
  },
  content: {
    flex: 1,
  },
  componentContainer: {
    marginTop: 60,
  },
});

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      required: {
        firstName: false,
        lastName: false,
        email: false,
        password: false,
      },
    };
  }

  seed = Math.random();

  onChange = e => {
    const { user, _changeUser } = this.props;
    const _user = { ...user };
    _user[e.target.name] = e.target.value;
    _changeUser(_user);
  };

  onSubmit = async () => {
    const { _signUp, user, inviteUser } = this.props;
    const { required } = this.state;
    const fields = validateFields(user, required);

    user.isManager = false;

    if (fields.error) {
      this.setState({ required: fields });
    } else {
      _signUp(user, inviteUser);
    }
  };

  verifyInvite = () => {
    const { user, _verifyInvitation } = this.props;
    _verifyInvitation(user);
  };

  clearInviteUser = () => {
    const { _clearInviteUser } = this.props;
    _clearInviteUser();
  };

  componentWillReceiveProps(nextProps) {
    const { auth, history } = nextProps;

    if (auth.error) NotificationManager.error(auth.message, auth.title);
    if (auth.success) {
      NotificationManager.success(auth.message, auth.title);
      history.push('/verifyEmail');
    }
  }

  render() {
    const { classes, inviteUser, user, history, width } = this.props;
    const { required } = this.state;
    const isMobile = width === 'xs';
    return (
      <DocumentTitle title='Registration'>
        <Fragment>
          <Grid
            container
            className={classes.content}
            direction={isMobile ? 'row' : 'column'}
            alignItems='center'
            justify='space-between'
          >
            {isMobile && (
              <Grid item>
                <Header isStatic={true} />
              </Grid>
            )}
            <Grid
              item
              container
              alignItems='center'
              direction='column'
              justify='flex-start'
              style={{ marginBottom: 20 }}
            >
              {!isMobile && (
                <Grid container item xs={10} justify='space-between' alignItems='center'>
                  <Grid className={classes.logo} item onClick={() => history.push('/')}>
                    <img src={Logo} height={112} alt='Brinq logo' />
                  </Grid>
                  <Grid item>
                    {l10n('Have an account?')}{' '}
                    <Button href='/login' size='large' className={classes.button}>
                      {l10n('Login')}
                    </Button>
                  </Grid>
                </Grid>
              )}
              <Grid className={classes.componentContainer} container item justify='center'>
                {inviteUser && inviteUser.exists && (
                  <Grid xs={11} item>
                    <SignUpForm
                      classes={classes}
                      onChange={this.onChange}
                      required={required}
                      user={user}
                      onSubmit={this.onSubmit}
                    />
                  </Grid>
                )}
                {inviteUser && !inviteUser.exists && (
                  <Grid item>
                    <NoInviteFound
                      classes={classes}
                      enterNewEmail={this.clearInviteUser}
                      requestBrinq={this.requestBrinq}
                    />
                  </Grid>
                )}
                {!inviteUser && (
                  <Grid item>
                    <VerifyInviteForm
                      classes={classes}
                      onChange={this.onChange}
                      required={required}
                      user={user}
                      onSubmit={this.verifyInvite}
                      isMobile={isMobile}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            {!isMobile && <FooterQuote seed={this.seed} />}
          </Grid>
        </Fragment>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth.auth,
  inviteUser: state.auth.inviteUser,
  user: state.auth.user,
  inviteManager: state.auth.inviteManager,
});

const mapDispatchToProps = dispatch => ({
  _signUp: (user, inviteUser) => dispatch(signUp(user, inviteUser)),
  _changeUser: user => dispatch(changeUser(user)),
  _verifyInvitation: user => dispatch(verifyInvitation(user)),
  _clearInviteUser: () => dispatch(clearInviteUser()),
});

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  _signUp: PropTypes.func.isRequired,
  _changeUser: PropTypes.func.isRequired,
  _verifyInvitation: PropTypes.func.isRequired,
  _clearInviteUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  inviteUser: PropTypes.object,
};

export default compose(
  withStyles(styles),
  withWidth(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(Register);
